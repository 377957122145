import moment from 'moment';
import Bowser, { OS_MAP, BROWSER_MAP } from 'bowser';
import { parsePhoneNumberFromString } from 'libphonenumber-js';

export const inputs = {
  rut: {
    regex: /^\d+$/,
  },
  rutInput: {
    regex: /^[0-9]{12}$/,
  },
  ci: {
    regex: /^[0-9]{8}$/,
  },
  username: {
    regex: /^[a-zA-Z\d.]+$/,
  },
  password: {
    regex: /^(?=.*[A-ZÑ])(?=.*\d)[a-zA-Zñ\d!@#$%ˆ&*()_+~\-={}[\]\\|;:"'<>,.?/]{8,}$/,
  },
  email: {
    regex: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  },
  phone: {
    regex: /^[0-9]{9,15}$/,
  },
};

export const getInputTypes = () => {
  return inputs;
};

export const checkRUT = rut => {
  // TODO: TEMPORAL - SHOULD BE REMOVED
  return true;

  /*if (rut.length !== 12) {
    return false;
  }

  if (!/^([0-9])*$/.test(rut)) {
    return false;
  }

  var dc = parseInt(rut.substr(11, 1));
  var rut = rut.substr(0, 11);
  var total = 0;
  var factor = 2;

  for (var i = 10; i >= 0; i--) {
    total += factor * rut.substr(i, 1);
    factor = factor == 9 ? 2 : ++factor;
  }

  var dv = 11 - total % 11;

  if (dv === 11) {
    dv = 0;
  } else if (dv === 10) {
    dv = 1;
  }

  if (dv === dc) {
    return true;
  }

  return false;*/
};

export const formatCurrency = (n, c, lang) => {
  var c = isNaN((c = Math.abs(c))) ? 2 : c,
    d = lang === 'en' ? '.' : ',',
    t = lang === 'en' ? ',' : '.',
    s = n < 0 ? '-' : '',
    i = String(parseInt((n = Math.abs(Number(n) || 0).toFixed(c)))),
    j = (j = i.length) > 3 ? j % 3 : 0;

  return (
    s +
    (j ? i.substr(0, j) + t : '') +
    i.substr(j).replace(/(\d{3})(?=\d)/g, '$1' + t) +
    (c ? d + Math.abs(n - i).toFixed(c).slice(2) : '')
  );
};

export const formatCardNumber = cardNumber => {
  cardNumber = typeof cardNumber !== 'string' ? cardNumber.toString() : cardNumber;
  return `${cardNumber.substring(0, 4)} ${cardNumber.substring(4, 6)}XX XXXX XXXX`;
};

export const formatLegalId = legalId => {
  return `${legalId.substring(0, 1)}.${legalId.substring(1, 4)}.${legalId.substring(4, 7)}-${legalId.substring(7)}`;
};

export const capitalize = text => {
  return `${text.substring(0, 1).toUpperCase()}${text.substring(1)}`;
};

export const getMonthDates = (month, year) => {
  const date = moment().month(month).year(year);
  let startDate = date.clone().startOf('month');
  let endDate = date.clone().endOf('month');

  var dates = [];
  var weeks = [];

  var perWeek = [];
  var difference = endDate.diff(startDate, 'days');

  perWeek.push(startDate.toDate());

  let index = 0;
  let lastWeek = false;
  while (startDate.add(1, 'days').diff(endDate) < 0) {
    if (startDate.day() !== 0) {
      perWeek.push(startDate.toDate());
    } else {
      if (startDate.clone().add(7, 'days').month() === month) {
        weeks.push(perWeek);
        perWeek = [];
        perWeek.push(startDate.toDate());
      } else if (Math.abs(index - difference) > 0) {
        if (!lastWeek) {
          weeks.push(perWeek);
          perWeek = [];
        }
        lastWeek = true;
        perWeek.push(startDate.toDate());
      }
    }

    index += 1;

    if (
      (lastWeek === true && Math.abs(index - difference) === 0) ||
      (Math.abs(index - difference) === 0 && perWeek.length === 1)
    ) {
      weeks.push(perWeek);
    }

    dates.push(startDate.toDate());
  }

  const startWeekDiff = 7 - weeks[0].length;
  for (let i = 0, l = startWeekDiff; i < l; i += 1) {
    weeks[0].unshift(null);
  }

  const endWeekDiff = 7 - weeks[weeks.length - 1].length;
  for (let i = 0, l = endWeekDiff; i < l; i += 1) {
    weeks[weeks.length - 1].push(null);
  }

  return weeks;
};

export const downloadFile = (file, fileName) => {
  const url = window.URL.createObjectURL(
    new Blob([file], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
  );
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', fileName);
  document.body.appendChild(link);
  link.click();
};

export const getFileSize = file => {
  return new Promise(resolve => {
    resolve(file.size / 1024 / 1000);
  });
};

export const getFileSizeFromUrl = url => {
  return new Promise(resolve => {
    var fileSize = '';
    var http = new XMLHttpRequest();
    http.open('HEAD', url, true);
    http.onreadystatechange = function () {
      if (this.readyState == this.DONE) {
        if (this.status === 200) {
          fileSize = this.getResponseHeader('content-length');
          resolve(parseInt(fileSize) / 1024 / 1000);
        } else {
          resolve(-1);
        }
      }
    };
    http.send();
  });
};

export const checkForBrowserVersion = () => {
  // Check https://lancedikson.github.io/bowser/docs/ to see available browser and versions options
  const browser = Bowser.getParser(window.navigator.userAgent);
  const osName = browser.getOSName();
  const browserName = browser.getBrowserName();
  let isValidBrowser = true;

  if (osName === OS_MAP.Windows || osName === OS_MAP.MacOS) {
    if (browserName === BROWSER_MAP.edge && browser.satisfies({ [BROWSER_MAP.edge]: '<80' })) {
      isValidBrowser = false;
    }
    if (browserName === BROWSER_MAP.chrome && browser.satisfies({ [BROWSER_MAP.chrome]: '<72.0' })) {
      isValidBrowser = false;
    }
    if (browserName === BROWSER_MAP.firefox && browser.satisfies({ [BROWSER_MAP.firefox]: '<65.0' })) {
      isValidBrowser = false;
    }
    if (browserName === BROWSER_MAP.opera && browser.satisfies({ [BROWSER_MAP.opera]: '<57.0' })) {
      isValidBrowser = false;
    }
  }

  if (typeof isValidBrowser !== 'undefined' && !isValidBrowser) {
    window.location.href = '/update-browser.html';
  }
};

export const DEFAULT_DATE_FORMAT = 'DD-MMM-YYYY';

export const taxedAmount = amount => {
  return Math.round((amount / 1.22 + Number.EPSILON) * 100) / 100;
};

export const checkForExpiredSession = authTime => {
  return Math.floor((new Date() - new Date(authTime * 1000)) / (1000 * 60)) >= 15;
};

export const toISOString = date => {
  const utcOffset = moment().utcOffset();
  return moment.utc(date).utcOffset(utcOffset).toISOString();
};

export const validatePhoneNumber = completePhoneNumber => {
  if (!completePhoneNumber)
    return false;

  const parsedPhoneNumber = parsePhoneNumberFromString(completePhoneNumber);

  if (!parsedPhoneNumber || !parsedPhoneNumber.isValid())  
    return false;
  
  return true;
};

export const getPhoneAreaFromCountry = (country, countries) => {
  if (!country) {
    return '+598';
  }
  return countries.find(c => c.name.toUpperCase() === country.toUpperCase())?.dialingCode || null;
}