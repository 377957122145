import React, { createRef, useState, useRef, useEffect } from 'react';
import qs from 'query-string';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';

import { Language, Utils } from '../../utils';
import { user as userActions } from '../../actions';
import { Page, Button, Confirm, OnboardingWarningModal } from '../../components';

import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';

import makeClasses from './styles';

import appLogo from '../../static/images/logo.svg';
import HelpIcon from '../../static/images/icons/help.svg';
import clsx from 'clsx';
import { Checkbox, Input } from '@material-ui/core';
import { Alert, AlertTitle } from '@material-ui/lab';
import TimerOutlinedIcon from '@material-ui/icons/TimerOutlined';
import CheckCircleOutlineOutlinedIcon from '@material-ui/icons/CheckCircleOutlineOutlined';
import CancelOutlinedIcon from '@material-ui/icons/CancelOutlined';
import moment from 'moment';
import { replace } from 'connected-react-router';
import FlagOutlinedIcon from '@material-ui/icons/FlagOutlined';
import SentimentDissatisfiedOutlinedIcon from '@material-ui/icons/SentimentDissatisfiedOutlined';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { OB_TYPES } from '../../utils/Api';

const TIMER_TIME = 60 * 5;

const RegisterScreen = ({ history }) => {
  const dispatch = useDispatch();
  const classes = makeClasses();
  const searchParams = qs.parse(history.location.search);

  const { loading, languageTexts } = useSelector(state => ({
    loading: state.user.loading,
    languageTexts: state.language.texts || {},
  }));
  const i18n = Language(languageTexts);

  const [ showWarningModal, setShowWarningModal ] = useState(false);
  const [ registerStep, setRegisterStep ] = useState(searchParams.step ? parseInt(searchParams.step) : 1);
  const [ step2Completed, setStep2Completed ] = useState(false);
  const [ step3Completed, setStep3Completed ] = useState(false);
  const [ attemptLimitReachedError, setAttemptLimitReachedError ] = useState(false);
  const [ affiliationStep1TraceId, setAffiliationStep1TraceId ] = useState(
    searchParams.affiliationStep1TraceId || null
  );
  const [ affiliationStep2TraceId, setAffiliationStep2TraceId ] = useState(
    searchParams.affiliationStep2TraceId || null
  );
  const [ affiliationStep3TraceId, setAffiliationStep3TraceId ] = useState(
    searchParams.affiliationStep3TraceId || null
  );
  const [ affiliationVerificationLink, setAffiliationVerificationLink ] = useState(
    searchParams.verificationLink || null
  );

  // Step 1 Data
  const [ categories, setCategories] = useState([]);
  const [ countries, setCountries ] = useState([]);
  const [ departments, setDepartments ] = useState([]);
  const [ cities, setCities ] = useState([]);
  
  // Step 1 Inputs
  const [ inputCi, setInputCi ] = useState('');
  const [ inputCategory, setInputCategory ] = useState(null);
  const [ inputCountry, setInputCountry ] = useState(null);
  const [ inputDepartment, setInputDepartment ] = useState(null);
  const [ inputCity, setInputCity ] = useState(null);
  const [ inputAddressStreet, setInputAddressStreet ] = useState('');
  const [ inputAddressNumber, setInputAddressNumber ] = useState('');
  const [ inputAddressApartment, setInputAddressApartment ] = useState('');
  const [ inputAddressCorner, setInputAddressCorner ] = useState('');
  const [ inputEmail, setInputEmail ] = useState('');
  const [ inputPhone, setInputPhone ] = useState('');
  const [ inputPhoneCharacteristic, setInputPhoneCharacteristic ] = useState(null);
  //
  const verificationCodeRefs = useRef(new Array(6).fill('').map(() => createRef()));
  const [ verificationCode, setVerificationCode ] = useState(new Array(6).fill(''));
  const [ timerStartTime, setTimerStartTime ] = useState(null);
  const [ intervalTime, setIntervalTime ] = useState(0);
  const [ intervalId, setIntervalId ] = useState(null);
  const [ formattedTime, setFormattedTime ] = useState('00:00');
  const [ showHelp, setShowHelp ] = useState(false);
  const [ acceptedHandyTermsAndConditions, setAcceptedHandyTermsAndConditions ] = useState(false);
  const [ acceptedPaigoTermsAndConditions, setAcceptedPaigoTermsAndConditions ] = useState(false);

  const canMoveToStep2 =
    inputCi.trim().length > 0 &&
    inputCategory !== null &&
    inputCountry !== null &&
    inputDepartment !== null &&
    inputCity !== null &&
    inputAddressStreet.length > 0 &&
    inputAddressNumber.length > 0 &&
    inputEmail.length > 0 &&
    inputPhone.length > 0 &&
    inputPhone.length <= 15 &&
    inputPhoneCharacteristic !== null &&
    inputAddressCorner.length > 0 &&
    acceptedHandyTermsAndConditions &&
    acceptedPaigoTermsAndConditions;
  const canValidateVerificationCode = verificationCode.filter(code => /[0-9]/.test(code)).length;

  useEffect(() => {
    dispatch(
      userActions.getCountries(countries => {
        setCountries(countries);
        setInputPhoneCharacteristic(countries);
        countries.forEach(country => {
          if (country.alphaCode2 === 'UY') {
            setInputCountry(country.id);
            setInputPhoneCharacteristic(country.id);
          }
        });
      })
    );
  }, []);

  useEffect(() => {
    dispatch(
      userActions.getCategories(OB_TYPES.PHYSICAL,categories => {
        setCategories(categories);
      })
    );
  }, []);

  useEffect(
    () => {
      if (searchParams.result) {
        if (searchParams.result === 'success' || searchParams.result === 'error') {
          setRegisterStep(5);
        } else if (searchParams.result === 'expired') {
          setRegisterStep(6);
        } else {
          setShowWarningModal(true);
        }
      } else {
        setShowWarningModal(true);
      }
    },
    [ searchParams.result ]
  );

  useEffect(
    () => {
      if (!intervalId) {
        const id = setInterval(() => {
          setIntervalTime(currentIntervalTime => currentIntervalTime + 1);
        }, 1000);
        setIntervalId(id);
      }
    },
    [ intervalId ]
  );

  useEffect(
    () => {
      if (timerStartTime) {
        const timer = TIMER_TIME - moment().diff(moment(timerStartTime), 's');
        if (timer < 0) {
          _stopTimer();
        } else {
          const minutes = Math.floor(timer / 60);
          const seconds = timer % 60;
          setFormattedTime(`${minutes < 10 ? '0' + minutes : minutes}:${seconds < 10 ? '0' + seconds : seconds}`);
        }
      }
    },
    [ intervalTime, timerStartTime ]
  );

  useEffect(
    () => {
      if (inputCountry) {
        dispatch(
          userActions.getDepartmentsByCountry(inputCountry, departments => {
            setDepartments(departments);
          })
        );
      }
    },
    [ inputCountry ]
  );

  useEffect(
    () => {
      if (inputDepartment) {
        dispatch(
          userActions.getCitiesByDepartments(inputDepartment, cities => {
            setCities(cities);
          })
        );
      }
    },
    [ inputDepartment ]
  );

  useEffect(
    () => {
      const searchParams = qs.parse(history.location.search) || {};
      searchParams.step = registerStep;
      searchParams.affiliationStep1TraceId = affiliationStep1TraceId || undefined;

      if (affiliationStep1TraceId) {
        _stopTimer();
        setVerificationCode(new Array(6).fill(''));

        if (registerStep === 2) {
          verificationCodeRefs.current[0].current.focus();

          dispatch(
            userActions.affiliationRequestEmailVerificationCode(affiliationStep1TraceId, step2TraceId => {
              _startTimer();

              if (step2TraceId) {
                setAffiliationStep2TraceId(step2TraceId);

                if (!searchParams.affiliationStep2TraceId || searchParams.affiliationStep2TraceId !== step2TraceId) {
                  searchParams.affiliationStep2TraceId = step2TraceId;
                  dispatch(
                    replace({
                      search: qs.stringify(searchParams),
                    })
                  );
                }
              }
            })
          );
        } else if (registerStep === 3) {
          verificationCodeRefs.current[0].current.focus();

          dispatch(
            userActions.affiliationRequestPhoneVerificationCode(affiliationStep1TraceId, step3TraceId => {
              _startTimer();

              if (step3TraceId) {
                setAffiliationStep3TraceId(step3TraceId);

                if (!searchParams.affiliationStep3TraceId || searchParams.affiliationStep3TraceId !== step3TraceId) {
                  searchParams.affiliationStep3TraceId = step3TraceId;
                  dispatch(
                    replace({
                      search: qs.stringify(searchParams),
                    })
                  );
                }
              }
            })
          );
        } else if (registerStep === 4) {
          dispatch(
            userActions.affiliationGetIdentityVerificationLink(affiliationStep1TraceId, result => {
              setAffiliationVerificationLink(encodeURIComponent(result.verificationLink));
              searchParams.verificationLink = result.verificationLink;
              dispatch(
                replace({
                  search: qs.stringify(searchParams),
                })
              );
            })
          );
        } else {
          dispatch(
            replace({
              search: qs.stringify(searchParams),
            })
          );
        }
      }
    },
    [ registerStep ]
  );

  useEffect(
    () => {
      if (affiliationVerificationLink) {
        const requestCameraPermission = async () => {
          await navigator.mediaDevices.getUserMedia({
            video: true,
          });
        };
        requestCameraPermission();
      }
    },
    [ affiliationVerificationLink ]
  );

  useEffect(() => _validateVerificationCode(), [ canValidateVerificationCode ]);

  const _handleOnChangeCategory = (e, newValue) => {
    setInputCategory(newValue);
  };


  const _handleOnChangeCountry = (e, newValue) => {
    setInputDepartment(null);
    setInputCity(null);
    setDepartments([]);
    setCities([]);
    setInputCountry(newValue);
  };

  const _handleOnChangeDepartment = (e, newValue) => {
    setInputCity(null);
    setCities([]);
    setInputDepartment(newValue);
  };

  const _handleOnChangePhoneCharacteristic  = (e, newValue) => {
    setInputPhoneCharacteristic(newValue);
  };

  const _handleOnChangeCity = (e, newValue) => {
    setInputCity(newValue);
  };

  const _submitRegisterStep1 = () => {

    const country = countries.find(c => c.id === inputCountry);
    
    const city = cities.find(c => c.id === inputCity);

    const category = categories.find(c=> c.id === inputCategory);

    const countryPhone = countries.find(c => c.id === inputPhoneCharacteristic);

    const phone = inputPhone.trim();

    const completePhoneNumber = countries.find(c => c.id === inputPhoneCharacteristic).dialingCode + phone;

    if (!Utils.inputs.ci.regex.test(inputCi.trim())) {
      return toast.error(i18n.get('Register.RegisterInvalidCi'), { autoClose: 5000 });
    }
    if (!Utils.inputs.email.regex.test(inputEmail.trim())) {
      return toast.error(i18n.get('Register.RegisterInvalidEmail'), { autoClose: 5000 });
    }
    if (!Utils.validatePhoneNumber(completePhoneNumber)) {
      return toast.error(i18n.get('Register.RegisterInvalidPhone'));
    }

    dispatch(
      userActions.startAffiliation(
        {
          CountryISO: country.alphaCode2,
          DocumentTypeKey: 'CI',
          Document: inputCi.trim(),
          CategoryId: category.id,
          Phone: {
            CountryId: countryPhone.id,
            Number: phone,
          },
          Email: inputEmail.trim(),
          Address: {
            CityId: city.id,
            Street: inputAddressStreet.trim(),
            Corner: inputAddressCorner.trim(),
            Apartment: inputAddressApartment.trim(),
            PostalCode: null,
            Number: inputAddressNumber.trim(),
            Description: null,
            Longitude: 0,
            Latitude: 0,
          },
          AffiliationCode: qs.parse(history?.location?.search)?.code ?? null
        },
        affiliationResult => {
          if (affiliationResult.traceId) {
            setAffiliationStep1TraceId(affiliationResult.traceId);
            setRegisterStep(2);
          }
        }
      )
    );
  };

  const _submitRegisterStep2 = () => {
    setRegisterStep(3);
  };

  const _submitRegisterStep3 = () => {
    setRegisterStep(4);
  };

  const _startTimer = () => {
    setTimerStartTime(Date.now());
  };

  const _stopTimer = () => {
    setTimerStartTime(null);
    if (intervalId) {
      clearInterval(intervalId);
    }
    setIntervalId(null);
  };

  const _validateVerificationCode = () => {
    const hasValidVerificationCode = verificationCode.filter(code => /[0-9]/.test(code)).length === 6;

    if (canValidateVerificationCode && hasValidVerificationCode) {
      dispatch(
        userActions.affiliationVerifyVerificationCode(
          affiliationStep1TraceId,
          registerStep === 2 ? affiliationStep2TraceId : affiliationStep3TraceId,
          verificationCode.join(''),
          result => {
            if (result.result === 'SUCCESSFUL') {
              _stopTimer();
              if (registerStep === 2) {
                setStep2Completed(true);
              } else if (registerStep === 3) {
                setStep3Completed(true);
              }
            } else if (result.result === 'WRONG_ACCESS_CODE') {
              toast.error(i18n.get('Register.RegisterCodeValidationError'), { autoClose: 5000 });
            } else if (result.result === 'ATTEMPT_LIMIT_REACHED') {
              setAttemptLimitReachedError(true);
            } else if (result.result === 'EXPIRED_ACCESS_CODE') {
              _stopTimer();
              toast.error(i18n.get('Register.RegisterExpiredValidationCodeError'), { autoClose: 5000 });
            }
          }
        )
      );
    }
  };

  const _handleVerificationCodeOnBlur = index => e => {
    if (e.target.value.length > 0 && !/[0-9]/.test(e.target.value)) {
      const newVerificationCode = verificationCode.slice();
      newVerificationCode[index] = '';
      setVerificationCode(newVerificationCode);
      verificationCodeRefs.current[index].current.focus();
    }
  };

  const _handleVerificationCodeOnChange = index => e => {
    if (e.target.value.length === 0) {
      const newVerificationCode = verificationCode.slice();
      newVerificationCode[index] = '';
      return setVerificationCode(newVerificationCode);
    }

    if (!/[0-9]/.test(e.target.value)) {
      return;
    }

    const newVerificationCode = verificationCode.slice();
    newVerificationCode[index] = e.target.value.substring(0, 1);
    setVerificationCode(newVerificationCode);

    if (index + 1 < 6) {
      verificationCodeRefs.current[index + 1].current.focus();
    } else if (index === 5) {
      setVerificationCode(newVerificationCode);
    }
  };

  const _handleVerificationCondeOnPaste = e => {
    const pastedText = e.clipboardData.getData('text').trim();

    if (!/[0-9]{6}/.test(pastedText)) {
      return alert(i18n.get('Register.CodeInvalidCode'));
    }

    const newVerificationCode = verificationCode.slice();
    for (let i = 0, l = 6; i < l; i += 1) {
      newVerificationCode[i] = pastedText[i];
    }

    setVerificationCode(newVerificationCode);
  };

  const _requestNewVerificationCode = () => {
    setVerificationCode(new Array(6).fill(''));

    if (registerStep === 2) {
      dispatch(
        userActions.affiliationRequestEmailVerificationCode(affiliationStep1TraceId, step2TraceId => {
          if (step2TraceId === false) {
            setAttemptLimitReachedError(true);
          } else if (typeof step2TraceId === 'string') {
            setAffiliationStep2TraceId(step2TraceId);
            _startTimer();
          }
        })
      );
    } else if (registerStep === 3) {
      dispatch(
        userActions.affiliationRequestPhoneVerificationCode(affiliationStep1TraceId, step3TraceId => {
          if (step3TraceId === false) {
            setAttemptLimitReachedError(true);
          } else if (typeof step3TraceId === 'string') {
            setAffiliationStep3TraceId(step3TraceId);
            _startTimer();
          }
        })
      );
    }
  };

  const _handleOnChangeInputPhone = e => {
    if (e.target.value.length <= 15) {
      setInputPhone(e.target.value);
    }
  };

  const _goBack = () => {
    if (registerStep === 2) {
      setRegisterStep(1);
    } else if (registerStep === 3) {
      setRegisterStep(2);
    } else {
      setRegisterStep(1);
      setInputCi('');
      setInputCategory(-1);
      setInputCountry(-1);
      setInputDepartment(-1);
      setInputCity(-1);
      setInputPhoneCharacteristic(-1);
      setInputAddressStreet('');
      setInputAddressNumber('');
      setInputAddressApartment('');
      setInputAddressCorner('');
      setInputEmail('');
      setInputPhone('');

      Object.keys(searchParams).map(key => delete searchParams[key]);

      dispatch(
        replace({
          search: qs.stringify(searchParams),
        })
      );
    }
  };

  const _toggleShowHelp = () => {
    setShowHelp(!showHelp);
  };

  const _toggleShowWarningModal = () => {
    setShowWarningModal(!showWarningModal);
  };

  const _getOptionLabel = array => option => {
    let foundOption = null;
    if(array === 'categories'){
      foundOption = categories.find(c=> c.id === option);
    }
    else if (array === 'countries') {
      foundOption = countries.find(c => c.id === option);
    } else if (array === 'cities') {
      foundOption = cities.find(c => c.id === option);
    } else if (array === 'departments') {
      foundOption = departments.find(c => c.id === option);
    }
    else if (array === 'phoneCharacteristics') {
      foundOption = countries.find(c => c.id === option);
      if (foundOption)
        return foundOption.name + " " + foundOption.dialingCode;
    }
    if (foundOption) {
      return foundOption.name;
    }
    return '';
  };

  const renderRegisterForm = () => {
    if (registerStep === 1) {
      return renderStep1();
    }
    if (registerStep === 2) {
      return renderStep2();
    }
    if (registerStep === 3) {
      return renderStep3();
    }
    if (registerStep === 4) {
      return renderStep4();
    }
    if (registerStep === 5) {
      return renderStep5();
    }
    if (registerStep === 6) {
      return renderStep6();
    }
  };

  const renderStep1 = () => {
    return (
      <React.Fragment>
        <div className={classes.formContent}>
          <div className={classes.formTitleContainer}>
            <div className={classes.formTitle}>{i18n.get('Register.StepFormTitle')}</div>
            <div className={classes.formText}>{i18n.get('Register.Step1FormText')}</div>
          </div>
          <form layout="vertical" onSubmit={_submitRegisterStep1} className={classes.form}>
            <Grid container spacing={2}>
              <Grid item xs={6} sm={6} md={6} xl={6}>
                <TextField
                  placeholder={i18n.get('Register.InputCITitle')}
                  className={classes.formInput}
                  onChange={e => setInputCi(e.target.value)}
                  classes={{ root: classes.formInput }}
                  variant="outlined"
                  value={inputCi}
                />
              </Grid>
              <Grid item xs={6} sm={6} md={6} xl={6}>
                <Autocomplete
                  options={categories.map(c => c.id)}
                  getOptionLabel={_getOptionLabel('categories')}
                  onChange={_handleOnChangeCategory}
                  classes={{ inputRoot: classes.formInputSelect }}
                  value={inputCategory}
                  renderInput={params => (
                    <TextField
                      className={classes.formInputTrimmed}
                      classes={{ root: classes.formInput }}
                      variant="outlined"
                      placeholder={i18n.get('Register.InputCategoryTitle')}
                      {...params}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={6} sm={6} md={4} xl={4}>
                <Autocomplete
                  options={countries.map(c => c.id)}
                  getOptionLabel={_getOptionLabel('countries')}
                  onChange={_handleOnChangeCountry}
                  classes={{ inputRoot: classes.formInputSelect }}
                  disabled
                  value={inputCountry}
                  renderInput={params => (
                    <TextField
                      className={classes.formInput}
                      classes={{ root: classes.formInput }}
                      variant="outlined"
                      placeholder={i18n.get('Register.InputCountryTitle')}
                      {...params}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={6} sm={6} md={4} xl={4}>
                <Autocomplete
                  options={departments.map(d => d.id)}
                  getOptionLabel={_getOptionLabel('departments')}
                  onChange={_handleOnChangeDepartment}
                  classes={{ inputRoot: classes.formInputSelect }}
                  disabled={!inputCountry}
                  value={inputDepartment}
                  renderInput={params => (
                    <TextField
                      className={classes.formInput}
                      classes={{ root: classes.formInput }}
                      variant="outlined"
                      placeholder={i18n.get('Register.InputDepartmentTitle')}
                      {...params}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={4} xl={4}>
                <Autocomplete
                  options={cities.map(c => c.id)}
                  getOptionLabel={_getOptionLabel('cities')}
                  onChange={_handleOnChangeCity}
                  classes={{ inputRoot: classes.formInputSelect }}
                  disabled={!inputDepartment}
                  value={inputCity}
                  renderInput={params => (
                    <TextField
                      className={classes.formInput}
                      classes={{ root: classes.formInput }}
                      variant="outlined"
                      placeholder={i18n.get('Register.InputCityTitle')}
                      {...params}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={6} sm={6} md={12} xl={12}>
                <TextField
                  placeholder={i18n.get('Register.InputStreetTitle')}
                  className={classes.formInput}
                  onChange={e => setInputAddressStreet(e.target.value)}
                  classes={{ root: classes.formInput }}
                  variant="outlined"
                  value={inputAddressStreet}
                />
              </Grid>
              <Grid item xs={6} sm={6} md={4} xl={4}>
                <TextField
                  placeholder={i18n.get('Register.InputNumberTitle')}
                  className={classes.formInput}
                  onChange={e => setInputAddressNumber(e.target.value)}
                  classes={{ root: classes.formInput }}
                  variant="outlined"
                  value={inputAddressNumber}
                />
              </Grid>
              <Grid item xs={6} sm={6} md={4} xl={4}>
                <TextField
                  placeholder={i18n.get('Register.InputApartmentTitle')}
                  className={classes.formInput}
                  onChange={e => setInputAddressApartment(e.target.value)}
                  classes={{ root: classes.formInput }}
                  variant="outlined"
                  value={inputAddressApartment}
                />
              </Grid>
              <Grid item xs={6} sm={6} md={4} xl={4}>
                <TextField
                  placeholder={i18n.get('Register.InputCornerTitle')}
                  className={classes.formInput}
                  onChange={e => setInputAddressCorner(e.target.value)}
                  classes={{ root: classes.formInput }}
                  variant="outlined"
                  value={inputAddressCorner}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12} xl={12}>
                <TextField
                  placeholder={i18n.get('Register.InputEmailTitle')}
                  className={classes.formInput}
                  onChange={e => setInputEmail(e.target.value)}
                  classes={{ root: classes.formInput }}
                  variant="outlined"
                  value={inputEmail}
                />
              </Grid>
              <Grid item xs={6} sm={6} md={4} xl={4}>
              <Autocomplete
                  options={countries.map(d => d.id)}
                  getOptionLabel={_getOptionLabel('phoneCharacteristics')}
                  onChange={_handleOnChangePhoneCharacteristic}
                  classes={{ inputRoot: classes.formInputSelect }}
                  value={inputPhoneCharacteristic}
                  renderInput={params => (
                    <TextField
                      className={classes.formInput}
                      classes={{ root: classes.formInput }}
                      variant="outlined"
                      placeholder={i18n.get('Register.InputPhoneCharacteristic')}
                      {...params}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={6} sm={6} md={8} xl={8}>
                <TextField
                  placeholder={i18n.get('Register.InputPhoneTitle')}
                  className={classes.formInput}
                  onChange={_handleOnChangeInputPhone}
                  classes={{ root: classes.formInput }}
                  variant="outlined"
                  value={inputPhone}
                />
              </Grid>
            </Grid>
          </form>
        </div>
        <div className={classes.termsWrapper}>
          <div className={classes.termsContainer}>
            <Checkbox
              classes={{ root: classes.checkboxRoot }}
              checked={acceptedHandyTermsAndConditions}
              onChange={() => setAcceptedHandyTermsAndConditions(!acceptedHandyTermsAndConditions)}
            />
            <div className={classes.termsLabel} dangerouslySetInnerHTML={{ __html: i18n.get('Register.HandyTyC') }} />
          </div>
          <div className={classes.termsContainer}>
            <Checkbox
              classes={{ root: classes.checkboxRoot }}
              checked={acceptedPaigoTermsAndConditions}
              onChange={() => setAcceptedPaigoTermsAndConditions(!acceptedPaigoTermsAndConditions)}
            />
            <div
              className={classes.termsLabel}
              dangerouslySetInnerHTML={{ __html: i18n.get('Register.PaigoTyCLabel') }}
            />
          </div>
        </div>
        <div className={clsx(classes.formSubmitContainer, classes.formSubmitContainerWithOneButton)}>
          <Button
            className={classes.formSubmitButton}
            onClick={_submitRegisterStep1}
            disabled={loading || !canMoveToStep2}
          >
            {i18n.get('Register.NextButton')}
          </Button>
        </div>
      </React.Fragment>
    );
  };

  const renderStep2 = () => {
    return (
      <React.Fragment>
        <div className={classes.formContent}>
          <div className={clsx(classes.formTitleContainer, classes.formTitleContainerWithAlert)}>
            <div className={classes.formTitle}>{i18n.get('Register.StepFormTitle')}</div>
            {!attemptLimitReachedError ? (
              <div className={classes.formText}>{i18n.get('Register.Step2FormText')}</div>
            ) : null}
          </div>
          {attemptLimitReachedError ? (
            <React.Fragment>
              <Alert severity="error" icon={false} className={clsx(classes.alert, classes.alertError)}>
                <div>
                  <CancelOutlinedIcon className={classes.alertIcon} />
                </div>
                <div className={classes.alertMessage}>
                  <AlertTitle className={classes.alertTitle}>
                    {i18n.get('Register.AlertAttemptLimitReachedErrorTitle')}
                  </AlertTitle>
                  {i18n.get('Register.AlertAttemptLimitReachedErrorMessage')}
                </div>
              </Alert>
            </React.Fragment>
          ) : step2Completed ? (
            <React.Fragment>
              <Alert severity="success" icon={false} className={clsx(classes.alert, classes.alertSuccess)}>
                <div>
                  <CheckCircleOutlineOutlinedIcon className={classes.alertIcon} />
                </div>
                <div className={classes.alertMessage}>
                  <AlertTitle className={classes.alertTitle}>{i18n.get('Register.AlertSuccessStep2Title')}</AlertTitle>
                  {i18n.get('Register.AlertSuccessStep2Message')}
                </div>
              </Alert>
            </React.Fragment>
          ) : (
            <React.Fragment>
              <Alert severity="warning" icon={false} className={clsx(classes.alert, classes.alertWarning)}>
                <div>
                  <TimerOutlinedIcon className={classes.alertIcon} />
                </div>
                <div className={classes.alertMessage}>
                  <AlertTitle className={classes.alertTitle}>{i18n.get('Register.AlertWarningStep2Title')}</AlertTitle>
                  {i18n.get('Register.AlertWarningStep2Message1')}
                  <span className={classes.alertTimer}>{formattedTime}</span>
                  {i18n.get('Register.AlertWarningStep2Message2')}
                </div>
              </Alert>
              <form layout="vertical" className={clsx(classes.form, classes.formVerificationCode)}>
                <Grid container spacing={1}>
                  {verificationCode.map((code, index) => (
                    <Grid key={index} item xs={2} sm={2} md={2}>
                      <Input
                        value={code}
                        onBlur={_handleVerificationCodeOnBlur(index)}
                        onChange={_handleVerificationCodeOnChange(index)}
                        inputProps={{ className: classes.verificationCodeInput, inputMode: 'numeric' }}
                        inputRef={verificationCodeRefs.current[index]}
                        onPaste={_handleVerificationCondeOnPaste}
                        disableUnderline={true}
                      />
                    </Grid>
                  ))}
                </Grid>
              </form>
            </React.Fragment>
          )}
        </div>
        {!attemptLimitReachedError ? (
          <div className={classes.formSubmitContainer}>
            <Button className={classes.formSubmitButton} onClick={_goBack} variant="secondary" disabled>
              {i18n.get('Register.ReturnButton')}
            </Button>
            {!step2Completed ? (
              <React.Fragment>
                <div className={classes.formSubmitButtonSeparator} />
                <Button
                  className={classes.formSubmitButton}
                  onClick={_requestNewVerificationCode}
                  disabled={timerStartTime}
                >
                  {i18n.get('Register.RequestNewVerificationCodeButton')}
                </Button>
              </React.Fragment>
            ) : null}
            <div className={classes.formSubmitButtonSeparator} />
            <Button
              className={classes.formSubmitButton}
              onClick={_submitRegisterStep2}
              disabled={loading || !step2Completed}
            >
              {i18n.get('Register.NextButton')}
            </Button>
          </div>
        ) : null}
      </React.Fragment>
    );
  };

  const renderStep3 = () => {
    return (
      <React.Fragment>
        <div className={classes.formContent}>
          <div className={clsx(classes.formTitleContainer, classes.formTitleContainerWithAlert)}>
            <div className={classes.formTitle}>{i18n.get('Register.StepFormTitle')}</div>
            {!attemptLimitReachedError ? (
              <div className={classes.formText}>{i18n.get('Register.Step3FormText')}</div>
            ) : null}
          </div>
          {attemptLimitReachedError ? (
            <React.Fragment>
              <Alert severity="error" icon={false} className={clsx(classes.alert, classes.alertError)}>
                <div className={classes.alertMessage}>
                  <AlertTitle className={classes.alertTitle}>
                    {i18n.get('Register.AlertAttemptLimitReachedErrorTitle')}
                  </AlertTitle>
                  {i18n.get('Register.AlertAttemptLimitReachedErrorMessage')}
                </div>
              </Alert>
            </React.Fragment>
          ) : step3Completed ? (
            <React.Fragment>
              <Alert severity="success" icon={false} className={clsx(classes.alert, classes.alertSuccess)}>
                <div>
                  <CheckCircleOutlineOutlinedIcon className={classes.alertIcon} />
                </div>
                <div className={classes.alertMessage}>
                  <AlertTitle className={classes.alertTitle}>{i18n.get('Register.AlertSuccessStep3Title')}</AlertTitle>
                  {i18n.get('Register.AlertSuccessStep3Message')}
                </div>
              </Alert>
            </React.Fragment>
          ) : (
            <React.Fragment>
              <Alert severity="warning" icon={false} className={clsx(classes.alert, classes.alertWarning)}>
                <div>
                  <TimerOutlinedIcon className={classes.alertIcon} />
                </div>
                <div className={classes.alertMessage}>
                  <AlertTitle className={classes.alertTitle}>{i18n.get('Register.AlertWarningStep3Title')}</AlertTitle>
                  {i18n.get('Register.AlertWarningStep3Message1')}
                  <span className={classes.alertTimer}>{formattedTime}</span>
                  {i18n.get('Register.AlertWarningStep3Message2')}
                </div>
              </Alert>
              <form layout="vertical" className={clsx(classes.form, classes.formVerificationCode)}>
                <Grid container spacing={1}>
                  {verificationCode.map((code, index) => (
                    <Grid key={index} item xs={2} sm={2} md={2}>
                      <Input
                        value={code}
                        onBlur={_handleVerificationCodeOnBlur(index)}
                        onChange={_handleVerificationCodeOnChange(index)}
                        inputProps={{ className: classes.verificationCodeInput, inputMode: 'numeric' }}
                        inputRef={verificationCodeRefs.current[index]}
                        onPaste={_handleVerificationCondeOnPaste}
                        disableUnderline={true}
                      />
                    </Grid>
                  ))}
                </Grid>
              </form>
            </React.Fragment>
          )}
        </div>
        {!attemptLimitReachedError ? (
          <div className={classes.formSubmitContainer}>
            <Button className={classes.formSubmitButton} onClick={_goBack} variant="secondary" disabled>
              {i18n.get('Register.ReturnButton')}
            </Button>
            {!step3Completed ? (
              <React.Fragment>
                <div className={classes.formSubmitButtonSeparator} />
                <Button
                  className={classes.formSubmitButton}
                  onClick={_requestNewVerificationCode}
                  disabled={timerStartTime}
                >
                  {i18n.get('Register.RequestNewVerificationCodeButton')}
                </Button>
              </React.Fragment>
            ) : null}
            <div className={classes.formSubmitButtonSeparator} />
            <Button
              className={classes.formSubmitButton}
              onClick={_submitRegisterStep3}
              disabled={loading || !step3Completed}
            >
              {i18n.get('Register.NextButton')}
            </Button>
          </div>
        ) : null}
      </React.Fragment>
    );
  };

  const renderStep4 = () => {
    return (
      <React.Fragment>
        <div className={classes.formContent}>
          <div className={classes.formTitleContainer}>
            <div className={classes.formTitle}>{i18n.get('Register.StepFormTitle')}</div>
            {!affiliationVerificationLink ? (
              <div className={classes.formText}>{i18n.get('Register.Step4FormText')}</div>
            ) : null}
          </div>
        </div>
        {affiliationVerificationLink ? (
          <div className={classes.iframeContainer}>
            <iframe src={decodeURIComponent(affiliationVerificationLink)} title="Verification" allow="camera" />
          </div>
        ) : null}
      </React.Fragment>
    );
  };

  const renderStep5 = () => {
    return (
      <React.Fragment>
        <div className={classes.formContent}>
          <div className={classes.formTitleContainer}>
            <div className={classes.formTitle}>{i18n.get('Register.StepFormTitle')}</div>
            <div className={classes.formText}>{i18n.get('Register.Step5FormText')}</div>
          </div>
          <Alert severity="warning" icon={false} className={clsx(classes.alert, classes.alertWarning)}>
            <FlagOutlinedIcon className={clsx(classes.alertIcon, classes.alertIconWithBorder)} />
            <div className={classes.alertMessage}>
              <AlertTitle className={classes.alertTitle}>{i18n.get('Register.Step5ConfirmationTitle')}</AlertTitle>
              <div>{i18n.get('Register.Step5ConfirmationMessage1')}</div>
            </div>
          </Alert>
        </div>
      </React.Fragment>
    );
  };

  const renderStep6 = () => {
    return (
      <React.Fragment>
        <div className={classes.formContent}>
          <div className={classes.formTitleContainer}>
            <div className={classes.formTitle}>{i18n.get('Register.StepFormTitle')}</div>
          </div>
          <Alert severity="warning" icon={false} className={clsx(classes.alert, classes.alertError)}>
            <SentimentDissatisfiedOutlinedIcon className={classes.alertIcon} />
            <div className={classes.alertMessage}>
              <AlertTitle className={classes.alertTitle}>{i18n.get('Register.Step6ConfirmationTitle')}</AlertTitle>
              <div>{i18n.get('Register.Step6ConfirmationMessage')}</div>
            </div>
          </Alert>
          <div className={clsx(classes.formSubmitContainer, classes.formSubmitContainerWithOneButton)}>
            <Button className={classes.formSubmitButton} onClick={_goBack}>
              {i18n.get('Register.BackToStart')}
            </Button>
          </div>
        </div>
      </React.Fragment>
    );
  };

  return (
    <Page
      withHeader={false}
      withSideMenu={false}
      withBanner={false}
      withBoxBanner={false}
      showBranchWithNoPaymentMethodWarning={false}
    >
      <div className={classes.container}>
        <div
          className={clsx(
            classes.contentContainer,
            registerStep === 4 && affiliationVerificationLink ? classes.contentContainerWithIFrame : null
          )}
        >
          <div
            className={clsx(
              classes.contentWrapper,
              affiliationVerificationLink ? classes.contentWrapperWithIFrame : null
            )}
          >
            <div className={classes.brandContainer}>
              <img src={appLogo} className={classes.brandLogo} alt="Handy Logo" />
              <div className={classes.brandSeparator} />
              <div className={classes.brandTitle}>{i18n.get('Register.PageTitle')}</div>
            </div>
          </div>
          {renderRegisterForm()}
        </div>
        <div className={classes.helpContainer} onClick={_toggleShowHelp}>
          {i18n.get('Register.UserHelpTitle')}
          <img src={HelpIcon} className={classes.helpIcon} alt="ayuda" />
        </div>
      </div>
      <Confirm
        open={showHelp}
        onClose={_toggleShowHelp}
        title={i18n.get('Register.UserHelpDialogTitle')}
        text={i18n.get('Register.UserHelpDialogText')}
        confirmText={i18n.get('Register.UserHelpDialogButton')}
        onConfirm={_toggleShowHelp}
      />
      <OnboardingWarningModal open={showWarningModal} onClose={_toggleShowWarningModal} />
    </Page>
  );
};

RegisterScreen.id = 'com.Handy.Register';

export default RegisterScreen;
