import { Auth } from 'aws-amplify';

const COMMON_HEADERS = {
  Accept: 'application/json',
  'Content-Type': 'application/json',
};
const FILE_HEADERS = {
  Accept: 'application/octet-stream',
  'Content-Type': 'application/octet-stream',
};

const getToken = async () => {
  const session = await Auth.currentSession();
  const authIdToken = session.getIdToken().getJwtToken();
  return authIdToken;
};

const handleResponse = async (response, options = {}) => {
  if (response.status === 200 || response.status === 204 || response.status === 201) {
    try {
      if (options.file) {
        const data = await response.blob();
        return { status: API_RESPONSES.SUCCESS, code: response.status, data };
      }
      const data = await response.json();
      return { status: API_RESPONSES.SUCCESS, code: response.status, data };
    } catch (e) {
      return { status: API_RESPONSES.SUCCESS, code: response.status };
    }
  }

  try {
    const error = await response.text();
    return {
      status: API_RESPONSES.ERROR,
      message: JSON.parse(error),
      code: response.status,
    };
  } catch (e) {
    return { status: API_RESPONSES.ERROR, code: response.status };
  }
};

const API = {
  get: async (path = '', options = {}, noToken = false) => {
    try {
      let response = null;

      if (noToken) {
        response = await fetch(path, {
          method: 'GET',
          headers: {
            ...COMMON_HEADERS,
          },
        });
      } else {
        const token = await getToken();
        const headers = options.file ? FILE_HEADERS : COMMON_HEADERS;
        response = await fetch(path, {
          method: 'GET',
          headers: {
            ...headers,
            Authorization: token ? `Bearer ${token}` : null,
          },
        });
      }

      return await handleResponse(response, options);
    } catch (e) {
      return { status: API_RESPONSES.ERROR, message: e, code: e.code || null };
    }
  },
  createFormData: (body, file) => {
    var formData = new FormData();
    formData.append('file', file);
    for (const key in body) {
      if (body.hasOwnProperty(key) && body[key] !== undefined && body[key] !== null) {
        formData.append(key, body[key]);
      }
    }
    return formData;
  },
  post: async (path = '', body = {}, noToken = false, options = {}) => {
    try {
      let response = null;
      const headers = options.file ? { "File-Length": options.file.size } : options.headers || COMMON_HEADERS;
      const bodyData = options.file
        ? API.createFormData(body, options.file)
        : options.formData
        ? body
        : JSON.stringify(body);

      if (noToken) {
        response = await fetch(path, {
          method: 'POST',
          headers,
          body: bodyData,
        });
      } else {
        const token = await getToken();
        response = await fetch(path, {
          method: 'POST',
          headers: {
            ...headers,
            Authorization: `Bearer ${token}`,
          },
          body: bodyData,
        });
      }
      return await handleResponse(response);
    } catch (e) {
      return { status: API_RESPONSES.ERROR, message: e, code: e.code || null };
    }
  },

  put: async (path = '', body = {}, noToken = false) => {
    try {
      let response = null;

      if (noToken) {
        response = await fetch(path, {
          method: 'PUT',
          headers: {
            ...COMMON_HEADERS,
          },
          body: JSON.stringify(body),
        });
      } else {
        const token = await getToken();
        response = await fetch(path, {
          method: 'PUT',
          headers: {
            ...COMMON_HEADERS,
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(body),
        });
      }

      return await handleResponse(response);
    } catch (e) {
      return { status: API_RESPONSES.ERROR, message: e, code: e.code || null };
    }
  },

  delete: async (path = '', body = {}) => {
    try {
      const token = await getToken();
      const response = await fetch(path, {
        method: 'DELETE',
        headers: {
          ...COMMON_HEADERS,
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(body),
      });

      return await handleResponse(response);
    } catch (e) {
      return { status: API_RESPONSES.ERROR, message: e, code: e.code || null };
    }
  },
};

export const API_HOST = process.env.REACT_APP_API_HOST;
export const API_HOST_LINK = process.env.REACT_APP_API_HOST_LINK;
export const API_HOST_NOTIFICATIONS = process.env.REACT_APP_API_HOST_NOTIFICATIONS;
export const API_HOST_LOANS = process.env.REACT_APP_API_HOST_LOANS;
export const API_HOST_PROFILE = process.env.REACT_APP_API_HOST_PROFILE;
export const API_HOST_MOBILE_BALANCE = process.env.REACT_APP_API_HOST_MOBILE_BALANCE;
export const API_HOST_ITAU = process.env.REACT_APP_API_HOST_ITAU;
export const API_HOST_USERS = process.env.REACT_APP_API_HOST_USERS;
export const API_HOST_INVOICES = process.env.REACT_APP_API_HOST_INVOICES;
export const API_HOST_CRM = process.env.REACT_APP_API_HOST_CRM;
export const API_HOST_PROVIDERS = process.env.REACT_APP_API_HOST_PROVIDERS;
export const API_HOST_PROVIDERS_SUBSCRIPTIONS = process.env.REACT_APP_API_HOST_PROVIDERS_SUBSCRIPTIONS;

export const PUBLIC_PATH = 'public';
export const PRIVATE_PATH = 'private';
export const API_PUBLIC = `${API_HOST}/${PUBLIC_PATH}/api`;
export const API_PRIVATE = `${API_HOST}/${PRIVATE_PATH}/api`;
export const API_COMMON = `${API_HOST}/api`;
export const API_LINKS = `${API_HOST_LINK}/api`;
export const API_NOTIFICATIONS = `${API_HOST_NOTIFICATIONS}/api`;
export const API_LOANS = `${API_HOST_LOANS}/api`;
export const API_PROFILE = `${API_HOST_PROFILE}/api`;
export const API_MOBILE_BALANCE = `${API_HOST_MOBILE_BALANCE}/api`;
export const API_ITAU = `${API_HOST_ITAU}/gateway/api`;
export const API_USERS = `${API_HOST_USERS}/api`;
export const API_INVOICES = `${API_HOST_INVOICES}/api`;
export const API_CRM = `${API_HOST_CRM}/api`;
export const API_PROVIDERS = `${API_HOST_PROVIDERS}/api`;
export const API_PROVIDERS_SUBSCRIPTIONS = `${API_HOST_PROVIDERS_SUBSCRIPTIONS}/api`;

export const API_PATHS = (search) => {
  const queryParams = search ? `?${search}` : '';
  return {
    LANGUAGES: (language) => `${API_HOST}/${PUBLIC_PATH}/languages/${language}`,
    USER: (merchantTraceId) => `${API_PRIVATE}/users/${merchantTraceId}`,
    MERCHANTS: (merchantTraceId) => ({
      ACCOUNTS: () => `${API_COMMON}/merchants/${merchantTraceId}/accounts${queryParams}`,
      ACCOUNT_DETAILS: (traceId, view) => {
        if (view === 0) {
          return `${API_COMMON}/merchants/${merchantTraceId}/accounts/${traceId}${queryParams}`;
        }
        return `${API_COMMON}/merchants/${merchantTraceId}/accounts/${traceId}:by_date${queryParams}`;
      },
      PROFILE: {
        DATA: () => `${API_PROFILE}/merchants/${merchantTraceId}`,
        BRANCHES: () => `${API_PROFILE}/merchants/${merchantTraceId}/branches`,
        BRANCH_ADDRESS: (branchTraceId) =>
          `${API_PROFILE}/merchants/${merchantTraceId}/branches/${branchTraceId}/principal_address`,
      },
      CRM: {
        CATEGORIES: (categoryType) => `${API_CRM}/categories?type=${categoryType}`,
        COUNTRIES: () => `${API_CRM}/countries`,
        DEPARTMENTS_BY_COUNTRY: (countryId) => `${API_CRM}/countries/${countryId}/departments`,
        CITIES_BY_DEPARTMENTS: (departmentId) => `${API_CRM}/departments/${departmentId}/cities`,
        START_AFFILIATION: () => `${API_CRM}/people/affiliations`,
        AFFILIATION_REQUEST_EMAIL_VERIFICATION_CODE: (traceId) =>
          `${API_CRM}/people/affiliations/${traceId}/challenge:email`,
        AFFILIATION_REQUEST_PHONE_VERIFICATION_CODE: (traceId) =>
          `${API_CRM}/people/affiliations/${traceId}/challenge:phone`,
        AFFILIATION_VERIFY_VERIFICATION_CODE: (traceId) => `${API_CRM}/people/affiliations/${traceId}/challenge:verify`,
        AFFILIATION_GET_IDENTITY_VERIFICATION_LINK: (traceId) =>
          `${API_CRM}/people/affiliations/${traceId}/identity_verification`,
        AFFILIATION_GET_IDENTITY_VERIFICATION_STATUS: (traceId) =>
          `${API_CRM}/people/affiliations/${traceId}/identity_verification:status`,
        BUSSINES_NAME_TYPES: () => `${API_CRM}/business_name_types`,
        MERCHANT_START_AFFILIATION: () => `${API_CRM}/merchant_affiliations`,

        MERCHANT_AFFILIATION_VALIDATE_EMAIL: (traceId) => `${API_CRM}/merchant_affiliations/${traceId}:email`,
        MERCHANT_AFFILIATION_VALIDATE_PHONE: (traceId) => `${API_CRM}/merchant_affiliations/${traceId}:phone`,

        MERCHANT_AFFILIATION_REQUEST_PHONE_VERIFICATION_CODE: (traceId) =>
          `${API_CRM}/merchant_affiliations/${traceId}/challenge:phone`,
        MERCHANT_AFFILIATION_REQUEST_EMAIL_VERIFICATION_CODE: (traceId) =>
          `${API_CRM}/merchant_affiliations/${traceId}/challenge:email`,
        MERCHANT_AFFILIATION_VERIFY_VERIFICATION_CODE: (traceId) =>
          `${API_CRM}/merchant_affiliations/${traceId}/challenge:verify`,
        MCCS: () => `${API_CRM}/mccs`,
        PAYMENT_PLANS: () => `${API_CRM}/payment_plans`,
        MERCHANT_AFFILIATION_DATA: (traceId) => `${API_CRM}/merchant_affiliations/${traceId}/merchant_data`,
        MERCHANT_AFFILIATION_DATA_CONFIRM: (traceId) =>
          `${API_CRM}/merchant_affiliations/${traceId}/merchant_data:confirm`,
        MERCHANT_AFFILIATION_FILE: (traceId) => `${API_CRM}/merchant_affiliations/${traceId}/files${queryParams}`,
        POSITIONS: () => `${API_CRM}/positions`,
        MERCHANT_REPRESENTATIVE_DATA: (traceId) => `${API_CRM}/merchant_affiliations/${traceId}/representative_data`,
        MERCHANT_REPRESENTATIVE_DATA_CONFIRM: (traceId) =>
          `${API_CRM}/merchant_affiliations/${traceId}/representative_data:confirm`,
        MERCHANT_IDENTITY_VERIFICATION: (traceId) =>
          `${API_CRM}/merchant_affiliations/${traceId}/identity_verification`,
      },
      BRANCHES: (branchId) =>
        branchId
          ? `${API_PRIVATE}/merchants/${merchantTraceId}/branches/${branchId}${queryParams}`
          : `${API_PRIVATE}/merchants/${merchantTraceId}/branches${queryParams}`,
      SUMMARIES: (branchId) =>
        branchId
          ? `${API_PRIVATE}/summaries/merchants/${merchantTraceId}/branches/${branchId}${queryParams}`
          : `${API_PRIVATE}/summaries/merchants/${merchantTraceId}${queryParams}`,
      CALENDARIES: (branchId) =>
        branchId
          ? `${API_PRIVATE}/calendaries/merchants/${merchantTraceId}/branches/${branchId}${queryParams}`
          : `${API_PRIVATE}/calendaries/merchants/${merchantTraceId}${queryParams}`,
      INVOICES: (branchId) =>
        branchId
          ? `${API_COMMON}/v2/merchants/${merchantTraceId}/branches/${branchId}/sales:day${queryParams}`
          : `${API_COMMON}/v2/merchants/${merchantTraceId}/sales:day${queryParams}`,
      INVOICES_BY_INVOICE_ID: (invoiceId) =>
        `${API_PRIVATE}/sales/merchants/${merchantTraceId}/invoices/${invoiceId}${queryParams}`,
      ACTIVITIES: (branchId) =>
        branchId
          ? `${API_PRIVATE}/activities/merchants/${merchantTraceId}/branches/${branchId}${queryParams}`
          : `${API_PRIVATE}/activities/merchants/${merchantTraceId}${queryParams}`,
      REVERSALS: (branchId) =>
        branchId
          ? `${API_PRIVATE}/reversals/merchants/${merchantTraceId}/branches/${branchId}${queryParams}`
          : `${API_PRIVATE}/reversals/merchants/${merchantTraceId}${queryParams}`,
      PLANS: (paymentPlan) => `${API_PRIVATE}/merchants/${merchantTraceId}/plans/${paymentPlan}`,
      VOUCHERS: () => `${API_INVOICES}/merchants/${merchantTraceId}/invoices/${queryParams}`,
      PAYMENTS: (branchId) =>
        branchId
          ? `${API_PRIVATE}/payments/merchants/${merchantTraceId}/branches/${branchId}${queryParams}`
          : `${API_PRIVATE}/payments/merchants/${merchantTraceId}${queryParams}`,
      LOANS: () => `${API_LOANS}/merchants/${merchantTraceId}/loans`,
      CHECK_LOANS_AVAILABILITY: () => `${API_LOANS}/merchants/${merchantTraceId}/loans/:offer_state`,
      LOAN_REDIRECT_LINK: () => `${API_LOANS}/merchants/${merchantTraceId}/loans/:redirect`,
      EXPORT: (entity, traceId) => {
        switch (entity) {
          case 'activities':
            return `${API_PRIVATE}/activities/merchants/${merchantTraceId}:export${queryParams}`;
          case 'payments':
            return traceId
              ? `${API_PRIVATE}/payments/merchants/${merchantTraceId}/branches/${traceId}:export${queryParams}`
              : `${API_PRIVATE}/payments/merchants/${merchantTraceId}/:export${queryParams}`;
          case 'sales':
            return `${API_PRIVATE}/sales/merchants/${merchantTraceId}/invoices:export${queryParams}`;
          case 'reversals':
            return traceId
              ? `${API_PRIVATE}/reversals/merchants/${merchantTraceId}/branches/${traceId}:export${queryParams}`
              : `${API_PRIVATE}/reversals/merchants/${merchantTraceId}:export${queryParams}`;
          case 'vouchers':
            return traceId
              ? `${API_INVOICES}/merchants/${merchantTraceId}/branches/${traceId}/invoices:export${queryParams}`
              : `${API_INVOICES}/merchants/${merchantTraceId}/invoices:export${queryParams}`;
          case 'account-details':
            return `${API_COMMON}/merchants/${merchantTraceId}/accounts/${traceId}:export${queryParams}`;
          default:
            return '';
        }
      },
      SECRETS: (traceId) => {
        if (!traceId) {
          return `${API_LINKS}/merchants/${merchantTraceId}/secrets`;
        } else {
          return `${API_LINKS}/merchants/${merchantTraceId}/secrets?branchTraceId=${traceId}`;
        }
      },
      LINKS: (traceId) => {
        if (!traceId) {
          return `${API_LINKS}/merchants/${merchantTraceId}/links${queryParams}`;
        } else {
          return `${API_LINKS}/merchants/${merchantTraceId}/links/${traceId}${queryParams}`;
        }
      },
      LINK_RESTRICTIONS: (traceId) => {
        return `${API_PROFILE}/merchants/${merchantTraceId}/branches/${traceId}/restrictions`;
      },
      LINK_TRANSACTIONS: (traceId) => {
        return `${API_COMMON}/merchants/${merchantTraceId}/online_sales/${traceId}:related${queryParams}`;
      },
      LINKS_EXPORT: () => {
        return `${API_LINKS}/merchants/${merchantTraceId}/links:export${queryParams}`;
      },
      NOTIFICATIONS: (notificationId) =>
        notificationId
          ? `${API_NOTIFICATIONS}/merchants/${merchantTraceId}/notifications/${notificationId}`
          : `${API_NOTIFICATIONS}/merchants/${merchantTraceId}/notifications`,
      MOBILE_BALANCE_STATUS: () => `${API_MOBILE_BALANCE}/merchants/${merchantTraceId}:is_active`,
      MOBILE_BALANCE: () => `${API_MOBILE_BALANCE}/merchants/${merchantTraceId}:balance`,
      MOBILE_BALANCE_ACTIVE_POS: () => `${API_MOBILE_BALANCE}/merchants/${merchantTraceId}/pos:active${queryParams}`,
      MOBILE_BALANCE_HISTORY: () => `${API_MOBILE_BALANCE}/merchants/${merchantTraceId}:history${queryParams}`,
      POS_ALIASES: () => `${API_PROFILE}/merchants/${merchantTraceId}/pos:alias`,
      BRANCH_ISSUERS: (branchId) => `${API_ITAU}/merchants/${merchantTraceId}/branches/${branchId}/issuers`,
      ITAU_BRANCHES: () => `${API_ITAU}/merchants/${merchantTraceId}/branches`,
      ITAU_SALES: () => `${API_ITAU}/merchants/${merchantTraceId}/transactions${queryParams}`,
      ITAU_SALE: (traceId) => `${API_ITAU}/merchants/${merchantTraceId}/branches/${traceId}/transactions`,
      MANAGE_USERS: {
        GET_ALL: () => `${API_USERS}/merchants/${merchantTraceId}/workers${queryParams}`,
        GET: (userTraceId) => `${API_USERS}/merchants/${merchantTraceId}/workers/${userTraceId}`,
        CREATE: () => `${API_USERS}/merchants/${merchantTraceId}/workers`,
        UPDATE: (userTraceId) => `${API_USERS}/merchants/${merchantTraceId}/workers/${userTraceId}`,
        UPDATE_PASSWORD: (userTraceId) => `${API_USERS}/merchants/${merchantTraceId}/workers/${userTraceId}:password`,
        DELETE: (userTraceId) => `${API_USERS}/merchants/${merchantTraceId}/workers/${userTraceId}`,
        DISABLE: (userTraceId) => `${API_USERS}/merchants/${merchantTraceId}/workers/${userTraceId}:disable`,
        ENABLE: (userTraceId) => `${API_USERS}/merchants/${merchantTraceId}/workers/${userTraceId}:enable`,
        ROLES: () => `${API_USERS}/merchants/${merchantTraceId}/roles${queryParams}`,
        COUNT: () => `${API_USERS}/merchants/${merchantTraceId}`,
        REQUEST_CHANGE_EMAIL: () => `${API_USERS}/merchants/${merchantTraceId}/profile:email`,
        REQUEST_CHANGE_PHONE_NUMBER: () => `${API_USERS}/merchants/${merchantTraceId}/profile:phone`,
        VERIFY_DATA_CHANGE: () => `${API_USERS}/merchants/${merchantTraceId}/profile:verify`,
      },
      MANAGE_PAYMENT_METHODS: {
        GET_ALL: (hasStatuses) =>
          hasStatuses
            ? `${API_CRM}/merchants/${merchantTraceId}/payment_methods/:requests${queryParams}`
            : `${API_CRM}/merchants/${merchantTraceId}/payment_methods${queryParams}`,
        GET_PAYMENT_ENTITIES: () => `${API_CRM}/merchants/${merchantTraceId}/payment_entities${queryParams}`,
        GET_PAYMENT_ENTITY_REGEX: (key) => `${API_CRM}/merchants/${merchantTraceId}/payment_entities/${key}`,
        GET_PAYMENT_CURRENCIES: () => `${API_COMMON}/merchants/${merchantTraceId}/currencies`,
        GET_BRANCHES_BY_PAYMENT_METHOD: (paymentMethodTraceId) =>
          `${API_PROFILE}/merchants/${merchantTraceId}/payment_methods/${paymentMethodTraceId}/branches${queryParams}`,
        ADD_PAYMENT_METHOD: () => `${API_USERS}/merchants/${merchantTraceId}/profile:payment_method`,
        VERIFY_CHANGE_PAYMENT_METHOD: () => `${API_USERS}/merchants/${merchantTraceId}/profile:verify`,
        ASSIGN_BRANCHES_TO_PAYMENT_METHOD: (branchTraceId, traceId) =>
          `${API_PROFILE}/merchants/${merchantTraceId}/branches/${branchTraceId}/payment_methods/${traceId}`,
        GET_PAYMENT_METHODS_BY_BRANCH: (branchTraceId) =>
          `${API_CRM}/merchants/${merchantTraceId}/payment_methods/branches/${branchTraceId}?count=1000&page=1`,
        GET_BRANCHES_WITH_NO_PAYMENT_METHOD: () => `${API_CRM}/merchants/${merchantTraceId}/restrictions`,
        GET_PAYMENT_METHOD_FULL_ACCOUNT_NUMBER: (paymentMethodTraceId) =>
          `${API_CRM}/merchants/${merchantTraceId}/payment_methods/${paymentMethodTraceId}:unmasked`,
      },
      MANAGE_POS_REQUESTS: {
        GET_ALL_PENDING: () => `${API_CRM}/merchants/${merchantTraceId}/physical_pos_requests${queryParams}`,
        GET_TYPES: () => `${API_CRM}/physical_pos_type${queryParams}`,
        ADD_REQUEST: (branchTraceId) =>
          `${API_CRM}/merchants/${merchantTraceId}/branches/${branchTraceId}/physical_pos_requests`,
        CANCEL_REQUEST: (posTraceId) => `${API_CRM}/merchants/${merchantTraceId}/physical_pos_requests/${posTraceId}`,
        GET_POS_BY_BRANCH: (branchTraceId) =>
          `${API_CRM}/merchants/${merchantTraceId}/branches/${branchTraceId}/physical_pos${queryParams}`,
      },
      MANAGE_SUBSCRIPTIONS: {
        SUBSCRIPTIONS: (subscriptionTraceId) =>
          subscriptionTraceId
            ? `${API_PROVIDERS}/merchants/${merchantTraceId}/subscriptions/${subscriptionTraceId}${queryParams}`
            : `${API_PROVIDERS}/merchants/${merchantTraceId}/subscriptions${queryParams}`,
        SUBSCRIPTION_STATUS: (subscriptionTraceId) =>
          `${API_PROVIDERS}/merchants/${merchantTraceId}/subscriptions/${subscriptionTraceId}/status`,
        SUBSCRIPTION_ADD_CHARGE: () => `${API_PROVIDERS}/merchants/${merchantTraceId}/charges`,
        SUBSCRIPTION_CHARGES: (paymentTraceId) =>
          paymentTraceId
            ? `${API_PROVIDERS}/merchants/${merchantTraceId}/subscription_charges/${paymentTraceId}${queryParams}`
            : `${API_PROVIDERS}/merchants/${merchantTraceId}/subscription_charges${queryParams}`,
        SUBSCRIPTION_CHARGE_STATE: (chargeTraceId) =>
          `${API_PROVIDERS}/merchants/${merchantTraceId}/charges/${chargeTraceId}/state`,
        SUBSCRIPTION_CHARGE: (chargeTraceId) =>
          `${API_PROVIDERS}/merchants/${merchantTraceId}/charges/${chargeTraceId}`,
        SUBSCRIPTION_RETRY_CHARGE: (messageId) =>
          `${API_PROVIDERS}/merchants/${merchantTraceId}/charges/${messageId}:retry`,
        SUBSCRIPTION_PROVIDERS: () => `${API_PROVIDERS}/merchants/${merchantTraceId}/subscription_providers`,
        SUBSCRIPTION_SERVICES: () => `${API_PROVIDERS}/merchants/${merchantTraceId}/subscription_products`,
        EXPORT_SUBSCRIPTIONS: () => `${API_PROVIDERS}/merchants/${merchantTraceId}/subscriptions:xlsx${queryParams}`,
        EXPORT_SUBSCRIPTION_CHARGES: () =>
          `${API_PROVIDERS}/merchants/${merchantTraceId}/subscription_charges:xlsx${queryParams}`,
      },
    }),
    PROVIDERS: (merchantTraceId) => ({
      GET: () => `${API_PROVIDERS}/providers/${merchantTraceId}`,
      ME: () => `${API_PROVIDERS}/providers/${merchantTraceId}/me`,
      PRODUCTS: (productTraceId) =>
        productTraceId
          ? `${API_PROVIDERS}/providers/${merchantTraceId}/products/${productTraceId}${queryParams}`
          : `${API_PROVIDERS}/providers/${merchantTraceId}/products${queryParams}`,
      AVAILABLE_PRODUCTS: (providerTraceId) =>
        `${API_PROVIDERS}/providers/${providerTraceId}/available_products${queryParams}`,
      SUBSCRIBERS: (subscriberTraceId) =>
        subscriberTraceId
          ? `${API_PROVIDERS}/providers/${merchantTraceId}/subscribers/${subscriberTraceId}${queryParams}`
          : `${API_PROVIDERS}/providers/${merchantTraceId}/subscribers${queryParams}`,
      SUBSCRIBER_CHARGES: (chargeTraceId) =>
        chargeTraceId
          ? `${API_PROVIDERS}/providers/${merchantTraceId}/subscriber_charges/${chargeTraceId}${queryParams}`
          : `${API_PROVIDERS}/providers/${merchantTraceId}/subscriber_charges${queryParams}`,
      EXPORT_PROVIDER_SERVICES: () => `${API_PROVIDERS}/providers/${merchantTraceId}/products:xlsx${queryParams}`,
      EXPORT_PROVIDER_SUBSCRIBERS: () => `${API_PROVIDERS}/providers/${merchantTraceId}/subscribers:xlsx${queryParams}`,
      EXPORT_PROVIDER_CHARGES: () =>
        `${API_PROVIDERS}/providers/${merchantTraceId}/subscriber_charges:xlsx${queryParams}`,
    }),
    PROVIDERS_SUBSCRIPTIONS: (merchantTraceId) => ({
      GET: () => `${API_PROVIDERS_SUBSCRIPTIONS}/merchants/${merchantTraceId}`,
      PLANS: () => `${API_PROVIDERS_SUBSCRIPTIONS}/merchants/${merchantTraceId}/subscription_plans${queryParams}`,
      UPDATE_PLAN_STATUS: (subscriptionPlanTraceId) =>
        `${API_PROVIDERS_SUBSCRIPTIONS}/merchants/${merchantTraceId}/subscription_plans/${subscriptionPlanTraceId}/status`,
      GET_SHARE_LINK: (subscriptionPlanTraceId) =>
        `${API_PROVIDERS_SUBSCRIPTIONS}/merchants/${merchantTraceId}/subscription_plans/${subscriptionPlanTraceId}/link`,
      ADD_PLAN: () => `${API_PROVIDERS_SUBSCRIPTIONS}/merchants/${merchantTraceId}/subscription_plans`,
      PLAN: (subscriptionPlanTraceId) =>
        `${API_PROVIDERS_SUBSCRIPTIONS}/merchants/${merchantTraceId}/subscription_plans/${subscriptionPlanTraceId}`,
      EXPORT_PLANS: () =>
        `${API_PROVIDERS_SUBSCRIPTIONS}/merchants/${merchantTraceId}/subscription_plans/:export${queryParams}`,
      SUBSCRIPTIONS: () => `${API_PROVIDERS_SUBSCRIPTIONS}/merchants/${merchantTraceId}/subscriptions${queryParams}`,
      EXPORT_SUBSCRIPTIONS: () =>
        `${API_PROVIDERS_SUBSCRIPTIONS}/merchants/${merchantTraceId}/subscriptions/:export${queryParams}`,
      SUBSCRIBERS: () => `${API_PROVIDERS_SUBSCRIPTIONS}/merchants/${merchantTraceId}/subscribers${queryParams}`,
      UPDATE_SUBSCRIPTION_STATUS: (subscriptionTraceId) =>
        `${API_PROVIDERS_SUBSCRIPTIONS}/merchants/${merchantTraceId}/subscriptions/${subscriptionTraceId}/status${queryParams}`,
      SUBSCRIPTION: (subscriptionTraceId) =>
        `${API_PROVIDERS_SUBSCRIPTIONS}/merchants/${merchantTraceId}/subscriptions/${subscriptionTraceId}`,
      TRANSACTIONS: () => `${API_PROVIDERS_SUBSCRIPTIONS}/merchants/${merchantTraceId}/transactions${queryParams}`,
      UPDATE_SUBSCRIBER: (subscriberId) =>
        `${API_PROVIDERS_SUBSCRIPTIONS}/merchants/${merchantTraceId}/subscribers/${subscriberId}`,
      EXPORT_TRANSACTIONS: () =>
        `${API_PROVIDERS_SUBSCRIPTIONS}/merchants/${merchantTraceId}/transactions/:export${queryParams}`,
      PROMOTIONS: () => `${API_PROVIDERS_SUBSCRIPTIONS}/merchants/${merchantTraceId}/promotions${queryParams}`,
      PROMOTION: (promotionTraceId) =>
        `${API_PROVIDERS_SUBSCRIPTIONS}/merchants/${merchantTraceId}/promotions/${promotionTraceId}${queryParams}`,
      ADD_PROMOTION: (isNew, subscriptionPlanTraceId) =>
        isNew
          ? `${API_PROVIDERS_SUBSCRIPTIONS}/merchants/${merchantTraceId}/subscription_plans/${subscriptionPlanTraceId}/promotions`
          : `${API_PROVIDERS_SUBSCRIPTIONS}/merchants/${merchantTraceId}/promotions/assign_to_branch${queryParams}`,
      EXPORT_PROMOTIONS: () =>
        `${API_PROVIDERS_SUBSCRIPTIONS}/merchants/${merchantTraceId}/promotions/:export${queryParams}`,
      GET_SUBSCRIPTION_SHARE_LINK: (subscriptionTraceId) =>
        `${API_PROVIDERS_SUBSCRIPTIONS}/merchants/${merchantTraceId}/subscriptions/${subscriptionTraceId}/modify`,
      TERMS_AND_CONDITIONS: (branchTraceId) =>
        `${API_PROVIDERS_SUBSCRIPTIONS}/merchants/${merchantTraceId}/branches/${branchTraceId}/terms_and_conditions`,
      ADD_TERMS_AND_CONDITIONS: (branchTraceId) =>
        `${API_PROVIDERS_SUBSCRIPTIONS}/merchants/${merchantTraceId}/branches/${branchTraceId}`,
    }),
    PHONE_COMPANIES: () => `${API_MOBILE_BALANCE}/phone_companies`,
    HOME: (merchantTraceId) => ({
      TRANSACTIONS: () => `${API_COMMON}/merchants/${merchantTraceId}/transactions${queryParams}`,
      BALANCE: () => `${API_COMMON}/merchants/${merchantTraceId}/balance`,
      PENDING_BALANCE: () => `${API_COMMON}/merchants/${merchantTraceId}/balance:pending${queryParams}`,
      PURCHASE_TODAY_BALANCE: () => `${API_COMMON}/merchants/${merchantTraceId}/balance:purchases_today${queryParams}`,
      TIPS_TODAY_BALANCE: () => `${API_COMMON}/merchants/${merchantTraceId}/balance:tips_today${queryParams}`,
    }),
  };
};

export const API_RESPONSES = {
  SUCCESS: 'success',
  ERROR: 'error',
};

export const OB_TYPES = {
  PHYSICAL: 1,
  LEGAL: 2,
};

export default API;
