import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { push, replace } from 'connected-react-router';
import { toast } from 'react-toastify';
import qs from 'query-string';

import ROUTES from '../../../routes';
import {
  Box,
  BoxMenu,
  BoxSeparator,
  BranchesSelector,
  Button,
  ContentBox,
  EmptyMessage,
  FiltersContainer,
  FiltersDateSelector,
  FiltersSelector,
  FiltersSeparator,
  FiltersToggle,
  ForbiddenSection,
  OperationsTable,
  Page,
  PaginationSimple,
  TitleBox,
} from '../../../components';
import { business as businessActions } from '../../../actions';
import { Language, Utils } from '../../../utils';

import ListItemText from '@material-ui/core/ListItemText';
import { Visibility as VisisbilityIcon } from '@material-ui/icons';
import MoreVerticalIcon from '../../../static/images/icons/more_vertical.svg';
import { SaveAlt as SaveAltIcon, Check as CheckIcon, Clear as ClearIcon } from '@material-ui/icons';

import makeClasses from './styles';
import { Hidden, IconButton, ListItemIcon, Menu, MenuItem, useMediaQuery, useTheme } from '@material-ui/core';
import clsx from 'clsx';
import { Policies } from '../../../utils/Policies';
import moment from 'moment';
import { CUSTOM_FILTER, getDateRangeForFilter } from '../../../components/FiltersDateSelector';
import { toISOString } from '../../../utils/Utils';

const STATUS_OPTIONS = [
  { id: 'CANCELED', label: 'ManagePayments.StatusFilter_CANCELED' },
  { id: 'ERROR', label: 'ManagePayments.StatusFilter_ERROR' },
  { id: 'COMPLETED', label: 'ManagePayments.StatusFilter_COMPLETED' },
  { id: 'PARTIAL_EXECUTED', label: 'ManagePayments.StatusFilter_PARTIAL_EXECUTED' },
  { id: 'IN_PROGRESS', label: 'ManagePayments.StatusFilter_IN_PROGRESS' },
  { id: 'MANUAL_REJECTED', label: 'ManagePayments.StatusFilter_MANUAL_REJECTED' },
];

const STATUS_FILTER_OPTIONS = [
  { id: 'CANCELED', name: 'ManagePayments.StatusFilter_CANCELED' },
  { id: 'ERROR', name: 'ManagePayments.StatusFilter_ERROR' },
  { id: 'COMPLETED', name: 'ManagePayments.StatusFilter_COMPLETED' },
  { id: 'PARTIAL_EXECUTED', name: 'ManagePayments.StatusFilter_PARTIAL_EXECUTED' },
  { id: 'IN_PROGRESS|APPROVED', name: 'ManagePayments.StatusFilter_IN_PROGRESS' },
  { id: 'MANUAL_REJECTED', name: 'ManagePayments.StatusFilter_MANUAL_REJECTED' },
];

const VIEW_FILTER_OPTIONS = [
  { id: 0, label: 'ManagePayments.ViewFilter.PENDING_PAYMENTS' },
  { id: 1, label: 'ManagePayments.ViewFilter.PAYMENTS_HISTORY' },
];

const ManagePaymentsScreen = ({ history }) => {
  const dispatch = useDispatch();
  const classes = makeClasses();
  const searchParams = qs.parse(history.location.search);
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));

  const [menuAnchorEl, setMenuAnchorEl] = useState(null);
  const [menuOpenIndex, setMenuOpenIndex] = useState(-1);
  const [paginationPage, setPaginationPage] = useState(searchParams.page ? parseInt(searchParams.page) : 1);
  const [forbiddenSection, setForbbidenSection] = useState(false);
  const [pendingPayments, setPendingPayments] = useState({});
  const [paymentsHistory, setPaymentsHistory] = useState({});
  const [selectedBranch, setSelectedBranch] = useState(searchParams.bid || 'all');
  const [selectedProvider, setSelectedProvider] = useState(searchParams.pid || 'all');
  const [selectedService, setSelectedService] = useState(searchParams.sid || 'all');
  const [subscriptionProvidersOptions, setSubscriptionProvidersOptions] = useState([]);
  const [subscriptionServicesOptions, setSubscriptionServicesOptions] = useState([]);
  const [orderByField, setOrderByField] = useState(searchParams.orderByField ? searchParams.orderByField : 'CreatedAt');
  const [orderByDesc, setOrderByDesc] = useState(searchParams.orderByDesc ? searchParams.orderByDesc === 'desc' : true);
  const [selectedFilter, setSelectedFilter] = useState(searchParams.fid ? parseInt(searchParams.fid) : 3);
  const [selectedCustomFilter, setSelectedCustomFilter] = useState(
    parseInt(searchParams.fid) === CUSTOM_FILTER ? { startDate: searchParams.fsd, endDate: searchParams.fed } : {}
  );
  const [selectedStatus, setSelectedStatus] = useState(searchParams.tid ? searchParams.tid : 'all');
  const [filtersLoaded, setFiltersLoaded] = useState(false);
  const [selectedView, setSelectedView] = useState(searchParams.view ? parseInt(searchParams.view) : 0);
  const { policies, languageTexts, branches } = useSelector((state) => ({
    policies: state.user.userData.policies || [],
    languageTexts: state.language.texts || {},
    branches: state.business.branches || [],
  }));
  const i18n = Language(languageTexts);
  const canExport = policies.includes(Policies.types.SUBSCRIBER) && policies.includes(Policies.types.SUPER_READ);
  const canManage = policies.includes(Policies.types.SUBSCRIBER_WRITE);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (!policies || !policies.includes(Policies.types.SUBSCRIBER)) {
      setForbbidenSection(true);
    }
  }, [policies]);

  useEffect(() => {
    if (!forbiddenSection) {
      loadData();
      loadFiltersData();
    }
  }, [
    forbiddenSection,
    selectedBranch,
    selectedView,
    paginationPage,
    orderByField,
    orderByDesc,
    selectedProvider,
    selectedService,
    selectedFilter,
    selectedCustomFilter,
    selectedStatus,
  ]);

  const loadData = () => {
    if (selectedView === 0) {
      loadPendingPayments();
    } else {
      loadPaymentsHistory();
    }
  };

  const loadFiltersData = () => {
    if (filtersLoaded) {
      return;
    }

    dispatch(
      businessActions.getSubscriptionProviders((data) => {
        if (data) {
          const options = data.elements
            ? data.elements.filter((e) => e).map((e) => ({ id: e.traceId, name: e.name }))
            : [];
          setSubscriptionProvidersOptions(options);
        }
      })
    );
    dispatch(
      businessActions.getSubscriptionServices((data) => {
        if (data) {
          const options = data.elements
            ? data.elements.filter((e) => e).map((e) => ({ id: e.traceId, name: e.name }))
            : [];
          setSubscriptionServicesOptions(options);
        }
      })
    );

    setFiltersLoaded(true);
  };

  const loadPendingPayments = () => {
    const dateFilter = getDateRangeForFilter(
      selectedFilter,
      selectedFilter === CUSTOM_FILTER ? selectedCustomFilter : null
    );
    const startDate = toISOString(dateFilter.startDate);
    const endDate = toISOString(dateFilter.endDate);

    dispatch(
      businessActions.getPendingPayments(
        selectedBranch !== 'all' ? selectedBranch : null,
        null,
        orderByField,
        orderByDesc,
        paginationPage,
        startDate,
        endDate,
        selectedProvider !== 'all' ? selectedProvider : null,
        selectedService !== 'all' ? selectedService : null,
        (data) => {
          setPendingPayments(data);
        }
      )
    );
  };

  const loadPaymentsHistory = () => {
    const dateFilter = getDateRangeForFilter(
      selectedFilter,
      selectedFilter === CUSTOM_FILTER ? selectedCustomFilter : null
    );
    const startDate = toISOString(dateFilter.startDate);
    const endDate = toISOString(dateFilter.endDate);

    dispatch(
      businessActions.getPaymentsHistory(
        selectedBranch !== 'all' ? selectedBranch : null,
        null,
        orderByField,
        orderByDesc,
        paginationPage,
        startDate,
        endDate,
        selectedProvider !== 'all' ? selectedProvider : null,
        selectedService !== 'all' ? selectedService : null,
        selectedStatus !== 'all' ? selectedStatus : null,
        (data) => {
          setPaymentsHistory(data);
        }
      )
    );
  };

  const toggleMenu = (index) => (e) => {
    if (menuAnchorEl) {
      setMenuAnchorEl(null);
      setMenuOpenIndex(-1);
    } else {
      setMenuAnchorEl(e.target);
      setMenuOpenIndex(index);
    }
  };

  const handleOnFilterSelected = (filter, startDate, endDate) => {
    setSelectedFilter(filter);

    const searchParams = qs.parse(history.location.search) || {};
    searchParams.fid = filter;

    if (filter === CUSTOM_FILTER) {
      setSelectedCustomFilter({ startDate, endDate });
      searchParams.fsd = startDate;
      searchParams.fed = endDate;
    }

    dispatch(
      replace({
        search: qs.stringify(searchParams),
      })
    );
  };

  const handleOnPaymentsHistoryPaginationChange = (nextPage) => {
    setPaginationPage(nextPage);

    const searchParams = qs.parse(history.location.search) || {};
    searchParams.page = nextPage;

    dispatch(
      replace({
        search: qs.stringify(searchParams),
      })
    );
  };

  const handleOnPaginationPressNext = () => {
    setPaginationPage(paginationPage + 1);
    handleOnPaymentsHistoryPaginationChange(paginationPage + 1);
  };

  const handleOnPaginationPressPrevious = () => {
    setPaginationPage(paginationPage - 1);
    handleOnPaymentsHistoryPaginationChange(paginationPage - 1);
  };

  const handleOnPaymentsHistorySortChange = (column, direction) => {
    let orderByField = '';
    let orderByDesc = false;

    if (column === 1) {
      orderByField = 'CreatedAt';
      orderByDesc = direction === 'desc';
    }

    setOrderByField(orderByField);
    setOrderByDesc(orderByDesc);

    const searchParams = qs.parse(history.location.search) || {};
    searchParams.orderByField = orderByField;
    searchParams.orderByDesc = direction;

    dispatch(
      replace({
        search: qs.stringify(searchParams),
      })
    );
  };

  const handleOnBranchSelected = (branch) => {
    setSelectedBranch(branch);

    const searchParams = qs.parse(history.location.search) || {};
    searchParams.bid = branch;

    dispatch(
      replace({
        search: qs.stringify(searchParams),
      })
    );
  };

  const handleOnProviderSelected = (provider) => {
    setSelectedProvider(provider);

    const searchParams = qs.parse(history.location.search) || {};
    searchParams.pid = provider;

    dispatch(
      replace({
        search: qs.stringify(searchParams),
      })
    );
  };

  const handleOnServiceSelected = (service) => {
    setSelectedService(service);

    const searchParams = qs.parse(history.location.search) || {};
    searchParams.sid = service;

    dispatch(
      replace({
        search: qs.stringify(searchParams),
      })
    );
  };

  const handleOnViewChange = (view) => {
    setSelectedView(view);

    const searchParams = {};
    searchParams.view = view;

    setSelectedBranch('all');
    setSelectedFilter(3);
    setSelectedCustomFilter({});
    setSelectedProvider('all');
    setSelectedService('all');
    setSelectedStatus('all');

    dispatch(
      replace({
        search: qs.stringify(searchParams),
      })
    );
  };

  const handleOnStatusSelected = (status) => {
    setSelectedStatus(status);

    const searchParams = qs.parse(history.location.search) || {};
    searchParams.tid = status;

    dispatch(
      replace({
        search: qs.stringify(searchParams),
      })
    );
  };

  const goToPaymentHistoryDetails = (traceId) => (e) => {
    e && e.preventDefault();

    const payment = paymentsHistory.elements.find((e) => e.traceId === traceId);
    goToPaymentDetails(payment.subscriptionTraceId, payment.traceId)();
  };

  const goToPaymentDetails = (subscriptionTraceId, traceId) => () => {
    dispatch(
      push(
        ROUTES.MANAGE_PROVIDERS_PAYMENTS_SUBSCRIPTIONS_PAYMENT_DETAILS.path
          .replace(':traceId', subscriptionTraceId)
          .replace(':paymentTraceId', traceId)
      )
    );
  };

  const handleOnViewPayment = (payment) => () => {
    toggleMenu()();
    goToPaymentDetails(payment.subscriptionTraceId, payment.traceId)();
  };

  const handleOnApprovePayment = (payment) => () => {
    toggleMenu()();
    dispatch(
      businessActions.subscriptionApprovePayment(payment.traceId, (result) => {
        if (result) {
          toast.success(i18n.get('ManagePayments.SuccessApproveMessage'));
          loadPendingPayments();
        } else {
          toast.error(i18n.get('ManagePayments.ErrorApproveMessage'));
        }
      })
    );
  };

  const handleOnRejectPayment = (payment) => () => {
    toggleMenu()();
    dispatch(
      businessActions.subscriptionRejectPayment(payment.traceId, (result) => {
        if (result) {
          toast.success(i18n.get('ManagePayments.SuccessRejectMessage'));
          loadPendingPayments();
        } else {
          toast.error(i18n.get('ManagePayments.ErrorRejectMessage'));
        }
      })
    );
  };

  const handleOnGridExport = () => {
    const dateFilter = getDateRangeForFilter(
      selectedFilter,
      selectedFilter === CUSTOM_FILTER ? selectedCustomFilter : null
    );
    const startDate = toISOString(dateFilter.startDate);
    const endDate = toISOString(dateFilter.endDate);
    const fileName = selectedView === 0 ? 'PagoProveedores_PagosPendientes_' : 'PagoProveedores_HistorialPagos_';

    dispatch(
      businessActions.exportSubscriptionPayments(
        selectedBranch !== 'all' ? selectedBranch : null,
        selectedProvider !== 'all' ? selectedProvider : null,
        orderByField,
        orderByDesc,
        paginationPage,
        startDate,
        endDate,
        selectedService !== 'all' ? selectedService : null,
        selectedStatus !== 'all' ? selectedStatus : null,
        selectedView === 0,
        false,
        (file) =>
          Utils.downloadFile(
            file,
            fileName +
              dateFilter.startDate.format(i18n.get('ExportDateFormat')) +
              '_' +
              dateFilter.endDate.format(i18n.get('ExportDateFormat')) +
              '.xlsx'
          )
      )
    );
  };

  const handleOnMenuItemClick = (item) => {
    dispatch(push(item.path));
  };

  const renderBranchesSelector = () => (
    <BranchesSelector branches={branches} selectedBranch={selectedBranch} onBranchSelected={handleOnBranchSelected} />
  );

  const renderFiltersDateSelector = () => (
    <FiltersDateSelector
      selectedFilter={selectedFilter}
      startDate={selectedCustomFilter.startDate}
      endDate={selectedCustomFilter.endDate}
      onFilterSelected={handleOnFilterSelected}
      align="right"
      dateFormat={i18n.get('DateFormat', false)}
    />
  );

  const renderProvidersSelector = () => {
    return (
      <FiltersSelector
        options={subscriptionProvidersOptions}
        selected={selectedProvider}
        onSelected={handleOnProviderSelected}
        defaultOption={i18n.get('ManagePayments.FilterProvidersDefaultOption')}
      />
    );
  };

  const renderServicesSelector = () => {
    return (
      <FiltersSelector
        options={subscriptionServicesOptions}
        selected={selectedService}
        onSelected={handleOnServiceSelected}
        defaultOption={i18n.get('ManagePayments.FilterServicesDefaultOption')}
      />
    );
  };

  const renderStatusesSelector = () => {
    return (
      <FiltersSelector
        options={STATUS_FILTER_OPTIONS.map((s) => ({ ...s, name: i18n.get(s.name) }))}
        selected={selectedStatus}
        onSelected={handleOnStatusSelected}
        defaultOption={i18n.get('ManagePayments.FilterStatusesDefaultOption')}
      />
    );
  };

  const renderViewToggle = () => (
    <FiltersToggle
      showLabel={false}
      showDefault={false}
      options={VIEW_FILTER_OPTIONS}
      onChange={handleOnViewChange}
      selected={selectedView}
    />
  );

  const renderPendingPayments = () => {
    if (!pendingPayments || !pendingPayments.elements || pendingPayments.elements.length === 0) {
      return <EmptyMessage textOnly>{i18n.get('ManagePayments.GridEmptyMessage')}</EmptyMessage>;
    }

    if (isSmallScreen) {
      return (
        <div>
          {pendingPayments.elements.map((payment, index) => (
            <React.Fragment key={index}>
              {index === 0 ? null : <div className={classes.boxItemSeparator} />}
              <div className={clsx(classes.boxContainer, index % 2 === 0 ? classes.boxContainerEven : null)}>
                <div className={classes.boxItemTitle}>
                  {payment.productName} - {payment.providerName}
                </div>
                {payment.description ? (
                  <div className={classes.boxItemDescription}>
                    <span>{payment.description}</span>
                  </div>
                ) : null}
                <div className={clsx(classes.boxItem, classes.boxItemCompact)}>
                  <span>{i18n.get('ManagePayments.LabelBranch')}:</span>
                  <span>{payment.branchName}</span>
                </div>
                <div className={clsx(classes.boxItem, classes.boxItemCompact)}>
                  <span>{i18n.get('ManagePayments.LabelRequestDate')}:</span>
                  <span>{moment(payment.createdAt).format(i18n.get('DateFormat'))}</span>
                </div>
                <div className={clsx(classes.boxItem, classes.boxItemCompact)}>
                  <span>{i18n.get('ManagePayments.LabelLimitDate')}:</span>
                  <span>{moment(payment.limitDate).format(i18n.get('DateFormat'))}</span>
                </div>
                <div className={clsx(classes.boxItem, classes.boxItemCompact)}>
                  <span>{i18n.get('ManagePayments.LabelAmount')}:</span>
                  <span>
                    {payment.currency} {Utils.formatCurrency(payment.amount)}
                  </span>
                </div>
                <div className={classes.listItemActions}>
                  <IconButton onClick={toggleMenu(index)}>
                    <img src={MoreVerticalIcon} alt="More" />
                  </IconButton>
                </div>
                <Menu
                  anchorEl={menuAnchorEl}
                  anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                  keepMounted
                  transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                  open={menuOpenIndex === index}
                  onClose={toggleMenu()}
                  className={classes.listItemMenu}
                >
                  <MenuItem onClick={handleOnViewPayment(payment)} className={classes.listItemMenuItem}>
                    <ListItemIcon className={classes.menuItemIcon}>
                      <VisisbilityIcon fontSize="small" />
                    </ListItemIcon>
                    <ListItemText
                      primary={i18n.get('ManagePayments.ActionViewPaymentDetails')}
                      className={classes.menuItemText}
                    />
                  </MenuItem>
                  {canManage ? (
                    <MenuItem onClick={handleOnApprovePayment(payment)} className={classes.listItemMenuItem}>
                      <ListItemIcon className={classes.menuItemIcon}>
                        <CheckIcon fontSize="small" />
                      </ListItemIcon>
                      <ListItemText
                        primary={i18n.get('ManagePayments.ActionApprovePayment')}
                        className={classes.menuItemText}
                      />
                    </MenuItem>
                  ) : null}
                  {canManage ? (
                    <MenuItem onClick={handleOnRejectPayment(payment)} className={classes.listItemMenuItem}>
                      <ListItemIcon className={classes.menuItemIcon}>
                        <ClearIcon fontSize="small" />
                      </ListItemIcon>
                      <ListItemText
                        primary={i18n.get('ManagePayments.ActionRejectPayment')}
                        className={classes.menuItemText}
                      />
                    </MenuItem>
                  ) : null}
                </Menu>
              </div>
            </React.Fragment>
          ))}
        </div>
      );
    }

    return pendingPayments.elements.map((payment, index) => (
      <React.Fragment key={payment.traceId}>
        {index !== 0 ? <div className={classes.subscriptionItemSeparator} /> : null}
        <div className={classes.subscriptionItemWrapper}>
          <div className={classes.subscriptionItemDetails}>
            <div>
              {payment.productName} <span>|</span> {payment.providerName}{' '}
              {payment.description ? <span>| {payment.description}</span> : null}
            </div>
            <div className={classes.subscriptionItemExtraDetails}>
              <div>
                <span>{i18n.get('ManagePayments.LabelBranch')}:</span> {payment.branchName}
              </div>
              <div>|</div>
              <div>
                <span>{i18n.get('ManagePayments.LabelRequestDate')}:</span>
                {moment(payment.createdAt).format(i18n.get('DateFormat'))}
              </div>
              <div>|</div>
              <div>
                <span>{i18n.get('ManagePayments.LabelLimitDate')}:</span>
                {moment(payment.limitDate).format(i18n.get('DateFormat'))}
              </div>
              <div>|</div>
              <div>
                <span>{i18n.get('ManagePayments.LabelAmount')}:</span>
                {payment.currency} {Utils.formatCurrency(payment.amount)}
              </div>
            </div>
          </div>
          <div>
            <div className={classes.listItemActions}>
              <IconButton onClick={toggleMenu(index)}>
                <img src={MoreVerticalIcon} alt="More" />
              </IconButton>
              <Menu
                anchorEl={menuAnchorEl}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                keepMounted
                transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                open={menuOpenIndex === index}
                onClose={toggleMenu()}
                className={classes.listItemMenu}
              >
                <MenuItem onClick={handleOnViewPayment(payment)} className={classes.listItemMenuItem}>
                  <ListItemIcon className={classes.menuItemIcon}>
                    <VisisbilityIcon fontSize="small" />
                  </ListItemIcon>
                  <ListItemText
                    primary={i18n.get('ManagePayments.ActionViewPaymentDetails')}
                    className={classes.menuItemText}
                  />
                </MenuItem>
                {canManage ? (
                  <MenuItem onClick={handleOnApprovePayment(payment)} className={classes.listItemMenuItem}>
                    <ListItemIcon className={classes.menuItemIcon}>
                      <CheckIcon fontSize="small" />
                    </ListItemIcon>
                    <ListItemText
                      primary={i18n.get('ManagePayments.ActionApprovePayment')}
                      className={classes.menuItemText}
                    />
                  </MenuItem>
                ) : null}
                {canManage ? (
                  <MenuItem onClick={handleOnRejectPayment(payment)} className={classes.listItemMenuItem}>
                    <ListItemIcon className={classes.menuItemIcon}>
                      <ClearIcon fontSize="small" />
                    </ListItemIcon>
                    <ListItemText
                      primary={i18n.get('ManagePayments.ActionRejectPayment')}
                      className={classes.menuItemText}
                    />
                  </MenuItem>
                ) : null}
              </Menu>
            </div>
          </div>
        </div>
      </React.Fragment>
    ));
  };

  const renderPaymentsHistory = () => {
    if (!paymentsHistory || !paymentsHistory.elements) {
      return null;
    }

    const headers = [
      {
        canHide: false,
        columnNumber: 1,
        name: i18n.get('ManagePayments.PaymentsHistoryGrid.Column1'),
        sortable: true,
        type: 'date-time',
      },
      {
        canHide: false,
        columnNumber: 2,
        name: i18n.get('ManagePayments.PaymentsHistoryGrid.Column2'),
        sortable: false,
        type: 'status',
      },
      {
        canHide: false,
        columnNumber: 3,
        name: i18n.get('ManagePayments.PaymentsHistoryGrid.Column3'),
        sortable: false,
        type: 'text',
      },
      {
        canHide: false,
        columnNumber: 4,
        name: i18n.get('ManagePayments.PaymentsHistoryGrid.Column4'),
        sortable: false,
        type: 'text',
      },
      {
        canHide: false,
        columnNumber: 5,
        name: i18n.get('ManagePayments.PaymentsHistoryGrid.Column5'),
        sortable: false,
        type: 'text',
      },
      {
        canHide: false,
        columnNumber: 6,
        name: i18n.get('ManagePayments.PaymentsHistoryGrid.Column6'),
        sortable: false,
        type: 'text',
      },
      {
        canHide: false,
        columnNumber: 7,
        name: i18n.get('ManagePayments.PaymentsHistoryGrid.Column7'),
        sortable: false,
        type: 'text',
      },
    ];
    const body = (paymentsHistory.elements || []).map((item) => ({
      rowId: item.traceId,
      columns: [
        {
          columnNumber: 1,
          data: item.createdAt,
        },
        {
          columnNumber: 2,
          data: item.status === 'APPROVED' ? 'IN_PROGRESS' : item.status,
          customData: STATUS_OPTIONS.map((o) => ({ ...o, label: i18n.get(o.label) })),
        },
        {
          columnNumber: 3,
          data: item.providerName,
        },
        {
          columnNumber: 4,
          data: item.productName,
        },
        {
          columnNumber: 5,
          data: item.branchName,
        },
        {
          columnNumber: 6,
          data: `${item.currency} ${Utils.formatCurrency(item.amount)}`,
        },
        {
          columnNumber: 7,
          data: `${item.currency} ${Utils.formatCurrency(item.dueAmount)}`,
        },
      ],
    }));

    return (
      <OperationsTable
        headers={headers}
        body={body}
        currentPage={paginationPage}
        paginationSimple
        hasMorePages={paymentsHistory.hasNext}
        onPaginationChange={handleOnPaymentsHistoryPaginationChange}
        emptyMessage={i18n.get('ManagePayments.GridEmptyMessage')}
        showDetails
        onClick={goToPaymentHistoryDetails}
        onClickProp={null}
        onTableSortChange={handleOnPaymentsHistorySortChange}
      />
    );
  };

  if (forbiddenSection) {
    return (
      <Page
        withHeader
        withSidebar
        withHeaderTitle={i18n.get('ManagePayments.Title')}
        withActivePage={ROUTES.MANAGE_PROVIDERS_PAYMENTS.id}
        withBanner={false}
      >
        <ForbiddenSection />
      </Page>
    );
  }

  return (
    <Page
      withHeader
      withSidebar
      withHeaderTitle={i18n.get('ManagePayments.Title')}
      withActivePage={ROUTES.MANAGE_PROVIDERS_PAYMENTS.id}
    >
      <BoxMenu
        items={[
          { ...ROUTES.MANAGE_PROVIDERS_PAYMENTS_SUBSCRIPTIONS, label: i18n.get('ManagePayments.BoxMenu.Item1') },
          { ...ROUTES.MANAGE_PROVIDERS_PAYMENTS_PAYMENTS, label: i18n.get('ManagePayments.BoxMenu.Item2') },
        ]}
        selectedItem={ROUTES.MANAGE_PROVIDERS_PAYMENTS_PAYMENTS.id}
        onClickItem={handleOnMenuItemClick}
      />
      <TitleBox title={i18n.get('ManagePayments.BoxTitle')} />
      <BoxSeparator />
      <Hidden xsDown>
        <Box>
          <div className={classes.viewFilterWrapper}>
            <div>{i18n.get('ManagePayments.BoxViewTitle')}</div>
            {renderViewToggle()}
          </div>
        </Box>
      </Hidden>
      <Hidden smUp>
        <ContentBox title={i18n.get('ManagePayments.BoxViewTitle')} titleBold>
          <FiltersContainer>{renderViewToggle()}</FiltersContainer>
        </ContentBox>
      </Hidden>
      <BoxSeparator size="small" />
      <ContentBox title={i18n.get('ManagePayments.BoxFiltersTitle')} titleBold>
        <FiltersContainer>
          {renderBranchesSelector()}
          <FiltersSeparator />
          {renderProvidersSelector()}
          <FiltersSeparator />
          {renderServicesSelector()}
          <FiltersSeparator />
          {selectedView === 1 ? (
            <React.Fragment>
              {renderStatusesSelector()}
              <FiltersSeparator />
            </React.Fragment>
          ) : null}
          {renderFiltersDateSelector()}
        </FiltersContainer>
      </ContentBox>
      <BoxSeparator size="small" />
      {selectedView === 0 ? (
        <Box direction="column">
          <div className={classes.gridTitleWrapper}>
            <div>
              <div>{i18n.get('ManagePayments.GridTitle')}</div>
            </div>
            {canExport ? (
              <Button
                onClick={handleOnGridExport}
                rightIcon={() => <SaveAltIcon />}
                disabled={!pendingPayments || !pendingPayments.elements || pendingPayments.elements.length === 0}
              >
                {isSmallScreen ? null : i18n.get('ManagePayments.GridExportButton')}
              </Button>
            ) : null}
          </div>
          <div className={classes.gridWrapper}>
            <div className={classes.gridSeparator} />
            {renderPendingPayments()}
          </div>
          {pendingPayments && pendingPayments.elements && pendingPayments.elements.length > 0 ? (
            <PaginationSimple
              currentPage={paginationPage}
              hasMorePages={pendingPayments.hasNext}
              onPressNext={handleOnPaginationPressNext}
              onPressPrevious={handleOnPaginationPressPrevious}
            />
          ) : null}
        </Box>
      ) : (
        <Box direction="column">
          <div className={classes.gridTitleWrapper}>
            <div>
              <div>{i18n.get('ManagePayments.GridTitle')}</div>
            </div>
            {canExport ? (
              <Button
                onClick={handleOnGridExport}
                rightIcon={() => <SaveAltIcon />}
                disabled={!paymentsHistory || !paymentsHistory.elements || paymentsHistory.elements.length === 0}
              >
                {isSmallScreen ? null : i18n.get('ManagePayments.GridExportButton')}
              </Button>
            ) : null}
          </div>
          <div className={classes.gridWrapper}>
            <div className={classes.gridSeparator} />
            {renderPaymentsHistory()}
          </div>
        </Box>
      )}
    </Page>
  );
};

ManagePaymentsScreen.id = 'com.Handy.ManagePayments';

export default ManagePaymentsScreen;
