import React, { createRef, useEffect, useRef, useState } from "react";
import CancelOutlinedIcon from "@material-ui/icons/CancelOutlined";
import makeClasses from "./styles";
import Button from "../Button";
import { useDispatch, useSelector } from "react-redux";
import { Language, Utils } from "../../utils";
import { user as userActions } from "../../actions";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Input,
  InputAdornment,
  TextField,
} from "@material-ui/core";
import { Alert, AlertTitle, Autocomplete } from "@material-ui/lab";
import TimerOutlinedIcon from "@material-ui/icons/TimerOutlined";
import CheckCircleOutlineOutlinedIcon from "@material-ui/icons/CheckCircleOutlineOutlined";
import clsx from "clsx";
import { toast } from "react-toastify";
import moment from "moment";

const TIMER_TIME = 60 * 5;

const ChangePhoneNumberModal = ({ title, open, onClose }) => {
  const classes = makeClasses();
  const dispatch = useDispatch();

  const { languageTexts } = useSelector(({ language }) => ({
    languageTexts: language.texts || {},
  }));
  const [step, setStep] = useState(1);
  const [requestingVerificationCode, setRequestingVerificationCode] =
    useState(false);
  const [verifyingCode, setVerifyingCode] = useState(null);
  const [newPhoneNumber, setNewPhoneNumber] = useState("");
  const [requestTraceId, setRequestTraceId] = useState(null);
  const [timerStartTime, setTimerStartTime] = useState(null);
  const [intervalTime, setIntervalTime] = useState(0);
  const [intervalId, setIntervalId] = useState(null);
  const [formattedTime, setFormattedTime] = useState("00:00");
  const verificationCodeRefs = useRef(
    new Array(6).fill("").map(() => createRef())
  );
  const [verificationCode, setVerificationCode] = useState(
    new Array(6).fill("")
  );
  const [countries, setCountries] = useState([]);
  const [inputCountry, setInputCountry] = useState(null);

  const i18n = Language(languageTexts);
  const canContinueToStep2 =
    !requestingVerificationCode &&
    Utils.inputs.phone.regex.test(newPhoneNumber);
  const canVerifyCode =
    verificationCode &&
    verificationCode.filter((code) => /[0-9]/.test(code)).length === 6;

  useEffect(() => {
    if (canVerifyCode) {
      _verifyCode();
    }
  }, [canVerifyCode]);

  useEffect(() => {
    if (!intervalId) {
      const id = setInterval(() => {
        setIntervalTime((currentIntervalTime) => currentIntervalTime + 1);
      }, 1000);
      setIntervalId(id);
    }
  }, [intervalId]);

  useEffect(() => {
    if (timerStartTime) {
      const timer = TIMER_TIME - moment().diff(moment(timerStartTime), "s");
      if (timer < 0) {
        _stopTimer();
      } else {
        const minutes = Math.floor(timer / 60);
        const seconds = timer % 60;
        setFormattedTime(
          `${minutes < 10 ? "0" + minutes : minutes}:${
            seconds < 10 ? "0" + seconds : seconds
          }`
        );
      }
    }
  }, [intervalTime, timerStartTime]);

  useEffect(() => {
    dispatch(
      userActions.getCountries((countries) => {
        setCountries(countries);
        if (countries.length > 0) {
          setInputCountry(
            countries.find((c) => c.alphaCode2 === "UY")?.id || null
          );
        }
      })
    );
  }, []);

  const _setNewPhoneNumber = (e) => {
    setNewPhoneNumber(e.target.value.substring(0, 50));
  };

  const _onClose = (success = false) => {
    setNewPhoneNumber("");
    setStep(1);
    _stopTimer();
    onClose(success);
  };

  const _startTimer = () => {
    setTimerStartTime(Date.now());
  };

  const _stopTimer = () => {
    setTimerStartTime(null);
    if (intervalId) {
      clearInterval(intervalId);
    }
    setIntervalId(null);
  };

  const _requestVerificationCode = (e) => {
    e && e.preventDefault();

    setRequestingVerificationCode(true);

    const country = countries.find((c) => c.id === inputCountry);

    dispatch(
      userActions.requestChangePhoneNumber(
        country.id,
        country.dialingCode,
        newPhoneNumber,
        (_requestTraceId) => {
          setStep(2);
          setRequestTraceId(_requestTraceId);
          _startTimer();
          setRequestingVerificationCode(false);
          setVerificationCode(new Array(6).fill(""));
          verificationCodeRefs.current[0].current.focus();
        },
        () => {
          setRequestingVerificationCode(false);
        }
      )
    );
  };

  const _verifyCode = (e) => {
    e && e.preventDefault();

    const hasValidVerificationCode =
      verificationCode &&
      verificationCode.filter((code) => /[0-9]/.test(code)).length === 6;

    if (hasValidVerificationCode) {
      setVerifyingCode(true);
      dispatch(
        userActions.verifyDataChange(
          verificationCode.join(""),
          requestTraceId,
          () => {
            setStep(3);
            setRequestTraceId(null);
            setVerificationCode(null);
            _stopTimer();
            setVerifyingCode(false);
          },
          () => {
            setVerifyingCode(false);
          }
        )
      );
    }
  };

  const _handleVerificationCodeOnBlur = (index) => (e) => {
    if (e.target.value.length > 0 && !/[0-9]/.test(e.target.value)) {
      const newVerificationCode = verificationCode.slice();
      newVerificationCode[index] = "";
      setVerificationCode(newVerificationCode);
      verificationCodeRefs.current[index].current.focus();
    }
  };

  const _handleVerificationCodeOnChange = (index) => (e) => {
    if (e.target.value.length === 0) {
      const newVerificationCode = verificationCode.slice();
      newVerificationCode[index] = "";
      return setVerificationCode(newVerificationCode);
    }

    if (!/[0-9]/.test(e.target.value)) {
      return;
    }

    const newVerificationCode = verificationCode.slice();
    newVerificationCode[index] = e.target.value.substring(0, 1);
    setVerificationCode(newVerificationCode);

    if (index + 1 < 6) {
      verificationCodeRefs.current[index + 1].current.focus();
    } else if (index === 5) {
      setVerificationCode(newVerificationCode);
    }
  };

  const _handleVerificationCondeOnPaste = (e) => {
    const pastedText = e.clipboardData.getData("text").trim();

    if (!/[0-9]{6}/.test(pastedText)) {
      return toast.error(i18n.get("Login.CodeInvalidCode"));
    }

    const newVerificationCode = verificationCode.slice();
    for (let i = 0, l = 6; i < l; i += 1) {
      newVerificationCode[i] = pastedText[i];
    }

    setVerificationCode(newVerificationCode);
  };

  const _getOptionLabel = (array) => (option) => {
    let foundOption = null;
    if (array === "countries") {
      foundOption = countries.find((c) => c.id === option);
    }
    if (foundOption) {
      return `(${foundOption.dialingCode}) ${foundOption.name}`;
    }
    return "";
  };

  const _handleOnChangeCountry = (e, newValue) => {
    setInputCountry(newValue);
  };

  const renderSteps = () => {
    if (step === 1) {
      return (
        <div className={classes.modalBody}>
          <div className={classes.dialogContentTitle}>
            {i18n.get("Components.ChangePhoneNumberModal.Step1Title")}
          </div>
          <div className={classes.dialogContentInputWrapper}>
            <form onSubmit={_requestVerificationCode}>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={12} md={4}>
                  <Autocomplete
                    options={countries.map((c) => c.id)}
                    getOptionLabel={_getOptionLabel("countries")}
                    onChange={_handleOnChangeCountry}
                    classes={{ inputRoot: classes.formInputSelect }}
                    value={inputCountry}
                    renderInput={(params) => (
                      <TextField
                        className={classes.dialogContentInput}
                        classes={{ root: classes.dialogContentInput }}
                        label={i18n.get(
                          "Components.ChangePhoneNumberModal.CountryInputLabel"
                        )}
                        placeholder={i18n.get(
                          "Components.ChangePhoneNumberModal.CountryInputPlaceholder"
                        )}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        {...params}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={8}>
                  <TextField
                    InputLabelProps={{
                      shrink: true,
                    }}
                    label={i18n.get(
                      "Components.ChangePhoneNumberModal.PhoneNumberInputLabel"
                    )}
                    placeholder={i18n.get(
                      "Components.ChangePhoneNumberModal.PhoneNumberInputPlaceholder"
                    )}
                    className={classes.dialogContentInput}
                    onChange={_setNewPhoneNumber}
                    value={newPhoneNumber}
                    type="number"
                    inputProps={{ inputMode: "tel" }}
                  />
                </Grid>
              </Grid>
            </form>
          </div>
        </div>
      );
    }
    if (step === 2) {
      return (
        <div className={classes.modalBody}>
          {!timerStartTime ? (
            <Alert
              severity="error"
              icon={false}
              className={clsx(classes.alert, classes.alertError)}
            >
              <div>
                <TimerOutlinedIcon className={classes.alertIcon} />
              </div>
              <div className={classes.alertMessage}>
                <AlertTitle className={classes.alertTitle}>
                  {i18n.get(
                    "Components.ChangePhoneNumberModal.AlertErrorTitle"
                  )}
                </AlertTitle>
                {i18n.get(
                  "Components.ChangePhoneNumberModal.AlertErrorMessage"
                )}
              </div>
            </Alert>
          ) : (
            <Alert
              severity="warning"
              icon={false}
              className={clsx(classes.alert, classes.alertWarning)}
            >
              <div>
                <TimerOutlinedIcon className={classes.alertIcon} />
              </div>
              <div className={classes.alertMessage}>
                <AlertTitle className={classes.alertTitle}>
                  {i18n.get(
                    "Components.ChangePhoneNumberModal.AlertWarningTitle"
                  )}
                </AlertTitle>
                {i18n.get(
                  "Components.ChangePhoneNumberModal.AlertWarningMessage1"
                )}
                <span className={classes.alertTimer}>{formattedTime}</span>
                {i18n.get(
                  "Components.ChangePhoneNumberModal.AlertWarningMessage2"
                )}
              </div>
            </Alert>
          )}
          <form
            layout="vertical"
            onSubmit={_verifyCode}
            className={classes.verificationCodeForm}
          >
            <Grid container spacing={1}>
              {verificationCode.map((code, index) => (
                <Grid key={index} item xs={2} sm={2} md={2}>
                  <Input
                    value={code}
                    onBlur={_handleVerificationCodeOnBlur(index)}
                    onChange={_handleVerificationCodeOnChange(index)}
                    inputProps={{
                      className: classes.verificationCodeInput,
                      inputMode: "numeric",
                    }}
                    inputRef={verificationCodeRefs.current[index]}
                    onPaste={_handleVerificationCondeOnPaste}
                    disableUnderline={true}
                  />
                </Grid>
              ))}
            </Grid>
          </form>
        </div>
      );
    }
    if (step === 3) {
      return (
        <div className={classes.modalBody}>
          <Alert
            severity="success"
            icon={false}
            className={clsx(classes.alert, classes.alertSuccess)}
          >
            <div>
              <CheckCircleOutlineOutlinedIcon className={classes.alertIcon} />
            </div>
            <div className={classes.alertMessage}>
              <AlertTitle className={classes.alertTitle}>
                {i18n.get(
                  "Components.ChangePhoneNumberModal.AlertSuccessTitle"
                )}
              </AlertTitle>
              {i18n.get(
                "Components.ChangePhoneNumberModal.AlertSuccessMessage"
              )}
            </div>
          </Alert>
        </div>
      );
    }

    return null;
  };

  return (
    <Dialog
      open={open}
      onClose={() => _onClose()}
      classes={{ paper: classes.dialog }}
    >
      <DialogTitle className={classes.dialogTitleWrapper}>
        <div
          className={clsx(
            classes.dialogTitle,
            classes.dialogTitleWithCloseButton
          )}
        >
          {title || i18n.get("Components.ChangePhoneNumberModal.HeaderTitle")}
        </div>
        <div className={classes.dialogTitleClose} onClick={() => _onClose()}>
          <CancelOutlinedIcon
            className={classes.dialogTitleCloseIcon}
            color="inherit"
          />
        </div>
      </DialogTitle>
      <DialogContent className={classes.dialogContentWrapper}>
        {renderSteps()}
      </DialogContent>
      <DialogActions className={classes.dialogActionsWrapper}>
        {step === 1 ? (
          <React.Fragment>
            <Button
              disabled={!canContinueToStep2}
              loading={requestingVerificationCode}
              onClick={_requestVerificationCode}
            >
              {i18n.get("Components.ChangePhoneNumberModal.ButtonNext")}
            </Button>
          </React.Fragment>
        ) : step === 2 ? (
          <React.Fragment>
            <Button
              disabled={timerStartTime}
              loading={requestingVerificationCode}
              onClick={_requestVerificationCode}
            >
              {i18n.get(
                "Components.ChangePhoneNumberModal.ButtonRequestNewCode"
              )}
            </Button>
            <Button
              disabled={!canVerifyCode}
              loading={verifyingCode}
              onClick={_verifyCode}
            >
              {i18n.get("Components.ChangePhoneNumberModal.ButtonNext")}
            </Button>
          </React.Fragment>
        ) : step === 3 ? (
          <Button onClick={() => _onClose(true)}>
            {i18n.get("Components.ChangePhoneNumberModal.ButtonClose")}
          </Button>
        ) : null}
      </DialogActions>
    </Dialog>
  );
};

export default ChangePhoneNumberModal;
