import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { goBack, push } from 'connected-react-router';
import { toast } from 'react-toastify';

import ROUTES from '../../../../../routes';
import { BoxSeparator, ContentBox, Page, TitleBox, Button, ForbiddenSection, Confirm } from '../../../../../components';
import { business as businessActions } from '../../../../../actions';
import { Language } from '../../../../../utils';

import { Checkbox, FormControlLabel, Grid, InputAdornment, TextField } from '@material-ui/core';

import makeClasses from './styles';
import { Policies } from '../../../../../utils/Policies';
import { Autocomplete } from '@material-ui/lab';
import moment from 'moment';
import clsx from 'clsx';

const AddSubscriptionScreen = ({ match }) => {
  const dispatch = useDispatch();
  const classes = makeClasses();

  const subscriptionTraceId = match.params && match.params.traceId ? match.params.traceId : null;
  const isEditMode = subscriptionTraceId !== null;

  const [providers, setProviders] = useState([]);
  const [services, setServices] = useState([]);

  const [providerTraceId, setProviderTraceId] = useState(null);
  const [serviceTraceId, setServiceTraceId] = useState(null);
  const [inputProvider, setInputProvider] = useState(null);
  const [inputService, setInputService] = useState(null);
  const [inputDescription, setInputDescription] = useState('');
  const [inputBranch, setInputBranch] = useState(null);
  const [inputAutoApprovalAllowedToggle, setInputAutoApprovalAllowedToggle] = useState(false);
  const [inputAutoApprovalAllowed, setInputAutoApprovalAllowed] = useState('');
  const [subscriptionStatus, setSubscriptionStatus] = useState(false);
  const [subscriptionDate, setSubscriptionDate] = useState('');
  const [autoApprovalAllowed, setAutoApprovalAllowed] = useState(false);
  const [showConsentmentModal, setShowConsentmentModal] = useState(false);

  const [forbiddenSection, setForbbidenSection] = useState(false);

  const { policies, languageTexts, branches } = useSelector((state) => ({
    policies: state.user.userData.policies || [],
    languageTexts: state.language.texts || {},
    branches: state.business.branches || [],
  }));
  const i18n = Language(languageTexts);

  const canSubmitForm = inputProvider !== null && inputService !== null;

  useEffect(() => {
    window.scrollTo(0, 0);

    if (policies && policies.includes(Policies.types.SUBSCRIBER_WRITE)) {
      if (isEditMode) {
        dispatch(
          businessActions.getSubscription(subscriptionTraceId, (data) => {
            setProviderTraceId(data.providerTraceId);
            setServiceTraceId(data.productTraceId);
            setInputProvider(data.providerName);
            setInputBranch(data.branchName);
            setInputDescription(data.description || '');
            setSubscriptionStatus(data.isActive);
            setSubscriptionDate(moment(data.createdAt).format(i18n.get('DateFormat')));

            if (data.product.autoApprovalAllowed) {
              setAutoApprovalAllowed(true);
              setInputAutoApprovalAllowedToggle(true);
              setInputAutoApprovalAllowed('');
            } else if (data.autoApprovalAllowed) {
              setAutoApprovalAllowed(false);
              setInputAutoApprovalAllowedToggle(true);
              if (data.autoApprovalAllowedLimit) {
                setInputAutoApprovalAllowed(data.autoApprovalAllowedLimit);
              }
            }
          })
        );
      } else {
        dispatch(
          businessActions.getProviders((data) => {
            setProviders(data);
          })
        );
      }
    } else {
      setForbbidenSection(true);
    }
  }, [policies]);

  useEffect(() => {
    if (inputProvider) {
      dispatch(
        businessActions.getServices(providerTraceId || inputProvider, (data) => {
          setServices(data);
          if (isEditMode) {
            const service = data.find((s) => s.traceId === serviceTraceId);
            setInputService(service);
          } else if (data.length === 1) {
            setInputService(data[0]);
            setInputAutoApprovalAllowedToggle(data[0].autoApprovalAllowed);
          }
        })
      );
    }
  }, [inputProvider, providerTraceId, isEditMode]);

  useEffect(() => {
    if (inputService && !isEditMode) {
      if (inputService.autoApprovalAllowed) {
        setAutoApprovalAllowed(true);
        setInputAutoApprovalAllowedToggle(true);
        setInputAutoApprovalAllowed('');
      } else {
        setAutoApprovalAllowed(false);
        setInputAutoApprovalAllowedToggle(true);
      }
    }
  }, [inputService, isEditMode]);

  const _goBack = (e) => {
    e.preventDefault();
    dispatch(goBack());
  };

  const _submitForm = () => {
    if (!canSubmitForm) {
      return false;
    }

    if (!inputProvider) {
      return toast.error(i18n.get('ManageSubscriptionsAdd.ValidationProviderError'));
    }
    if (!inputService) {
      return toast.error(i18n.get('ManageSubscriptionsAdd.ValidationServiceError'));
    }
    if (
      !autoApprovalAllowed &&
      !inputService?.autoApprovalAllowed &&
      inputAutoApprovalAllowedToggle &&
      inputAutoApprovalAllowed <= 0
    ) {
      return toast.error(i18n.get('ManageSubscriptionsAdd.ValidationAutoApprovalError'));
    }

    if (isEditMode) {
      const params = { description: inputDescription };

      if (!autoApprovalAllowed) {
        params.autoApprovalAllowed = inputAutoApprovalAllowedToggle;
        params.autoApprovalAllowedLimit = inputAutoApprovalAllowedToggle ? inputAutoApprovalAllowed : 0;
      }

      dispatch(
        businessActions.updateSubscription(subscriptionTraceId, params, (result) => {
          if (result) {
            toast.success(i18n.get('ManageSubscriptionsAdd.SuccessUpdateMessage'));
            setTimeout(() => dispatch(push(ROUTES.MANAGE_PROVIDERS_PAYMENTS.path)), 1000);
          }
        })
      );
    } else {
      setShowConsentmentModal(true);
    }
  };

  const handleOnAcceptConsentmentModal = () => {
    setShowConsentmentModal(false);

    const params = {
      providerTraceId: inputProvider,
      productTraceId: inputService?.traceId,
      branchTraceId: inputBranch,
      description: inputDescription,
    };

    if (!autoApprovalAllowed) {
      params.autoApprovalAllowed = inputAutoApprovalAllowedToggle;
      params.autoApprovalAllowedLimit =
        inputAutoApprovalAllowedToggle && !inputService?.autoApprovalAllowed ? inputAutoApprovalAllowed : 0;
    }

    dispatch(
      businessActions.addSubscription(params, (result) => {
        if (result) {
          toast.success(i18n.get('ManageSubscriptionsAdd.SuccessAddMessage'));
          setTimeout(() => dispatch(push(ROUTES.MANAGE_PROVIDERS_PAYMENTS.path)), 1000);
        }
      })
    );
  };

  const handleOnCloseConsentmentModal = () => {
    setShowConsentmentModal(false);
  };

  const _getOptionLabel = (array) => (option) => {
    if (isEditMode) {
      return option;
    }

    let foundOption = null;
    if (array === 'providers') {
      foundOption = providers.find((c) => c.traceId === option);
    } else if (array === 'services') {
      foundOption = services.find((c) => c.traceId === option);
    } else if (array === 'branches') {
      foundOption = branches.find((c) => c.traceId === option);
    }
    if (foundOption) {
      return foundOption.name || foundOption.internalName || '';
    }
    return '';
  };

  const _handleOnChangeProvider = (e, newValue) => {
    setInputService(null);
    setInputProvider(newValue);
  };

  const _handleOnChangeService = (e, newValue) => {
    setInputService(services.find((s) => s.traceId === newValue));
  };

  const _handleOnChangeBranch = (e, newValue) => {
    setInputBranch(newValue);
  };

  const renderManageSubscriptionsAddForm = () => {
    return (
      <Grid container justify="center" alignItems="center" className={classes.formWrapper}>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <form layout="vertical" onSubmit={_submitForm} className={classes.form}>
            <Grid container spacing={4}>
              <Grid item xs={12} sm={12} md={6}>
                {!isEditMode ? (
                  <Autocomplete
                    options={providers.map((c) => c.traceId)}
                    getOptionLabel={_getOptionLabel('providers')}
                    onChange={_handleOnChangeProvider}
                    classes={{ inputRoot: classes.formInputSelect }}
                    value={inputProvider}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        className={classes.formInput}
                        classes={{ root: classes.formInput }}
                        label={i18n.get('ManageSubscriptionsAdd.ProviderInputLabel')}
                        placeholder={i18n.get('ManageSubscriptionsAdd.ProviderInputPlaceholder')}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    )}
                  />
                ) : (
                  <div
                    className={clsx(
                      classes.subscriptionDetailsItem,
                      isEditMode ? classes.subscriptionDetailsItemDisabled : null
                    )}
                  >
                    <div>{i18n.get('ManageSubscriptionsAdd.ProviderInputLabel')}</div>
                    {inputProvider}
                  </div>
                )}
              </Grid>
              <Grid item xs={12} sm={12} md={6}>
                {!isEditMode ? (
                  <Autocomplete
                    key={inputService?.traceId}
                    options={services.map((c) => c.traceId)}
                    getOptionLabel={_getOptionLabel('services')}
                    onChange={_handleOnChangeService}
                    classes={{ inputRoot: classes.formInputSelect }}
                    value={inputService?.traceId}
                    disabled={!inputProvider}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        className={classes.formInput}
                        classes={{ root: classes.formInput }}
                        label={i18n.get('ManageSubscriptionsAdd.ServiceInputLabel')}
                        placeholder={i18n.get('ManageSubscriptionsAdd.ServiceInputPlaceholder')}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    )}
                  />
                ) : (
                  <div
                    className={clsx(
                      classes.subscriptionDetailsItem,
                      isEditMode ? classes.subscriptionDetailsItemDisabled : null
                    )}
                  >
                    <div>{i18n.get('ManageSubscriptionsAdd.ServiceInputLabel')}</div>
                    {inputService?.name}
                  </div>
                )}
              </Grid>
            </Grid>
            <div className={classes.formInputSeparator} />
            <div className={classes.formInputSeparator} />
            <Grid container spacing={4}>
              <Grid item xs={12} sm={12} md={6}>
                <TextField
                  InputLabelProps={{
                    shrink: true,
                  }}
                  className={classes.formInput}
                  label={i18n.get('ManageSubscriptionsAdd.DescriptionInputLabel')}
                  placeholder={i18n.get('ManageSubscriptionsAdd.DescriptionInputPlaceholder')}
                  onChange={(e) => setInputDescription(e.target.value)}
                  value={inputDescription}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6}>
                {!isEditMode ? (
                  <Autocomplete
                    options={branches.map((c) => c.traceId)}
                    getOptionLabel={_getOptionLabel('branches')}
                    onChange={_handleOnChangeBranch}
                    classes={{ inputRoot: classes.formInputSelect }}
                    value={inputBranch}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        className={classes.formInput}
                        classes={{ root: classes.formInput }}
                        label={i18n.get('ManageSubscriptionsAdd.BranchInputLabel')}
                        placeholder={i18n.get('ManageSubscriptionsAdd.BranchPlaceholder')}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    )}
                  />
                ) : (
                  <div
                    className={clsx(
                      classes.subscriptionDetailsItem,
                      isEditMode ? classes.subscriptionDetailsItemDisabled : null
                    )}
                  >
                    <div>{i18n.get('ManageSubscriptionsAdd.BranchInputLabel')}</div>
                    {inputBranch}
                  </div>
                )}
              </Grid>
            </Grid>
            <div className={classes.autoApprovalWrapper}>
              <Grid container spacing={4}>
                <Grid item xs={12} sm={12} md={3}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={inputAutoApprovalAllowedToggle}
                        onChange={() => setInputAutoApprovalAllowedToggle(!inputAutoApprovalAllowedToggle)}
                        disabled={autoApprovalAllowed}
                      />
                    }
                    label={i18n.get('ManageSubscriptionsAdd.EnableAutoApproval')}
                    classes={{ label: classes.autoApprovalLabel }}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={3}>
                  <div
                    className={
                      autoApprovalAllowed || !inputAutoApprovalAllowedToggle
                        ? classes.autoApprovalInputDisabledWrapper
                        : null
                    }
                  >
                    <TextField
                      InputProps={{
                        startAdornment: <InputAdornment position="start">UYU</InputAdornment>,
                      }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      disabled={autoApprovalAllowed || !inputAutoApprovalAllowedToggle}
                      className={classes.formInput}
                      label={i18n.get('ManageSubscriptionsAdd.AutoApprovalLabel')}
                      placeholder={i18n.get('ManageSubscriptionsAdd.AutoApprovalPlaceholder')}
                      onChange={(e) => setInputAutoApprovalAllowed(e.target.value)}
                      value={inputAutoApprovalAllowed}
                      type="number"
                    />
                  </div>
                </Grid>
                {isEditMode ? (
                  <React.Fragment>
                    <Grid item xs={12} sm={12} md={3}>
                      {!isEditMode ? (
                        <TextField
                          InputLabelProps={{
                            shrink: true,
                          }}
                          className={classes.formInput}
                          label={i18n.get('ManageSubscriptionsAdd.StatusLabel')}
                          value={
                            subscriptionStatus
                              ? i18n.get('ManageSubscriptionsAdd.StatusActive')
                              : i18n.get('ManageSubscriptionsAdd.StatusInactive')
                          }
                        />
                      ) : (
                        <div
                          className={clsx(
                            classes.subscriptionDetailsItem,
                            isEditMode ? classes.subscriptionDetailsItemDisabled : null
                          )}
                        >
                          <div>{i18n.get('ManageSubscriptionsAdd.StatusLabel')}</div>

                          <span
                            className={clsx(
                              classes.subscriptionDetailsStatus,
                              subscriptionStatus
                                ? classes.subscriptionDetailsStatusActive
                                : classes.subscriptionDetailsStatusInactive
                            )}
                          >
                            {subscriptionStatus
                              ? i18n.get('ManageSubscriptionsAdd.StatusActive')
                              : i18n.get('ManageSubscriptionsAdd.StatusInactive')}
                          </span>
                        </div>
                      )}
                    </Grid>
                    <Grid item xs={12} sm={12} md={3}>
                      {!isEditMode ? (
                        <TextField
                          InputLabelProps={{
                            shrink: true,
                          }}
                          className={classes.formInput}
                          label={i18n.get('ManageSubscriptionsAdd.DateLabel')}
                          value={subscriptionDate}
                        />
                      ) : (
                        <div
                          className={clsx(
                            classes.subscriptionDetailsItem,
                            isEditMode ? classes.subscriptionDetailsItemDisabled : null
                          )}
                        >
                          <div>{i18n.get('ManageSubscriptionsAdd.DateLabel')}</div>
                          {subscriptionDate}
                        </div>
                      )}
                    </Grid>
                  </React.Fragment>
                ) : null}
              </Grid>
            </div>
          </form>
        </Grid>
      </Grid>
    );
  };

  if (forbiddenSection) {
    return (
      <Page
        withHeader
        withSidebar
        withHeaderTitle={i18n.get('ManageSubscriptionsAdd.Title')}
        withActivePage={ROUTES.MANAGE_PROVIDERS_PAYMENTS.id}
        withBanner={false}
      >
        <ForbiddenSection />
      </Page>
    );
  }

  return (
    <Page
      withHeader
      withSidebar
      withHeaderTitle={i18n.get('ManageSubscriptionsAdd.Title')}
      withActivePage={ROUTES.MANAGE_PROVIDERS_PAYMENTS.id}
    >
      <TitleBox
        title={
          isEditMode ? i18n.get('ManageSubscriptionsAdd.BoxTitleEdit') : i18n.get('ManageSubscriptionsAdd.BoxTitleNew')
        }
        back
        backTitle={i18n.get('ManageSubscriptionsAdd.BoxBackButton')}
        onBackClick={_goBack}
        backButtonVariant="outlined"
      />
      <BoxSeparator />
      <ContentBox title={i18n.get('ManageSubscriptionsAdd.BoxContentTitle')} titleBold>
        {renderManageSubscriptionsAddForm()}
        <div>
          <div className={classes.contentActionsWrapper}>
            <Button variant="outlined" onClick={_goBack}>
              {i18n.get('ManageSubscriptionsAdd.CancelButton')}
            </Button>
            <div className={classes.contentActionSeparator} />
            <Button disabled={!canSubmitForm} onClick={_submitForm}>
              {i18n.get('ManageSubscriptionsAdd.SaveButton')}
            </Button>
          </div>
        </div>
      </ContentBox>
      <Confirm
        open={showConsentmentModal}
        title={i18n.get('ManageSubscriptionsAdd.ConsentmentModal.title')}
        htmlText={i18n.get('ManageSubscriptionsAdd.ConsentmentModal.text')}
        confirmText={i18n.get('ManageSubscriptionsAdd.ConsentmentModal.confirmButtonText')}
        cancelText={i18n.get('ManageSubscriptionsAdd.ConsentmentModal.cancelButtonText')}
        onConfirm={handleOnAcceptConsentmentModal}
        onCancel={handleOnCloseConsentmentModal}
        onClose={handleOnCloseConsentmentModal}
      />
    </Page>
  );
};

AddSubscriptionScreen.id = 'com.Handy.ManageSubscriptionsAdd';

export default AddSubscriptionScreen;
