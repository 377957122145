import React, { useState, useRef, useEffect } from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  useMediaQuery,
  useTheme,
  withStyles,
} from '@material-ui/core';
import makeClasses from './styles';
import { useSelector } from 'react-redux';
import { Language, Colors } from '../../utils';
import Button from '../Button';
import { Add as AddIcon, DeleteOutlineOutlined as Delete, CloudDownloadOutlined as SaveIcon } from '@material-ui/icons';
import _ from 'lodash';
import { BoxSeparator, ContentBox, TitleBox } from '../../components';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import Table from '@material-ui/core/Table';

const StyledTableRow = withStyles(() => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: Colors.rgb.grey,
    },
    '&:nth-of-type(even)': {
      backgroundColor: Colors.rgb.white,
    },
  },
}))(TableRow);

const FileUploader = ({
  onFileChange,
  title = '',
  fileCountLimit = 5,
  fileSizeLimit = 5,
  fileFilter = 'application/pdf,image/jpeg,image/png,image/heic',
  banner = '',
  templatePath = '',
  templateName = '',
}) => {
  const { languageTexts } = useSelector(({ language }) => ({
    languageTexts: language.texts || {},
  }));
  const i18n = Language(languageTexts);

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));

  const [files, setFiles] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [filesValid, setFilesValid] = useState(false);
  const [fileNameCount, setFileNameCount] = useState('');
  const fileInputRef = useRef(null);

  const handleFileChange = (event) => {
    const newFiles = Array.from(event.target.files);
    setFiles((prevFiles) => {
      const updatedFiles = [...prevFiles, ...newFiles];
      onFileChange(updatedFiles);
      return updatedFiles;
    });
  };

  const deleteFile = (index) => {
    setFiles((prevFiles) => {
      const updatedFiles = prevFiles.filter((_, i) => i !== index);
      onFileChange(updatedFiles);
      return updatedFiles;
    });
  };

  const clearFiles = () => {
    setFiles([]);
  };

  const classes = makeClasses();

  if (banner == '') banner = i18n.get('FileUploader.Banner');

  useEffect(() => {
    const length = files.length;
    const plural = length > 1 ? 's' : '';
    const fnCount = length > 0 ? `${length} archivo${plural} adjunto${plural}` : title;
    setFileNameCount(fnCount);
    setFilesValid(validateFiles());
  }, [files, title]);

  const getFileSize = (file) => {
    return (file.size / 1024 / 1024).toFixed(2) + ' MB';
  };

  const getTotalFileSizes = () => (files.reduce((total, file) => total + file.size, 0) / 1024 / 1024).toFixed(2);

  const validateFiles = () => {
    return files.length === 0 || getTotalFileSizes() > fileSizeLimit;
  };

  const downloadTemplate = () => {
    const link = document.createElement('a');
    link.href = templatePath;
    link.download = templateName || templatePath;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <div>
      <TextField
        placeholder={title}
        variant="outlined"
        classes={{ root: classes.formInput }}
        value={fileNameCount}
        disabled
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton aria-label="upload" className={classes.formInputFileButton}>
                <Button onClick={() => setShowModal(true)} disabled={files.length >= fileCountLimit}>Adjuntar</Button>
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
      <Dialog open={showModal} classes={{ paper: classes.dialog }}>
        <DialogTitle className={classes.dialogTitleWrapper}>
          <div className={classes.dialogTitle}>{title}</div>
        </DialogTitle>
        <DialogContent className={classes.dialogContentWrapper}>
          <TitleBox
            title={banner}
            buttonsRight={_.compact([
              templatePath !== ''
                ? {
                    text: isSmallScreen ? null : i18n.get('FileUploader.DownloadTemplate'),
                    icon: () => <SaveIcon fontSize="small" />,
                    onClick: () => downloadTemplate(),
                    variant: 'outlined',
                  }
                : null,
              {
                text: isSmallScreen ? null : i18n.get('FileUploader.AddButton'),
                icon: () => <AddIcon fontSize="small" />,
                onClick: () => fileInputRef.current.click(),
                disabled: files.length >= fileCountLimit,
              },
            ])}
            customClass={classes.contentBox}
          />
          <BoxSeparator />
          <ContentBox
            className={classes.noDataMessage}
            title={`Listado de archivos (tamaño máximo ${fileSizeLimit} MB)`}
            titleBold
            headerVertical={isSmallScreen}
            customClass={classes.contentBox}
          >
            <input
              type="file"
              accept={fileFilter}
              onChange={handleFileChange}
              style={{ display: 'none' }}
              ref={fileInputRef}
              multiple
            />
            {files.length == 0 ? (
              <div className={classes.noDataMessage}>
                {isSmallScreen ? i18n.get('FileUploader.EmptyLabelSmall') : i18n.get('FileUploader.EmptyLabel')}
              </div>
            ) : (
              <TableContainer>
                <Table className={classes.table}>
                  <TableHead>
                    <TableRow>
                      <TableCell className={classes.tableHeaderCell}>{'Nombre'}</TableCell>
                      <TableCell className={classes.tableHeaderCell} align="right">
                        {'Tamaño'}
                      </TableCell>
                      <TableCell align="right" className={classes.tableHeaderCell}>
                        {'Eliminar'}
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {files.map((file, index) => (
                      <StyledTableRow key={index}>
                        <TableCell className={classes.tableBodyCell}>{file.name}</TableCell>
                        <TableCell className={classes.tableBodyCell} align="right">
                          {getFileSize(file)}{' '}
                        </TableCell>
                        <TableCell className={classes.tableBodyCell} align="right">
                          <div className={classes.listItemActions}>
                            <IconButton onClick={() => deleteFile(index)}>
                              <Delete alt="Eliminar" />
                            </IconButton>
                          </div>
                        </TableCell>
                      </StyledTableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            )}
          </ContentBox>
        </DialogContent>
        <DialogActions className={classes.dialogActionsWrapper}>
          <Button variant="outlined" onClick={() => setShowModal(false)}>
            {i18n.get('FileUploader.CancelButton')}
          </Button>
          <Button disabled={filesValid} onClick={() => setShowModal(false)}>
            {i18n.get('FileUploader.AcceptButton')}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default FileUploader;
