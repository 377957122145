import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { push } from 'connected-react-router';
import ROUTES from '../../routes';
import {
  Page,
  OperationsTable,
  LoanAvailable,
  TitleBox,
  ContentBox,
  BoxSeparator,
  EmptyMessage,
  ForbiddenSection,
} from '../../components';
import { business as businessActions } from '../../actions';
import { Utils, Language, Colors } from '../../utils';
import Grid from '@material-ui/core/Grid';
import makeClasses from './styles';
import moment from 'moment';
import { getDateRangeForFilter } from '../../components/FiltersDateSelector';
import { Policies } from '../../utils/Policies';
import { toISOString } from '../../utils/Utils';

const LAST_TRANSACTION_TYPES = {
  PURCHASE: 'Venta',
  REFUND: 'Devolución',
  REVERSAL: 'Anulación',
  SYSTEM_REVERSAL: 'Reversa',
  CHARGE_BACK: 'Contracargo',
  INTERNAL_VOID: 'Reverso Administrativo',
};

const BoxItem = ({ title, item, boxesLength, index, onPress, i18n, background }) => {
  const classes = makeClasses();
  const boxSize = 12 / boxesLength;

  const handleOnClick = boxIndex => () => {
    if (boxIndex === 0) {
      onPress(ROUTES.ACCOUNT);
    } else if (boxIndex === 1) {
      onPress(ROUTES.ACTIVITY);
    }
  };

  return (
    <Grid key={index} item xs={12} sm={12} md={boxSize}>
      <ContentBox
        title={title}
        titleBold
        onClick={handleOnClick(index)}
        background={background}
        color={Colors.rgb.white}
        fullHeightBox
      >
        {item.balances.map((balance, key) => (
          <div key={key} className={classes.boxBalance}>
            <div className={classes.boxBalanceTitle}>{balance.detail}</div>
            <div className={classes.boxBalanceContent}>
              <div>
                <div className={classes.boxBalanceCurrency}>{i18n.get('Currency.UYU')}</div>
                <div className={classes.boxBalanceAmount}>{balance.uyu ? Utils.formatCurrency(balance.uyu) : 0}</div>
              </div>
              <div>
                <div className={classes.boxBalanceCurrency}>{i18n.get('Currency.USD')}</div>
                <div className={classes.boxBalanceAmount}>{balance.usd ? Utils.formatCurrency(balance.usd) : 0}</div>
              </div>
            </div>
          </div>
        ))}
      </ContentBox>
    </Grid>
  );
};

const HomeScreen = () => {
  const dispatch = useDispatch();
  const [ forbiddenSection, setForbbidenSection ] = useState(true);
  const [ boxes, setBoxes ] = useState([]);
  const [ lastTransactions, setLastTransactions ] = useState({});
  const [ paymentHistory, setPaymentHistory ] = useState({});
  const { policies, languageTexts, loanType, hasLoanAvailable } = useSelector(state => ({
    policies: state.user.userData.policies || [],
    languageTexts: state.language.texts || {},
    loanType: state.business.loanAvailability ? state.business.loanAvailability.bannerType : null,
    hasLoanAvailable: state.business.loanAvailability
      ? [ 'AVAILABLE_OFFER', 'RENEWAL' ].includes(state.business.loanAvailability.offerState)
      : false,
  }));

  const hasRecentActivity = lastTransactions && lastTransactions.elements && lastTransactions.elements.length > 0;
  const hasPaymentHistory = paymentHistory && paymentHistory.grid && paymentHistory.grid.rowsColumnAndSale.length > 0;

  const i18n = Language(languageTexts);

  useEffect(() => window.scrollTo(0, 0), []);

  useEffect(
    () => {
      if (policies && policies.includes(Policies.types.HOME)) {
        setForbbidenSection(false);
      }
    },
    [ policies ]
  );

  useEffect(
    () => {
      if (!forbiddenSection) {
        const dateFilter = getDateRangeForFilter(3);
        const startDate = toISOString(dateFilter.startDate);
        const endDate = toISOString(dateFilter.endDate);
        const todayDate = toISOString(moment().startOf('day'));

        dispatch(
          businessActions.getTransactionsForHome(startDate, endDate, transactions => {
            setLastTransactions(transactions);
          })
        );
        dispatch(
          businessActions.getHistoryForHome(startDate, endDate, history => {
            setPaymentHistory(history);
          })
        );
        dispatch(
          businessActions.getBalancesForHome(todayDate, data => {
            const _boxes = [];
            const account = { balances: [] };
            const summary = { balances: [] };
            if (data.balance) {
              const uyu = data.balance.find(b => b.currency === 'UYU');
              const usd = data.balance.find(b => b.currency === 'USD');
              account.balances.push({
                detail: i18n.get('Home.Boxes.BalanceTitle'),
                uyu: uyu ? uyu.balance : null,
                usd: usd ? usd.balance : null,
              });
            }
            if (data.pendingBalance) {
              const uyu = data.pendingBalance.find(b => b.currency === 'UYU');
              const usd = data.pendingBalance.find(b => b.currency === 'USD');
              account.balances.push({
                detail: i18n.get('Home.Boxes.PendingBalanceTitle'),
                uyu: uyu ? uyu.balance : null,
                usd: usd ? usd.balance : null,
              });
            }
            if (data.purchaseTodayBalance) {
              const uyu = data.purchaseTodayBalance.find(b => b.currency === 'UYU');
              const usd = data.purchaseTodayBalance.find(b => b.currency === 'USD');
              summary.balances.push({
                detail: i18n.get('Home.Boxes.PurchaseTodayBalanceTitle'),
                uyu: uyu ? uyu.balance : null,
                usd: usd ? usd.balance : null,
              });
            }
            if (data.tcpPurchaseTodayBalance) {
              const uyu = data.tcpPurchaseTodayBalance.find(b => b.currency === 'UYU');
              const usd = data.tcpPurchaseTodayBalance.find(b => b.currency === 'USD');
              summary.balances.push({
                detail: i18n.get('Home.Boxes.PctPurchaseTodayBalanceTitle'),
                uyu: uyu ? uyu.balance : null,
                usd: usd ? usd.balance : null,
              });
            }
            if (data.tipsTodayBalance) {
              const uyu = data.tipsTodayBalance.find(b => b.currency === 'UYU');
              const usd = data.tipsTodayBalance.find(b => b.currency === 'USD');
              if (uyu || usd) {
                summary.balances.push({
                  detail: i18n.get('Home.Boxes.TipsTodayBalanceTitle'),
                  uyu: uyu ? uyu.balance : null,
                  usd: usd ? usd.balance : null,
                });
              }
            }
            _boxes.push(account);
            _boxes.push(summary);
            setBoxes(_boxes);
          })
        );
      }
    },
    [ forbiddenSection ]
  );

  const _goToOperationDetails = operationId => e => {
    e.preventDefault();

    dispatch(push(`/operacion/${operationId}`));
  };

  const _goToHistory = () => () => {
    dispatch(push(`/historial-de-retiros`));
  };

  const _handleOnBoxClick = url => {
    dispatch(push(url.path));
  };

  const renderBoxes = () => {
    if (boxes) {
      const boxesLength = boxes.length;
      return boxes.map((item, index) => (
        <BoxItem
          key={index}
          boxesLength={boxesLength}
          title={i18n.get(`Home.Box${index}Title`)}
          item={item}
          index={index}
          onPress={_handleOnBoxClick}
          i18n={i18n}
          background={Colors.rgb.black_light}
        />
      ));
    }
    return null;
  };

  const renderRecentActivity = () => {
    if (lastTransactions.count === 0) {
      return null;
    }

    const headers = [
      {
        columnNumber: 1,
        name: i18n.get('Home.LastTransactionsGrid.Column1'),
        type: 'date',
      },
      {
        columnNumber: 2,
        name: i18n.get('Home.LastTransactionsGrid.Column2'),
        type: 'text',
      },
      {
        columnNumber: 3,
        name: i18n.get('Home.LastTransactionsGrid.Column3'),
        type: 'text',
      },
      {
        columnNumber: 4,
        name: i18n.get('Home.LastTransactionsGrid.Column4'),
        type: 'text',
      },
      {
        columnNumber: 5,
        name: i18n.get('Home.LastTransactionsGrid.Column5'),
        type: 'text',
      },
      {
        columnNumber: 6,
        name: i18n.get('Home.LastTransactionsGrid.Column6'),
        type: 'text',
      },
      {
        columnNumber: 7,
        name: i18n.get('Home.LastTransactionsGrid.Column7'),
        type: 'text',
      },
    ];
    const body = (lastTransactions.elements || []).map(item => ({
      rowId: item.id,
      columns: [
        {
          columnNumber: 1,
          data: item.transmissionDateTime,
        },
        {
          columnNumber: 2,
          data: item.ticketNumber,
        },
        {
          columnNumber: 3,
          data: item.maskedPan,
        },
        {
          columnNumber: 4,
          data: item.brand,
        },
        {
          columnNumber: 5,
          data: LAST_TRANSACTION_TYPES[item.type],
        },
        {
          columnNumber: 6,
          data: item.currency,
        },
        {
          columnNumber: 7,
          data: Utils.formatCurrency(item.amount),
        },
      ],
    }));

    return <OperationsTable headers={headers} body={body} onClick={_goToOperationDetails} compact />;
  };

  const renderRecentHistory = () => {
    if (!paymentHistory.grid) {
      return null;
    }

    const headers = paymentHistory.grid.columnsNameAndType;
    const body = paymentHistory.grid.rowsColumnAndSale;

    return <OperationsTable headers={headers} body={body} onClick={_goToHistory} compact />;
  };

  if (forbiddenSection) {
    return (
      <Page withHeader withSidebar withHeaderTitle={i18n.get('Home.Title')} withActivePage={ROUTES.HOME.id}>
        <ForbiddenSection />
      </Page>
    );
  }

  return (
    <Page withHeader withSidebar withHeaderTitle={i18n.get('Home.Title')} withActivePage={ROUTES.HOME.id}>
      {hasLoanAvailable ? <LoanAvailable loanType={loanType} i18n={i18n} /> : null}
      <TitleBox title={i18n.get('Home.BoxTitle')} />
      <BoxSeparator size="small" />
      <Grid container spacing={2}>
        {renderBoxes()}
      </Grid>
      <BoxSeparator size="small" />
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={6}>
          <ContentBox title={i18n.get('Home.BoxLastTransactionsGridTitle')} titleBold sm>
            {hasRecentActivity ? (
              renderRecentActivity()
            ) : (
              <EmptyMessage textOnly>{i18n.get('Home.GridEmptyMessage')}</EmptyMessage>
            )}
          </ContentBox>
        </Grid>
        <Grid item xs={12} sm={12} md={6}>
          <ContentBox title={i18n.get('Home.BoxHistoryGridTitle')} titleBold sm>
            {hasPaymentHistory ? (
              renderRecentHistory()
            ) : (
              <EmptyMessage textOnly>{i18n.get('Home.GridEmptyMessage')}</EmptyMessage>
            )}
          </ContentBox>
        </Grid>
      </Grid>
    </Page>
  );
};

HomeScreen.id = 'com.Handy.Home';

export default HomeScreen;
