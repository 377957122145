import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { goBack } from 'connected-react-router';

import { Box, BoxSeparator, ForbiddenSection, Page, TitleBox } from '../../components';
import { business as businessActions } from '../../actions';
import { Language, Utils } from '../../utils';

import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import makeClasses from './styles';
import { Grid, Hidden } from '@material-ui/core';
import clsx from 'clsx';
import { Policies } from '../../utils/Policies';
import moment from 'moment';

const OperationDetailsScreen = props => {
  const dispatch = useDispatch();
  const classes = makeClasses();

  const [ operationDetails, setOperationDetails ] = useState({});
  const [ forbiddenSection, setForbbidenSection ] = useState(false);
  const hasOperationDetails = Object.keys(operationDetails).length > 0;
  const operationId = props.match.params.operationId || null;
  const branchId = props.match.params.branchId || null;
  const { policies, languageTexts } = useSelector(state => ({
    policies: state.user.userData.policies || [],
    languageTexts: state.language.texts || {},
  }));
  const i18n = Language(languageTexts);

  useEffect(
    () => {
      window.scrollTo(0, 0);

      if (policies && policies.includes(Policies.types.OPERATION_DEATAILS)) {
        dispatch(
          businessActions.getOperationDetails(operationId, details => {
            setOperationDetails(details);
          })
        );
      } else {
        setForbbidenSection(true);
      }
    },
    [ policies, operationId, branchId ]
  );

  const _goBack = e => {
    e.preventDefault();
    dispatch(goBack());
  };

  const renderCardAuthorizationLineLabel = data => {
    if (
      /BANDES|BBVA|ITAU|BROU|SCOTIABANK|SCOTIA_AMEX|AMEX|PAGO_DESPUES|PASSCARD|SISTARBANC|REDPAGOS|EDENRED|CLUB_DEL_ESTE|ANDA|CABAL|MASTER|OCAVISA_NET|VISA|OCA|CREDITOS_DIRECTOS/.test(
        data.toUpperCase()
      )
    ) {
      let brandImage = null;
      switch (data.toUpperCase()) {
        case 'VISA':
        case 'VISA_NET':
          brandImage = (
            <img
              src={require('../../static/images/brands/brand_visa.svg').default}
              className={classes.brandImage}
              alt="VISA"
            />
          );
          break;
        case 'OCA':
          brandImage = (
            <img
              src={require('../../static/images/brands/brand_oca.svg').default}
              className={classes.brandImage}
              alt="OCA"
            />
          );
          break;
        case 'MASTER':
          brandImage = (
            <img
              src={require('../../static/images/brands/brand_master.svg').default}
              className={classes.brandImage}
              alt="MASTER"
            />
          );
          break;
        case 'CABAL':
          brandImage = (
            <img
              src={require('../../static/images/brands/brand_cabal.svg').default}
              className={classes.brandImage}
              alt="CABAL"
            />
          );
          break;
        case 'ANDA':
          brandImage = (
            <img
              src={require('../../static/images/brands/brand_anda.svg').default}
              className={classes.brandImage}
              alt="ANDA"
            />
          );
          break;
        case 'CLUB_DEL_ESTE':
          brandImage = (
            <img
              src={require('../../static/images/brands/brand_clubdeleste.svg').default}
              className={classes.brandImage}
              alt="CLUB_DEL_ESTE"
            />
          );
          break;
        case 'EDENRED':
          brandImage = (
            <img
              src={require('../../static/images/brands/brand_edenred.svg').default}
              className={classes.brandImage}
              alt="EDENRED"
            />
          );
          break;
        case 'REDPAGOS':
          brandImage = (
            <img
              src={require('../../static/images/brands/brand_redpagos.svg').default}
              className={classes.brandImage}
              alt="REDPAGOS"
            />
          );
          break;
        case 'SISTARBANC':
          brandImage = (
            <img
              src={require('../../static/images/brands/brand_sistarbanc.svg').default}
              className={classes.brandImage}
              alt="SISTARBANC"
            />
          );
          break;
        case 'PASSCARD':
          brandImage = (
            <img
              src={require('../../static/images/brands/brand_passcard.svg').default}
              className={classes.brandImage}
              alt="PASSCARD"
            />
          );
          break;
        case 'PAGO_DESPUES':
          brandImage = (
            <img
              src={require('../../static/images/brands/brand_pago_despues.svg').default}
              className={classes.brandImage}
              alt="PAGO_DESPUES"
            />
          );
          break;
        case 'AMEX':
        case 'SCOTIA_AMEX':
          brandImage = (
            <img
              src={require('../../static/images/brands/brand_scotia_amex.svg').default}
              className={classes.brandImage}
              alt="SCOTIA_AMEX"
            />
          );
          break;
        case 'SCOTIABANK':
          brandImage = (
            <img
              src={require('../../static/images/brands/brand_scotiabank.svg').default}
              className={classes.brandImage}
              alt="SCOTIABANK"
            />
          );
          break;
        case 'BROU':
          brandImage = (
            <img
              src={require('../../static/images/brands/brand_brou.svg').default}
              className={classes.brandImage}
              alt="BROU"
            />
          );
          break;
        case 'ITAU':
          brandImage = (
            <img
              src={require('../../static/images/brands/brand_itau.svg').default}
              className={classes.brandImage}
              alt="ITAU"
            />
          );
          break;
        case 'BBVA':
          brandImage = (
            <img
              src={require('../../static/images/brands/brand_bbva.svg').default}
              className={classes.brandImage}
              alt="BBVA"
            />
          );
          break;
        case 'BANDES':
          brandImage = (
            <img
              src={require('../../static/images/brands/brand_bandes.svg').default}
              className={classes.brandImage}
              alt="BANDES"
            />
          );
          break;
        case 'CREDITOS_DIRECTOS':
          brandImage = (
            <img
              src={require('../../static/images/brands/brand_creditos_directos.png').default}
              className={classes.brandImage}
              alt="CREDITOS_DIRECTOS"
            />
          );
          break;
        default:
          brandImage = null;
          break;
      }

      return (
        <div className={classes.boxBrandImage} title={data}>
          {brandImage}
        </div>
      );
    }

    return <ListItemText primary={data} />;
  };

  const renderCardAuthorizationLineText = (data, isDate, showTime) => {
    if (isDate) {
      if (showTime) {
        const dateData = `${moment(data).format(i18n.get('DateFormat', false))} ${moment(data).format(
          i18n.get('TimeFormat', false)
        )}`;
        return dateData;
      }
      return data ? moment(data).format(i18n.get('DateFormat', false)) : '-';
    }
    return data;
  };

  const renderCardAuthorizarionLines = lines =>
    lines ? (
      <List className={classes.boxContent}>
        {lines.map((line, index) => (
          <React.Fragment key={index}>
            {index !== 0 ? <Divider /> : null}
            <ListItem className={classes.boxContentItem}>
              {renderCardAuthorizationLineLabel(line.data)}
              <ListItemSecondaryAction className={classes.summaryAmount}>
                {renderCardAuthorizationLineText(
                  line.text,
                  [ 3, 4 ].includes(index),
                  index === 3 /* Transaction Date */
                )}
              </ListItemSecondaryAction>
            </ListItem>
          </React.Fragment>
        ))}
      </List>
    ) : null;

  const renderInvoiceDetails = (details = []) => (
    <List className={classes.boxContent}>{details.map(renderInvoiceDetailLines)}</List>
  );

  const renderInvoiceDetailLines = (details, detailsIndex) => {
    const invoiceDetailsLines = isAccordion =>
      details.lines.map((line, index) => (
        <ListItem key={index} className={classes.summaryItem}>
          {isAccordion ? (
            <React.Fragment>
              <Hidden xsDown>
                <ListItemText primary={line.text} className={classes.summaryTitle} />
                <ListItemSecondaryAction className={classes.summaryAmount}>
                  {line.currency} {Utils.formatCurrency(line.total)}
                </ListItemSecondaryAction>
              </Hidden>
              <Hidden smUp>
                <div
                  className={clsx(
                    classes.summaryItemWrapper,
                    index === details.lines.length - 1 ? classes.summaryItemNoBorder : null
                  )}
                >
                  <div className={classes.summaryTitle}>{line.text}</div>
                  <div className={classes.summaryAmount}>
                    {line.currency} {Utils.formatCurrency(line.total)}
                  </div>
                </div>
              </Hidden>
            </React.Fragment>
          ) : (
            <React.Fragment>
              <ListItemText primary={line.text} className={classes.summaryTitle} />
              <ListItemSecondaryAction className={classes.summaryAmount}>
                {line.currency} {Utils.formatCurrency(line.total)}
              </ListItemSecondaryAction>
            </React.Fragment>
          )}
        </ListItem>
      ));

    if (details.title) {
      return (
        <React.Fragment key={detailsIndex}>
          {detailsIndex !== 0 ? <Divider /> : null}
          <Accordion className={classes.boxAccordion}>
            <AccordionSummary className={classes.boxAccordionSummary} expandIcon={<ExpandMoreIcon />}>
              <div>{details.title}</div>
              <div className={classes.summaryAmount}>
                {details.lines[0].currency} {Utils.formatCurrency(details.total)}
              </div>
            </AccordionSummary>
            <AccordionDetails className={classes.boxAccordionLines}>
              <Divider />
              <div className={classes.boxAccordionLinesWrapper}>{invoiceDetailsLines(true)}</div>
            </AccordionDetails>
          </Accordion>
          <Divider />
        </React.Fragment>
      );
    }

    return invoiceDetailsLines();
  };

  if (forbiddenSection) {
    return (
      <Page withHeader withSidebar withHeaderTitle={i18n.get('OperationDetails.Title')}>
        <ForbiddenSection />
      </Page>
    );
  }

  return (
    <Page withHeader withSidebar withHeaderTitle={i18n.get('OperationDetails.Title')}>
      <TitleBox
        title={i18n.get('OperationDetails.BoxTitle')}
        back
        backTitle={i18n.get('OperationDetails.BoxBackTitle')}
        onBackClick={_goBack}
        backButtonVariant="outlined"
      />
      {hasOperationDetails ? (
        <React.Fragment>
          <BoxSeparator />
          <Grid container spacing={4}>
            <Grid item xs={12} sm={12} md={6}>
              <Box>
                <div className={clsx(classes.boxContentWrapper, classes.boxContentWrapperMain)}>
                  <div>{operationDetails.textCurrencyAndTotal.text}</div>
                  <div>
                    {operationDetails.textCurrencyAndTotal.currency}{' '}
                    {Utils.formatCurrency(operationDetails.textCurrencyAndTotal.total)}
                  </div>
                </div>
              </Box>
              <BoxSeparator />
              <Box>
                <div className={classes.boxContentWrapper}>
                  {renderCardAuthorizarionLines(operationDetails.cardAuthorization)}
                </div>
              </Box>
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <Box>
                <div className={classes.boxContentWrapper}>{renderInvoiceDetails(operationDetails.detail)}</div>
              </Box>
            </Grid>
          </Grid>
        </React.Fragment>
      ) : null}
    </Page>
  );
};

OperationDetailsScreen.id = 'com.Handy.OperationDetails';

export default OperationDetailsScreen;
