import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  BoxSeparator,
  ForbiddenSection,
  Page,
  TitleBox,
  ContentBox,
  StatusPill,
} from "../../../../../components";
import ROUTES from "../../../../../routes";
import { Language, Utils } from "../../../../../utils";
import { business as businessActions } from "../../../../../actions";
import { goBack } from "connected-react-router";
import makeClasses from "./styles";
import { Grid } from "@material-ui/core";

import { Policies } from "../../../../../utils/Policies";

const CURRENCY_FILTER_OPTIONS = [
  { id: "URUGUAYAN_PESO", name: "Currency.UYU" },
  { id: "DOLLAR", name: "Currency.USD" },
];

const FREQUENCE_FILTER_OPTIONS = [
  { id: "HOURLY", name: "Frequency.HOURLY" },
  { id: "DAILY", name: "Frequency.DAILY" },
  { id: "MONTHLY", name: "Frequency.MONTHLY" },
  { id: "ANNUAL", name: "Frequency.ANNUAL" },
  { id: "WEEKLY", name: "Frequency.WEEKLY" },
];

const FIELD_TYPES_OPTIONS = [
  {
    id: "STRING",
    name: "ProvidersManageSubscribersRequestNewCharge.AdditionalFieldTypeOption1",
  },
  {
    id: "INTEGER",
    name: "ProvidersManageSubscribersRequestNewCharge.AdditionalFieldTypeOption2",
  },
];

const ProvidersSubscriptionsPlanDetailsScreen = ({ match }) => {
  const dispatch = useDispatch();
  const classes = makeClasses();

  const { policies, languageTexts } = useSelector((state) => ({
    policies: state.user.userData.policies || [],
    languageTexts: state.language.texts || {},
  }));
  const i18n = Language(languageTexts);
  const subscriptionPlanTraceId =
    match && match.params ? match.params.traceId : null;

  const [plan, setPlan] = useState(null);
  const [forbiddenSection, setForbbidenSection] = useState(false);

  useEffect(() => {
    if (!policies || !policies.includes(Policies.types.PROVIDER)) {
      setForbbidenSection(true);
    }
  }, [policies]);

  useEffect(() => {
    if (!forbiddenSection) {
      loadData();
    }
  }, [forbiddenSection]);

  const loadData = () => {
    if (subscriptionPlanTraceId) {
      dispatch(
        businessActions.getProvidersSubscriptionsPlan(
          subscriptionPlanTraceId,
          (data) => {
            dispatch(
              businessActions.getProvidersSubscriptionsTermsAndConditionsLink(data.branchTraceId, (link) => {
                if (link) {
                  data.termsLink = link
                }
                setPlan(data);
              })
            );
          }
        )
      );
    }
  };

  const handleOnGoBackClick = () => {
    dispatch(goBack());
  };

  if (forbiddenSection) {
    return (
      <Page
        withHeader
        withSidebar
        withHeaderTitle={i18n.get("ProvidersSubscriptionsPlanDetails.Title")}
        withActivePage={ROUTES.MANAGE_PROVIDERS_SUBSCRIPTIONS.id}
      >
        <ForbiddenSection />
      </Page>
    );
  }

  return (
    <Page
      withHeader
      withSidebar
      withHeaderTitle={i18n.get("ProvidersSubscriptionsPlanDetails.Title")}
      withActivePage={ROUTES.MANAGE_PROVIDERS_SUBSCRIPTIONS.id}
    >
      <TitleBox
        title={i18n.get("ProvidersSubscriptionsPlanDetails.BoxTitle")}
        back
        backTitle={i18n.get("ProvidersSubscriptionsPlanDetails.BackButton")}
        onBackClick={handleOnGoBackClick}
        backButtonVariant="outlined"
      />
      <BoxSeparator />
      {plan ? (
        <React.Fragment>
          <ContentBox
            title={i18n.get(
              "ProvidersSubscriptionsPlanDetails.SubscriptionBoxDataLabel"
            )}
          >
            <Grid container spacing={4}>
              <Grid item xs={12} sm={12} md={3}>
                <div className={classes.suscriptionDetailsItem}>
                  <div>
                    {i18n.get(
                      "ProvidersSubscriptionsPlanDetails.PlanBoxNameLabel"
                    )}
                    :
                  </div>
                  {plan.name || "-"}
                </div>
              </Grid>
              <Grid item xs={12} sm={12} md={3}>
                <div className={classes.suscriptionDetailsItem}>
                  <div>
                    {i18n.get(
                      "ProvidersSubscriptionsPlanDetails.PlanBoxDescriptionLabel"
                    )}
                    :
                  </div>
                  {plan.description || "-"}
                </div>
              </Grid>
              <Grid item xs={12} sm={12} md={3}>
                <div className={classes.suscriptionDetailsItem}>
                  <div>
                    {i18n.get(
                      "ProvidersSubscriptionsPlanDetails.PlanBoxBranchLabel"
                    )}
                    :
                  </div>
                  {plan.branch ? plan.branch.name : "-"}
                </div>
              </Grid>
              <Grid item xs={12} sm={12} md={3}>
                <div className={classes.suscriptionDetailsItem}>
                  <div>
                    {i18n.get(
                      "ProvidersSubscriptionsPlanDetails.PlanBoxCurrencyLabel"
                    )}
                    :
                  </div>
                  {plan.currency
                    ? i18n.get(
                        CURRENCY_FILTER_OPTIONS.find(
                          (s) => s.id === plan.currency
                        )?.name
                      )
                    : "-"}
                </div>
              </Grid>
              <Grid item xs={12} sm={12} md={3}>
                <div className={classes.suscriptionDetailsItem}>
                  <div>
                    {i18n.get(
                      "ProvidersSubscriptionsPlanDetails.PlanBoxAmountLabel"
                    )}
                    :
                  </div>
                  {plan.amount ? Utils.formatCurrency(plan.amount) : "-"}
                </div>
              </Grid>
              <Grid item xs={12} sm={12} md={3}>
                <div className={classes.suscriptionDetailsItem}>
                  <div>
                    {i18n.get(
                      "ProvidersSubscriptionsPlanDetails.PlanBoxTaxAmountLabel"
                    )}
                    :
                  </div>
                  {plan.amount ? `${Math.round(((plan.amount - plan.taxableAmount) / plan.amount) * 100)}%` : "-"}
                </div>
              </Grid>
              <Grid item xs={12} sm={12} md={3}>
                <div className={classes.suscriptionDetailsItem}>
                  <div>
                    {i18n.get(
                      "ProvidersSubscriptionsPlanDetails.PlanBoxFrecuenceLabel"
                    )}
                    :
                  </div>
                  {plan.frequence
                    ? i18n.get(
                        FREQUENCE_FILTER_OPTIONS.find(
                          (s) => s.id === plan.frequence
                        )?.name
                      )
                    : "-"}
                </div>
              </Grid>
              <Grid item xs={12} sm={12} md={3}>
                <div className={classes.suscriptionDetailsItem}>
                  <div>
                    {i18n.get(
                      "ProvidersSubscriptionsPlanDetails.PlanBoxTermsLabel"
                    )}
                    :
                  </div>
                  {plan.termsLink ? (
                    <a href={plan.termsLink} target="_blank" rel="noreferrer">{i18n.get(
                      "ProvidersSubscriptionsPlanDetails.PlanBoxTermsValue"
                    )}</a>
                  ) : (
                    "-"
                  )}
                </div>
              </Grid>
              <Grid item xs={12} sm={12} md={3}>
                <div className={classes.suscriptionDetailsItem}>
                  <div>
                    {i18n.get(
                      "ProvidersSubscriptionsPlanDetails.PlanBoxStatusLabel"
                    )}
                    :
                  </div>
                  {plan.subscriptionPlanStatus ? (
                    <StatusPill data={plan.subscriptionPlanStatus} />
                  ) : (
                    "-"
                  )}
                </div>
              </Grid>
            </Grid>
          </ContentBox>
          <BoxSeparator size="small" />
          <ContentBox
            title={i18n.get(
              "ProvidersSubscriptionsPlanDetails.SubscriptionBoxOptionalDataLabel"
            )}
          >
            <Grid container spacing={4}>
              <Grid item xs={12} sm={12} md={3}>
                <div className={classes.suscriptionDetailsItem}>
                  <div>
                    {i18n.get(
                      "ProvidersSubscriptionsPlanDetails.PlanBoxCodeLabel"
                    )}
                    :
                  </div>
                  {plan.code || "-"}
                </div>
              </Grid>
              <Grid item xs={12} sm={12} md={3}>
                <div className={classes.suscriptionDetailsItem}>
                  <div>
                    {i18n.get(
                      "ProvidersSubscriptionsPlanDetails.PlanBoxGroupLabel"
                    )}
                    :
                  </div>
                  {plan.group || "-"}
                </div>
              </Grid>
              <Grid item xs={12} sm={12} md={3}>
                <div className={classes.suscriptionDetailsItem}>
                  <div>
                    {i18n.get(
                      "ProvidersSubscriptionsPlanDetails.PlanBoxDurationLabel"
                    )}
                    :
                  </div>
                  {plan.duration || "-"}
                </div>
              </Grid>
              <Grid item xs={12} sm={12} md={3}>
                <div className={classes.suscriptionDetailsItem}>
                  <div>
                    {i18n.get(
                      "ProvidersSubscriptionsPlanDetails.PlanBoxPaymentDayLabel"
                    )}
                    :
                  </div>
                  {plan.paymentDay || "-"}
                </div>
              </Grid>
              <Grid item xs={12} sm={12} md={3}>
                <div className={classes.suscriptionDetailsItem}>
                  <div>
                    {i18n.get(
                      "ProvidersSubscriptionsPlanDetails.PlanBoxCallbackUrlLabel"
                    )}
                    :
                  </div>
                  {plan.callbackUrl || "-"}
                </div>
              </Grid>
            </Grid>
          </ContentBox>
          {plan.additionalFields && plan.additionalFields.length > 0 ? (
            <React.Fragment>
              <BoxSeparator size="small" />
              <ContentBox
                title={i18n.get(
                  "ProvidersSubscriptionsPlanDetails.SubscriptionBoxAdditionalDataLabel"
                )}
              >
                {plan.additionalFields.map((field, key) => (
                  <Grid container spacing={4}>
                    <Grid key={key} item xs={12} sm={12} md={3}>
                      <div className={classes.suscriptionDetailsItem}>
                        <div>
                          {i18n.get(
                            "ProvidersSubscriptionsPlanDetails.PlanBoxAdditionalDataNameLabel"
                          )}
                          :
                        </div>
                        {field.name || "-"}
                      </div>
                    </Grid>
                    <Grid key={key} item xs={12} sm={12} md={3}>
                      <div className={classes.suscriptionDetailsItem}>
                        <div>
                          {i18n.get(
                            "ProvidersSubscriptionsPlanDetails.PlanBoxAdditionalDataInternalNameLabel"
                          )}
                          :
                        </div>
                        {field.key || "-"}
                      </div>
                    </Grid>
                    <Grid key={key} item xs={12} sm={12} md={3}>
                      <div className={classes.suscriptionDetailsItem}>
                        <div>
                          {i18n.get(
                            "ProvidersSubscriptionsPlanDetails.PlanBoxAdditionalDataTypeLabel"
                          )}
                          :
                        </div>
                        {field.type
                          ? i18n.get(
                              FIELD_TYPES_OPTIONS.find(
                                (s) => s.id === field.type
                              ).name
                            )
                          : "-"}
                      </div>
                    </Grid>
                  </Grid>
                ))}
              </ContentBox>
            </React.Fragment>
          ) : null}
        </React.Fragment>
      ) : null}
    </Page>
  );
};

ProvidersSubscriptionsPlanDetailsScreen.id =
  "com.Handy.ProvidersSubscriptionsPlanDetails";

export default ProvidersSubscriptionsPlanDetailsScreen;
