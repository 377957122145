import 'moment/locale/es';

let languageTexts = {};

const getTextFromKey = (key, texts) => {
  const keyPath = key.split('.');
  if (keyPath.length === 1) {
    if (!texts[key]) {
      return null;
    }
    return texts[key];
  }
  const parentKey = keyPath.shift();
  if (texts[parentKey]) {
    return getTextFromKey(keyPath.join('.'), texts[parentKey]);
  }
  return null;
};

const Languages = _languageTexts => {
  if (!languageTexts || Object.keys(languageTexts).length === 0) {
    languageTexts = _languageTexts || {};
  }

  return {
    get: (key, returnMissingKey = true) => {
      const text = getTextFromKey(key, languageTexts);

      if (!text) {
        return returnMissingKey ? `MISSING_TEXT_${key}` : null;
      }

      return text;
    },
  };
};

export default Languages;
