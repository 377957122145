import { makeStyles } from '@material-ui/core';
import { THEME_LAYOUT, THEME_SIDEMENU_WIDTH } from '../../theme';
import { Colors } from '../../utils';

const makeClasses = makeStyles(theme => ({
  sideMenu: {
    width: THEME_SIDEMENU_WIDTH,
    flexShrink: 0,
  },
  withBanner: {
    top: 55,
    height: 'calc(100% - 55px)',
  },
  withBoxBanner: {
    top: 60,
    height: 'calc(100% - 60px)',
  },
  withBoxBannerMd: {
    top: 110,
    height: 'calc(100% - 110px)',

    [theme.breakpoints.down('md')]: {
      top: 60,
      height: 'calc(100% - 60px)',
    },
  },
  sideMenuPaper: {
    width: THEME_SIDEMENU_WIDTH,
    background: Colors.rgb.menuBackgroundPrimary,
    borderRight: 'none',
    boxShadow:
      '0px 2px 10px -1px rgb(0 0 0 / 10%), 0px 4px 2px 0px rgb(0 0 0 / 10%), 0px 1px 10px 0px rgb(0 0 0 / 10%)',
  },
  sideMenuToolbarWrapper: {
    background: theme.palette.secondary.main,
    borderRadius: 0,
    color: Colors.rgb.white,
    height: 66,
  },
  sideMenuToolbar: {
    height: 66,
    paddingLeft: theme.spacing(THEME_LAYOUT.HORIZONTAL.INNER.L),
    paddingRight: theme.spacing(THEME_LAYOUT.HORIZONTAL.INNER.L),
  },
  list: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  listItem: {
    padding: 0,
    paddingRight: theme.spacing(1),
    paddingLeft: theme.spacing(3),
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    position: 'relative',

    '&:hover': {
      background: Colors.rgb.menuBackgroundSecondary,
    },
  },
  listSectionItem: {
    width: '100%',
    padding: 0,
    boxSizing: 'border-box',
    margin: '0 !important',
    position: 'relative',
    boxShadow: 'none',

    '&:before': {
      display: 'none',
    },

    '& > div': {
      padding: 0,
      minHeight: 'unset !important',

      '& > div': {
        margin: '0 !important',
      },
    },
  },
  listSectionItemTitle: {
    padding: '5px 10px 5px 20px !important',
    position: 'relative',

    '&:hover': {
      background: Colors.rgb.menuBackgroundSecondary,
    },

    '& > div:nth-child(2)': {
      padding: '0 !important',
    },
  },
  listSectionItemTitleSelected: {
    background: 'none !important',

    '& img': {
      filter: 'none !important',
    },
    '& span': {
      color: Colors.rgb.primary,
    },
  },
  listSectionItemDetails: {
    borderTop: `1px solid ${Colors.rgb.contentLine}`,
    padding: 0,
    margin: `0 ${theme.spacing(4)}`,

    '& > ul': {
      width: '100%',
      padding: 0,

      '& > div': {
        padding: `5px 0 5px ${theme.spacing(5)}`,

        '& span': {
          fontSize: `${theme.fontSize.S} !important`,
        },
        '& img': {
          width: `22px !important`,
        },
      },
    },
  },
  listLinkItem: {
    width: '100%',
    padding: 0,
    paddingLeft: theme.spacing(4),
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    position: 'relative',
    textAlign: 'left',
    color: Colors.rgb.menuText,
  },
  listItemSeparator: {
    height: 1,
    width: '85%',
    background: Colors.rgb.contentLine,
  },
  listItemInnerSeparator: {
    padding: '0 !important',
    width: '100% !important',
  },
  listItemSelected: {
    background: 'none !important',

    '& img': {
      filter: 'none !important',
    },
    '& span': {
      color: Colors.rgb.primary,
    },
  },
  listItemSelectedIndicator: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    width: 5,
    background: Colors.rgb.secondary,
  },
  listItemText: {
    color: Colors.rgb.menuText,
    display: 'flex',
    alignItems: 'center',

    '& > span': {
      fontSize: theme.fontSize.M,
      fontFamily: theme.fontFamily.regular,
    },
  },
  listItemIconWrapper: {
    margin: '4px 5px 4px 0	',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: 35,
    height: 32,
  },
  listItemIcon: {
    width: 45,
    filter: 'grayscale(100%)',
  },
  listItemIconSvg: {
    filter: 'grayscale(100%)',
    opacity: 0.7,
  },
  appLogoWrapper: {},
  appLogo: {
    height: 25,
  },
}));

export default makeClasses;
