import { makeStyles } from '@material-ui/core';
import { Colors } from '../../utils';

const makeClasses = makeStyles(theme => ({
  filtersWrapper: {
    display: 'flex',
    alignItems: 'flex-start',

    [theme.breakpoints.down('sm')]: {
      alignItems: 'self-start',
      justifyContent: 'center',
      flexDirection: 'column',
    },
  },
  filtersWrapperVertical: {
    alignItems: 'self-start',
    justifyContent: 'center',
    flexDirection: 'column',
  },
  label: {
    fontSize: theme.fontSize.M,
    fontFamily: theme.fontFamily.regular,
    color: Colors.rgb.contentText,
    marginRight: theme.spacing(2),
    marginTop: theme.spacing(1),
    whiteSpace: 'nowrap',

    [theme.breakpoints.down('sm')]: {
      marginRight: 0,
      marginBottom: theme.spacing(1),
    },
  },
  filters: {
    display: 'flex',
    flexWrap: 'wrap',
    width: '100%',

    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
}));

export default makeClasses;
