import { makeStyles } from '@material-ui/core';
import { Colors } from '../../utils';

const makeClasses = makeStyles(theme => ({
  boxMenuContainer: {
    background: `${Colors.rgb.grey} !important`,
    borderBottom: `1px solid ${Colors.rgb.contentLine}`,
  },
  boxMenuContentContainer: {
    display: 'flex',
    flexDirection: 'row',

    '& > div': {
      marginRight: theme.spacing(2),

      '&:last-child': {
        marginRight: 0,
      },
    },
  },
}));

export default makeClasses;
