import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Box,
  BoxSeparator,
  Button,
  ContentBox,
  FiltersContainer,
  FiltersDateSelector,
  FiltersToggle,
  ForbiddenSection,
  OperationsTable,
  Page,
  TitleBox,
} from '../../components';
import ROUTES from '../../routes';
import { Colors, Language, Utils } from '../../utils';
import { business as businessActions } from '../../actions';
import { goBack, push, replace } from 'connected-react-router';
import qs from 'query-string';
import { CUSTOM_FILTER, getDateRangeForFilter } from '../../components/FiltersDateSelector';
import makeClasses from './styles';
import AccountIcon from '../../static/images/icons/account-round.svg';
import { formatCurrency, toISOString } from '../../utils/Utils';
import moment from 'moment';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
  Hidden,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import { ExpandMore as ExpandMoreIcon } from '@material-ui/icons';
import clsx from 'clsx';
import SaveAltIcon from '@material-ui/icons/SaveAlt';
import { Policies } from '../../utils/Policies';

const SIGN_FILTER_OPTIONS = [
  {
    id: 0,
    name: 'AccountDetails.OperationTypeFilter.ALL',
    label: 'AccountDetails.OperationTypeFilter.ALL',
    value: null,
    translate: true,
  },
  {
    id: 1,
    name: 'AccountDetails.OperationTypeFilter.CREDIT',
    label: 'AccountDetails.OperationTypeFilter.CREDIT',
    value: 'CREDIT',
    translate: true,
  },
  {
    id: 2,
    name: 'AccountDetails.OperationTypeFilter.DEBIT',
    label: 'AccountDetails.OperationTypeFilter.DEBIT',
    value: 'DEBIT',
    translate: true,
  },
];

const VIEW_FILTER_OPTIONS = [
  { id: 0, label: 'AccountDetails.ViewFilter.ALL' },
  { id: 1, label: 'AccountDetails.ViewFilter.BY_DATE' },
];

const VIEW_FILTER = {
  ALL: 0,
  DATE: 1,
};

const AccountDetailsScreen = ({ match, history }) => {
  const dispatch = useDispatch();
  const searchParams = qs.parse(history.location.search);
  const classes = makeClasses();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));

  const { policies, languageTexts } = useSelector(state => ({
    policies: state.user.userData.policies || [],
    languageTexts: state.language.texts || {},
  }));
  const i18n = Language(languageTexts);
  const accountTraceId = match.params.accountTraceId || null;

  const [ accountDetails, setAccountDetails ] = useState({ account: {}, movements: [] });
  const [ paginationPage, setPaginationPage ] = useState(searchParams.page ? parseInt(searchParams.page) : 1);
  const [ paginationPageSize ] = useState(10);
  const [ selectedFilter, setSelectedFilter ] = useState(searchParams.fid ? parseInt(searchParams.fid) : 3);
  const [ selectedCustomFilter, setSelectedCustomFilter ] = useState(
    parseInt(searchParams.fid) === CUSTOM_FILTER ? { startDate: searchParams.fsd, endDate: searchParams.fed } : {}
  );
  const [ signFilter, setSignFilter ] = useState(searchParams.sid ? parseInt(searchParams.sid) : 0);
  const [ selectedView, setSelectedView ] = useState(searchParams.view ? parseInt(searchParams.view) : 0);
  const [ forbiddenSection, setForbbidenSection ] = useState(false);
  const prevPaginationPage = useRef(null);
  const canExportAccount =
    policies && policies.includes(Policies.types.ACCOUNT) && policies.includes(Policies.types.SUPER_READ);

  useEffect(
    () => {
      if (!policies || !policies.includes(Policies.types.ACCOUNT)) {
        setForbbidenSection(true);
      }
    },
    [ policies ]
  );

  useEffect(
    () => {
      if (!forbiddenSection) {
        if (paginationPage > 1 && prevPaginationPage.current === paginationPage) {
          _handleOnPaginationChange(1);
          prevPaginationPage.current = null;
        } else {
          loadData();
          prevPaginationPage.current = paginationPage;
        }
      }
    },
    [ forbiddenSection, accountTraceId, paginationPage, selectedFilter, selectedCustomFilter, signFilter, selectedView ]
  );

  const loadData = () => {
    if (accountTraceId) {
      const dateFilter = getDateRangeForFilter(
        selectedFilter,
        selectedFilter === CUSTOM_FILTER ? selectedCustomFilter : null
      );
      const startDate = toISOString(dateFilter.startDate);
      const endDate = toISOString(dateFilter.endDate);

      dispatch(
        businessActions.getAccountDetails(
          accountTraceId,
          startDate,
          endDate,
          signFilter >= 0 ? SIGN_FILTER_OPTIONS.find(f => f.id === signFilter).value : null,
          paginationPage,
          selectedView,
          details => {
            setAccountDetails(details);
          }
        )
      );
    }
  };

  const _handleOnGoBackClick = () => {
    dispatch(goBack());
  };

  const _handleOnPaginationChange = nextPage => {
    setPaginationPage(nextPage);

    const searchParams = qs.parse(history.location.search) || {};
    searchParams.page = nextPage;

    dispatch(
      replace({
        search: qs.stringify(searchParams),
      })
    );
  };

  const _handleOnFilterSelected = (filter, startDate, endDate) => {
    setSelectedFilter(filter);

    const searchParams = qs.parse(history.location.search) || {};
    searchParams.fid = filter;

    if (filter === CUSTOM_FILTER) {
      setSelectedCustomFilter({ startDate, endDate });
      searchParams.fsd = startDate;
      searchParams.fed = endDate;
    }

    dispatch(
      replace({
        search: qs.stringify(searchParams),
      })
    );
  };

  const _handleOnSignSelected = sign => {
    setSignFilter(sign);

    const searchParams = qs.parse(history.location.search) || {};
    searchParams.sid = sign;

    dispatch(
      replace({
        search: qs.stringify(searchParams),
      })
    );
  };

  const _handleOnViewChange = view => {
    setSelectedView(view);
    setAccountDetails({ account: {}, movements: [] });

    const searchParams = qs.parse(history.location.search) || {};
    searchParams.view = view;

    dispatch(
      push({
        search: qs.stringify(searchParams),
      })
    );
  };

  const _handleOnGridExport = () => {
    const dateFilter = getDateRangeForFilter(
      selectedFilter,
      selectedFilter === CUSTOM_FILTER ? selectedCustomFilter : null
    );
    const startDate = toISOString(dateFilter.startDate);
    const endDate = toISOString(dateFilter.endDate);

    dispatch(
      businessActions.exportTable(
        'account-details',
        {
          traceId: accountTraceId,
          sign: signFilter >= 0 ? SIGN_FILTER_OPTIONS.find(f => f.id === signFilter).value : null,
          start: startDate,
          end: endDate,
          page: paginationPage,
          count: 9999
        },
        file =>
          Utils.downloadFile(
            file,
            'CuentaHandy_' +
              dateFilter.startDate.format(i18n.get('ExportDateFormat')) +
              '_' +
              dateFilter.endDate.format(i18n.get('ExportDateFormat')) +
              '.xlsx'
          )
      )
    );
  };

  const renderFiltersDateSelector = () => (
    <FiltersDateSelector
      selectedFilter={selectedFilter}
      startDate={selectedCustomFilter.startDate}
      endDate={selectedCustomFilter.endDate}
      onFilterSelected={_handleOnFilterSelected}
      align="right"
      dateFormat={i18n.get('DateFormat', false)}
    />
  );

  const renderSignsToggle = () => (
    <FiltersToggle
      showLabel={false}
      showDefault={false}
      options={SIGN_FILTER_OPTIONS}
      selected={signFilter}
      onChange={_handleOnSignSelected}
      flexOptions={false}
    />
  );

  const renderViewToggle = () => (
    <FiltersToggle
      showLabel={false}
      showDefault={false}
      defaultOption={i18n.get('AccountDetails.ViewFilter.ALL')}
      options={VIEW_FILTER_OPTIONS}
      onChange={_handleOnViewChange}
      selected={selectedView}
    />
  );

  const renderOperationsTable = () => {
    if (!accountDetails || !accountDetails.movements || !accountDetails.movements.elements || !accountDetails.account) {
      return null;
    }

    const headers = [
      {
        canHide: false,
        columnNumber: 1,
        name: i18n.get('AccountDetails.Grid.Column1'),
        sortable: false,
        type: 'date-time',
      },
      {
        canHide: false,
        columnNumber: 2,
        name: i18n.get('AccountDetails.Grid.Column2'),
        sortable: false,
        type: 'text',
      },
      {
        canHide: false,
        columnNumber: 3,
        name: i18n.get('AccountDetails.Grid.Column3'),
        sortable: false,
        type: 'number-empty',
      },
      {
        canHide: false,
        columnNumber: 4,
        name: i18n.get('AccountDetails.Grid.Column4'),
        sortable: false,
        type: 'number-empty',
      },
    ];
    const body = (accountDetails.movements.elements || []).map(item => ({
      rowId: item.id,
      columns: [
        {
          columnNumber: 1,
          data: item.realExecutionDate,
        },
        {
          columnNumber: 2,
          data: item.reason,
        },
        {
          columnNumber: 3,
          data: item.debit || null,
        },
        {
          columnNumber: 4,
          data: item.credit || null,
        },
      ],
    }));

    return (
      <React.Fragment>
        <div className={classes.gridSeparator} />
        <OperationsTable
          headers={headers}
          body={body}
          currentPage={paginationPage}
          paginationSimple
          hasMorePages={(accountDetails.movements.elements || []).length === paginationPageSize}
          onPaginationChange={_handleOnPaginationChange}
          emptyMessage={i18n.get('AccountDetails.GridEmptyMessage')}
          showDetails={false}
          displayAsSales
        />
      </React.Fragment>
    );
  };

  const renderOperationsByDate = () => {
    if (!accountDetails || !accountDetails.movements || !accountDetails.movements.elements || !accountDetails.account) {
      return null;
    }

    accountDetails.movements.elements.forEach(element => {
      element.totalCredit = element.movements.map(m => m.credit).reduce((a, b) => a + b);
      element.totalDebit = element.movements.map(m => m.debit).reduce((a, b) => a + b);
    });

    return accountDetails.movements.elements.map((item, index) => (
      <React.Fragment key={index}>
        {index !== 0 ? <div className={classes.accordionSeparator} /> : null}
        <Accordion className={classes.accordionContainer} classes={{ expanded: classes.accordionContainerExpanded }}>
          <AccordionSummary
            className={classes.accordionSummary}
            classes={{
              content: classes.accordionSummaryContent,
              expanded: classes.accordionSummaryExpanded,
              expandIcon: classes.accordionSummaryExpandIcon,
            }}
            expandIcon={<ExpandMoreIcon />}
          >
            <div className={classes.accordionTitleWrapper}>{moment(item.executionDate).format('DD-MMM-YYYY')}</div>
          </AccordionSummary>
          <AccordionDetails className={classes.accordionDetailsContainer}>
            <div className={classes.contentSeparator} />
            <Grid container direction="row" className={classes.accordionGridHeader}>
              <Grid item xs={4} md={6} />
              <Grid item xs={4} md={3} className={classes.accordionGridItemCentered}>
                {i18n.get('AccountDetails.GridByDate.CreditHeader')}
              </Grid>
              <Grid item xs={4} md={3} className={classes.accordionGridItemCentered}>
                {i18n.get('AccountDetails.GridByDate.DebitHeader')}
              </Grid>
            </Grid>
            {item.movements.map((movement, key) => (
              <Grid key={key} container direction="row" className={classes.accordionGridRow}>
                <Grid item xs={4} md={6} className={clsx(classes.accordionGridItem, classes.accordionGridItemLabel)}>
                  {movement.reason}
                </Grid>
                <Grid item xs={4} md={3} className={clsx(classes.accordionGridItem, classes.accordionGridItemCentered)}>
                  {movement.credit !== 0 ? Utils.formatCurrency(movement.credit) : null}
                </Grid>
                <Grid item xs={4} md={3} className={clsx(classes.accordionGridItem, classes.accordionGridItemCentered)}>
                  {movement.debit !== 0 ? Utils.formatCurrency(movement.debit) : null}
                </Grid>
              </Grid>
            ))}
            <Grid container direction="row">
              <Grid item xs={4} md={6} />
              <Grid item xs={4} md={3} className={clsx(classes.accordionGridItem, classes.accordionGridItemCentered)}>
                <div>{i18n.get('AccountDetails.GridByDate.CreditTotal')}</div>
                <div
                  className={
                    item.totalCredit > 0 ? (
                      classes.accordionGridItemTotalPositive
                    ) : item.totalCredit < 0 ? (
                      classes.accordionGridItemTotalNegative
                    ) : null
                  }
                >
                  {Utils.formatCurrency(item.totalCredit)}
                </div>
              </Grid>
              <Grid item xs={4} md={3} className={clsx(classes.accordionGridItem, classes.accordionGridItemCentered)}>
                <div>{i18n.get('AccountDetails.GridByDate.DebitTotal')}</div>
                <div
                  className={
                    item.totalDebit > 0 ? (
                      classes.accordionGridItemTotalPositive
                    ) : item.totalDebit < 0 ? (
                      classes.accordionGridItemTotalNegative
                    ) : null
                  }
                >
                  {Utils.formatCurrency(item.totalDebit)}
                </div>
              </Grid>
            </Grid>
          </AccordionDetails>
        </Accordion>
      </React.Fragment>
    ));
  };

  if (forbiddenSection) {
    return (
      <Page
        withHeader
        withSidebar
        withHeaderTitle={i18n.get('AccountDetails.Title')}
        withActivePage={ROUTES.ACCOUNT.id}
      >
        <ForbiddenSection />
      </Page>
    );
  }

  return (
    <Page withHeader withSidebar withHeaderTitle={i18n.get('AccountDetails.Title')} withActivePage={ROUTES.ACCOUNT.id}>
      <TitleBox
        title={i18n.get('AccountDetails.BoxTitle')}
        back
        backTitle={i18n.get('AccountDetails.BackButton')}
        onBackClick={_handleOnGoBackClick}
        backButtonVariant="outlined"
      />
      <BoxSeparator />
      <Hidden xsDown>
        <Box>
          <div className={classes.viewFilterWrapper}>
            <div>{i18n.get('AccountDetails.BoxViewTitle')}</div>
            {renderViewToggle()}
          </div>
        </Box>
      </Hidden>
      <Hidden smUp>
        <ContentBox title={i18n.get('AccountDetails.BoxViewTitle')} titleBold>
          <FiltersContainer>{renderViewToggle()}</FiltersContainer>
        </ContentBox>
      </Hidden>
      <BoxSeparator size="small" />
      <Hidden xsDown>
        <ContentBox title={i18n.get('AccountDetails.BoxFiltersTitle')} titleBold>
          <FiltersContainer>{renderFiltersDateSelector()}</FiltersContainer>
        </ContentBox>
      </Hidden>
      <Hidden smUp>
        <ContentBox title={i18n.get('AccountDetails.BoxFiltersTitle')} titleBold>
          <FiltersContainer showLabel={false}>{renderFiltersDateSelector()}</FiltersContainer>
        </ContentBox>
      </Hidden>
      <BoxSeparator size="small" />
      <Box border borderWidth={2} borderColor={Colors.rgb.primary}>
        <div className={classes.gridTitleContainer}>
          <div>
            <Hidden xsDown>
              <img src={AccountIcon} alt="Account Icon" className={classes.gridTitleIcon} />
              <span>{accountDetails.account.name}</span>
              <span>|</span>
              <span>{i18n.get('AccountDetails.GridTitleCurrency')}</span>
              <span>{accountDetails.account.currency}</span>
            </Hidden>
            <Hidden smUp>
              <img src={AccountIcon} alt="Account Icon" className={classes.gridTitleIcon} />
              <span>{accountDetails.account.name}</span>
            </Hidden>
          </div>
          <div>
            <span>{i18n.get('AccountDetails.GridTitleBalance')}</span>
            <Hidden xsDown>
              <span>{formatCurrency(accountDetails.account.netBalance)}</span>
            </Hidden>
            <Hidden smUp>
              <span>{accountDetails.account.currency}</span>
              <span>{formatCurrency(accountDetails.account.netBalance)}</span>
            </Hidden>
          </div>
        </div>
      </Box>
      <Box direction="column">
        <div className={classes.gridTitleWrapper}>
          <div>
            <div>
              {isSmallScreen ? i18n.get('AccountDetails.GridTitleReduced') : i18n.get('AccountDetails.GridTitle')}
            </div>
            {renderSignsToggle()}
          </div>
          {canExportAccount ? (
            <Button onClick={_handleOnGridExport} rightIcon={() => <SaveAltIcon />}>
              {isSmallScreen ? null : i18n.get('AccountDetails.GridExportButton')}
            </Button>
          ) : null}
        </div>
        <div className={classes.gridWrapper}>
          {selectedView === VIEW_FILTER.DATE ? renderOperationsByDate() : renderOperationsTable()}
        </div>
      </Box>
    </Page>
  );
};

AccountDetailsScreen.id = 'com.Handy.AccountDetails';

export default AccountDetailsScreen;
