import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';

import { Language, Utils } from '../../../utils';
import { user as userActions } from '../../../actions';
import { Button } from '../../../components';
import qs from 'query-string';

import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';

import makeClasses from '../styles';

import clsx from 'clsx';
import { Checkbox } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';

const StepOne = ({ history, nextStep, handleChange, code }) => {
  const dispatch = useDispatch();
  const classes = makeClasses();

  const { loading, languageTexts } = useSelector((state) => ({
    loading: state.user.loading,
    languageTexts: state.language.texts || {},
  }));

  const i18n = Language(languageTexts);

  const [inputRut, setInputRut] = useState('');
  const [inputEmail, setInputEmail] = useState('');
  const [inputPhone, setInputPhone] = useState('');
  const [countries, setCountries] = useState([]);
  const [inputCountry, setInputCountry] = useState(null);
  const [inputPhoneCharacteristic, setInputPhoneCharacteristic] = useState(null);

  const [acceptedHandyTermsAndConditions, setAcceptedHandyTermsAndConditions] = useState(false);
  const [acceptedPaigoTermsAndConditions, setAcceptedPaigoTermsAndConditions] = useState(false);

  const canMoveToStep2 =
    inputRut.trim().length > 0 &&
    inputEmail.length > 0 &&
    inputPhone.length > 0 &&
    inputPhone.length <= 15 &&
    inputPhoneCharacteristic !== null &&
    inputCountry !== null &&
    acceptedHandyTermsAndConditions &&
    acceptedPaigoTermsAndConditions;

  const handleNext = () => {
    const selectedCountry = countries.find((c) => c.id === inputPhoneCharacteristic).id;

    const phone = inputPhone.trim();

    const completePhoneNumber = countries.find((c) => c.id === inputPhoneCharacteristic).dialingCode + phone;

    if (!Utils.inputs.rutInput.regex.test(inputRut.trim())) {
      return toast.error(i18n.get('LegalEntityRegistration.RegisterInvalidRut'), { autoClose: 5000 });
    }
    if (!Utils.inputs.email.regex.test(inputEmail.trim())) {
      return toast.error(i18n.get('LegalEntityRegistration.RegisterInvalidEmail'), { autoClose: 5000 });
    }

    if (!Utils.validatePhoneNumber(completePhoneNumber)) {
      return toast.error(i18n.get('LegalEntityRegistration.RegisterInvalidPhone', { autoClose: 5000 }));
    }

    dispatch(
      userActions.merchantStartAffiliation(
        {
          countryISO: 'UY',
          documentTypeKey: 'RUT',
          document: inputRut.trim(),
          affiliationCode: code,
          email: inputEmail.trim(),
          phone: {
            countryId: selectedCountry,
            number: inputPhone.trim(),
          },
        },
        (merchantAffiliationResult) => {
          if (merchantAffiliationResult.traceId) {
            nextStep({ data: merchantAffiliationResult.traceId });
          } else {
            return toast.error('error de algun tipo en el form', { autoClose: 5000 });
          }
        }
      )
    );
  };

  const _handleOnChangeInputPhone = (e) => {
    if (e.target.value.length <= 15) {
      setInputPhone(e.target.value);
    }
  };

  useEffect(() => {
    dispatch(
      userActions.getCountries((countries) => {
        setCountries(countries);
        countries.forEach((country) => {
          if (country.alphaCode2 === 'UY') {
            setInputCountry(country.id);
            setInputPhoneCharacteristic(country.id);
          }
        });
      })
    );
  }, [dispatch]);

  const _getOptionLabel = (array) => (option) => {
    let foundOption = null;
    if (array === 'countries') {
      foundOption = countries.find((c) => c.id === option);
      if (foundOption) return foundOption.name + ' ' + foundOption.dialingCode;
    } else if (array === 'phoneCharacteristics') {
      foundOption = countries.find((c) => c.id === option);
      if (foundOption) return foundOption.name + ' (' + foundOption.dialingCode + ')';
    }
    if (foundOption) {
      return foundOption.name;
    }
    return '';
  };

  const _handleOnChangePhoneCharacteristic = (e, newValue) => {
    setInputPhoneCharacteristic(newValue);
  };

  return (
    <React.Fragment>
      <div className={classes.formContent}>
        <div className={classes.formTitleContainer}>
          <div className={classes.formTitle}>{i18n.get('LegalEntityRegistration.StepFormTitle')}</div>
          <div className={classes.formText}>{i18n.get('LegalEntityRegistration.Step1FormText')}</div>
        </div>
        <form layout="vertical" onSubmit={handleNext} className={classes.form}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={4} xl={4}>
              <TextField
                placeholder={i18n.get('LegalEntityRegistration.InputRutTitle')}
                className={classes.formInput}
                onChange={(e) => setInputRut(e.target.value)}
                classes={{ root: classes.formInput }}
                variant="outlined"
                value={inputRut}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={8} xl={8}>
              <TextField
                placeholder={i18n.get('LegalEntityRegistration.InputEmailTitle')}
                className={classes.formInput}
                onChange={(e) => setInputEmail(e.target.value)}
                classes={{ root: classes.formInput }}
                variant="outlined"
                value={inputEmail}
              />
            </Grid>
            <Grid item xs={6} sm={6} md={4} xl={4}>
              <Autocomplete
                options={countries.map((c) => c.id)}
                getOptionLabel={_getOptionLabel('phoneCharacteristics')}
                onChange={_handleOnChangePhoneCharacteristic}
                classes={{ inputRoot: classes.formInputSelect }}
                value={inputPhoneCharacteristic}
                renderInput={(params) => (
                  <TextField
                    className={classes.formInput}
                    classes={{ root: classes.formInput }}
                    variant="outlined"
                    placeholder={i18n.get('LegalEntityRegistration.InputPhoneCharacteristic')}
                    {...params}
                  />
                )}
              />
            </Grid>
            <Grid item xs={6} sm={6} md={8} xl={8}>
              <TextField
                placeholder={i18n.get('LegalEntityRegistration.InputPhoneTitle')}
                className={classes.formInput}
                onChange={_handleOnChangeInputPhone}
                classes={{ root: classes.formInput }}
                variant="outlined"
                value={inputPhone}
              />
            </Grid>
          </Grid>
        </form>
      </div>
      <div className={classes.termsWrapper}>
        <div className={classes.termsContainer}>
          <Checkbox
            classes={{ root: classes.checkboxRoot }}
            checked={acceptedHandyTermsAndConditions}
            onChange={() => setAcceptedHandyTermsAndConditions(!acceptedHandyTermsAndConditions)}
          />
          <div
            className={classes.termsLabel}
            dangerouslySetInnerHTML={{ __html: i18n.get('LegalEntityRegistration.HandyTyC') }}
          />
        </div>
        <div className={classes.termsContainer}>
          <Checkbox
            classes={{ root: classes.checkboxRoot }}
            checked={acceptedPaigoTermsAndConditions}
            onChange={() => setAcceptedPaigoTermsAndConditions(!acceptedPaigoTermsAndConditions)}
          />
          <div
            className={classes.termsLabel}
            dangerouslySetInnerHTML={{ __html: i18n.get('LegalEntityRegistration.PaigoTyCLabel') }}
          />
        </div>

        <div
          className={clsx(
            classes.formSubmitContainer,
            classes.formSubmitContainerWithOneMiniButton,
            classes.formSubmitContainerWithNoMargin
          )}
        >
          <Button className={classes.formSubmitButton} disabled={loading || !canMoveToStep2} onClick={handleNext}>
            {i18n.get('LegalEntityRegistration.NextButton')}
          </Button>
        </div>
      </div>
    </React.Fragment>
  );
};
StepOne.id = 'com.Handy.LegalEntityRegistration.StepOne';
export default StepOne;
