export const Policies = {
  types: {
    // Read
    HOME: 'Summary:Read',
    ACCOUNT: 'Account:Read',
    ACTIVITY: 'Activity:Read',
    HANDY_LINK: 'PaymentLink:Read',
    HANDY_QR: 'PaymentLink:Read',
    MOBILE_BALANCE: 'MobileRecharge:Read',
    ITAU: 'PaymentGateway:Read',
    LOANS: 'Loan:Read',
    YOUR_MONEY: 'Calendar:Read',
    HISTORY: 'WithdrawalHistory:Read',
    REVERSALS: 'Reversal:Read',
    VOUCHERS: 'Invoice:Read',
    PROFILE: 'Profile:Read',
    MANAGE_USERS: 'User:Read',
    BRANCHES: 'Branch:Read',
    OPERATION_DEATAILS: 'OperationDetail:Read',
    NOTIFICATIONS: 'Notification:Read',
    PROVIDER: 'Provider:Read',
    PROVIDER_CREATE_PAYMENT: 'Provider:CreatePayment',
    SUBSCRIBER: 'Subscriber:Read',
    // Write
    ACTIVITY_WRITE: 'Activity:Write',
    HANDY_LINK_WRITE: 'PaymentLink:Write',
    HANDY_QR_WRITE: 'PaymentLink:Write',
    ITAU_WRITE: 'PaymentGateway:Write',
    LOANS_WRITE: 'Loan:Write',
    MANAGE_USERS_WRITE: 'User:Write',
    NOTIFICATIONS_WRITE: 'Notification:Write',
    SENSITIVE_DATA: 'SensitiveData:Write',
    PROVIDER_WRITE: 'Provider:Write',
    SUBSCRIBER_WRITE: 'Subscriber:Write',
    // Super
    SUPER_READ: 'Super:Read',
  },
};
