import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  BoxSeparator,
  Box,
  ForbiddenSection,
  Page,
  TitleBox,
  Button,
  ContentBox,
  StatusPill,
} from '../../../../../components';
import ROUTES from '../../../../../routes';
import { Language, Utils } from '../../../../../utils';
import { business as businessActions } from '../../../../../actions';
import { goBack } from 'connected-react-router';
import makeClasses from './styles';
import moment from 'moment';
import { Grid } from '@material-ui/core';

import { Policies } from '../../../../../utils/Policies';
import { toast } from 'react-toastify';

const STATUS_OPTIONS = [
  { id: 'CANCELED', label: 'Cancelado' },
  { id: 'ERROR', label: 'Error' },
  { id: 'COMPLETED', label: 'Pagado' },
  { id: 'PARTIAL_EXECUTED', label: 'Parcialmente pagado' },
  { id: 'IN_PROGRESS', label: 'Procesando pago' },
  { id: 'APPROVED', label: 'Procesando pago' },
  { id: 'MANUAL_REJECTED', label: 'Rechazado por usuario' },
  { id: 'PENDING_MANUAL_APPROVAL', label: 'Pendiente de aprobación' },
];

const SubscriptionPaymentDetailsScreen = ({ match }) => {
  const dispatch = useDispatch();
  const classes = makeClasses();

  const { policies, languageTexts } = useSelector((state) => ({
    policies: state.user.userData.policies || [],
    languageTexts: state.language.texts || {},
  }));
  const i18n = Language(languageTexts);
  const subscriptionTraceId = match && match.params ? match.params.traceId : null;
  const paymentTraceId = match && match.params ? match.params.paymentTraceId : null;

  const [subscriptionPayment, setSubscriptionPayment] = useState(null);

  const [forbiddenSection, setForbbidenSection] = useState(false);

  useEffect(() => {
    if (!policies || !policies.includes(Policies.types.SUBSCRIBER)) {
      setForbbidenSection(true);
    }
  }, [policies]);

  useEffect(() => {
    if (!forbiddenSection) {
      loadData();
    }
  }, [forbiddenSection]);

  const loadData = () => {
    if (subscriptionTraceId) {
      dispatch(
        businessActions.getSubscriptionPayment(paymentTraceId, (data) => {
          if (!data) {
            return;
          }

          if (data.additionalData) {
            for (const key in data.additionalData) {
              if (data.additionalData.hasOwnProperty(key)) {
                data.additionalData[key] = JSON.parse(JSON.parse(data.additionalData[key]));
              }
            }
          }
          setSubscriptionPayment(data);
        })
      );
    }
  };

  const _handleOnGoBackClick = () => {
    dispatch(goBack());
  };

  const handleOnApprovePayment = () => {
    dispatch(
      businessActions.subscriptionApprovePayment(paymentTraceId, (result) => {
        if (result) {
          toast.success(i18n.get('ManageSubscriptionsView.SuccessApproveMessage'));
          _handleOnGoBackClick();
        } else {
          toast.error(i18n.get('ManageSubscriptionsView.ErrorApproveMessage'));
        }
      })
    );
  };

  const handleOnRejectPayment = () => {
    dispatch(
      businessActions.subscriptionRejectPayment(paymentTraceId, (result) => {
        if (result) {
          toast.success(i18n.get('ManageSubscriptionsView.SuccessRejectMessage'));
          _handleOnGoBackClick();
        } else {
          toast.error(i18n.get('ManageSubscriptionsView.ErrorRejectMessage'));
        }
      })
    );
  };

  if (forbiddenSection) {
    return (
      <Page
        withHeader
        withSidebar
        withHeaderTitle={i18n.get('ManageSubscriptionsPaymentDetails.Title')}
        withActivePage={ROUTES.MANAGE_PROVIDERS_PAYMENTS.id}
      >
        <ForbiddenSection />
      </Page>
    );
  }

  return (
    <Page
      withHeader
      withSidebar
      withHeaderTitle={i18n.get('ManageSubscriptionsPaymentDetails.Title')}
      withActivePage={ROUTES.MANAGE_PROVIDERS_PAYMENTS.id}
    >
      <TitleBox
        title={i18n.get('ManageSubscriptionsPaymentDetails.BoxTitle')}
        back
        backTitle={i18n.get('ManageSubscriptionsPaymentDetails.BackButton')}
        onBackClick={_handleOnGoBackClick}
        backButtonVariant="outlined"
      />
      <BoxSeparator size="small" />
      <Box>
        {subscriptionPayment ? (
          <React.Fragment>
            <Grid container spacing={4}>
              <Grid item xs={12} sm={12} md={3}>
                <div className={classes.suscriptionDetailsItem}>
                  <div>{i18n.get('ManageSubscriptionsPaymentDetails.SubscriptionBoxDateLabel')}:</div>
                  {subscriptionPayment.createdAt
                    ? moment(subscriptionPayment.createdAt).format(i18n.get('DateFormat'))
                    : '-'}
                </div>
              </Grid>
              <Grid item xs={12} sm={12} md={3}>
                <div className={classes.suscriptionDetailsItem}>
                  <div>{i18n.get('ManageSubscriptionsPaymentDetails.SubscriptionBoxLimitDateLabel')}:</div>
                  {subscriptionPayment.limitDate
                    ? moment(subscriptionPayment.limitDate).format(i18n.get('DateFormat'))
                    : '-'}
                </div>
              </Grid>
              <Grid item xs={12} sm={12} md={3}>
                <div className={classes.suscriptionDetailsItem}>
                  <div>{i18n.get('ManageSubscriptionsPaymentDetails.SubscriptionBoxProductLabel')}:</div>
                  {subscriptionPayment.productName || '-'}
                </div>
              </Grid>
              <Grid item xs={12} sm={12} md={3}>
                <div className={classes.suscriptionDetailsItem}>
                  <div>{i18n.get('ManageSubscriptionsPaymentDetails.SubscriptionBoxProviderLabel')}:</div>
                  {subscriptionPayment.providerName || '-'}
                </div>
              </Grid>
              <Grid item xs={12} sm={12} md={3}>
                <div className={classes.suscriptionDetailsItem}>
                  <div>{i18n.get('ManageSubscriptionsPaymentDetails.SubscriptionBoxBranchLabel')}:</div>
                  {subscriptionPayment.branchName || '-'}
                </div>
              </Grid>
              <Grid item xs={12} sm={12} md={3}>
                <div className={classes.suscriptionDetailsItem}>
                  <div>{i18n.get('ManageSubscriptionsPaymentDetails.SubscriptionBoxStatusLabel')}:</div>
                  <StatusPill
                    data={subscriptionPayment.status === 'APPROVED' ? 'IN_PROGRESS' : subscriptionPayment.status}
                  >
                    {subscriptionPayment.status
                      ? STATUS_OPTIONS.find((s) => s.id === subscriptionPayment.status)?.label
                      : '-'}
                  </StatusPill>
                </div>
              </Grid>
              <Grid item xs={12} sm={12} md={3}>
                <div className={classes.suscriptionDetailsItem}>
                  <div>{i18n.get('ManageSubscriptionsPaymentDetails.SubscriptionBoxAmountLabel')}:</div>
                  {subscriptionPayment.amount
                    ? `${subscriptionPayment.product.currency} ${Utils.formatCurrency(subscriptionPayment.amount)}`
                    : '-'}
                </div>
              </Grid>
              <Grid item xs={12} sm={12} md={3}>
                <div className={classes.suscriptionDetailsItem}>
                  <div> {i18n.get('ManageSubscriptionsPaymentDetails.SubscriptionBoxDueAmountLabel')}:</div>
                  {subscriptionPayment.amount - subscriptionPayment.chargedAmount >= 0
                    ? `${subscriptionPayment.product.currency} ${Utils.formatCurrency(
                        subscriptionPayment.amount - subscriptionPayment.chargedAmount
                      )}`
                    : '-'}
                </div>
              </Grid>
              <Grid item xs={12} sm={12} md={3}>
                <div className={classes.suscriptionDetailsItem}>
                  <div>{i18n.get('ManageSubscriptionsPaymentDetails.SubscriptionBoxMessageLabel')}:</div>
                  {subscriptionPayment.message || '-'}
                </div>
              </Grid>
              <Grid item xs={12} sm={12} md={3}>
                <div className={classes.suscriptionDetailsItem}>
                  <div>{i18n.get('ManageSubscriptionsPaymentDetails.SubscriptionBoxBillIdentifierLabel')}:</div>
                  {subscriptionPayment.billIdentifier || '-'}
                </div>
              </Grid>
            </Grid>
          </React.Fragment>
        ) : null}
      </Box>
      {subscriptionPayment &&
      subscriptionPayment.additionalData &&
      Object.keys(subscriptionPayment.additionalData).length > 0 ? (
        <React.Fragment>
          <BoxSeparator size="small" />
          <ContentBox title={i18n.get('ManageSubscriptionsPaymentDetails.SubscriptionBoxAdditionalDataLabel')}>
            {subscriptionPayment.additionalData && Object.keys(subscriptionPayment.additionalData).length > 0
              ? Object.keys(subscriptionPayment.additionalData).map((key, index) => (
                  <div key={index} className={classes.additionalDataContainer}>
                    <Grid container spacing={4}>
                      <Grid item xs={12} sm={12} md={6}>
                        <div className={classes.suscriptionDetailsItem}>
                          <div>{i18n.get('ManageSubscriptionsPaymentDetails.AdditionalFieldNameInputLabel')}:</div>
                          {subscriptionPayment.additionalData[key].Name || '-'}
                        </div>
                      </Grid>
                      <Grid item xs={12} sm={12} md={6}>
                        <div className={classes.suscriptionDetailsItem}>
                          <div>{i18n.get('ManageSubscriptionsPaymentDetails.AdditionalFieldValueInputLabel')}:</div>
                          {subscriptionPayment.additionalData[key].Value
                            ? subscriptionPayment.additionalData[key].Type === 'date'
                              ? moment(subscriptionPayment.additionalData[key].Value).format(i18n.get('DateFormat'))
                              : subscriptionPayment.additionalData[key].Value
                            : '-'}
                        </div>
                      </Grid>
                    </Grid>
                  </div>
                ))
              : null}
          </ContentBox>
        </React.Fragment>
      ) : null}
      {subscriptionPayment && subscriptionPayment.status === 'PENDING_MANUAL_APPROVAL' ? (
        <React.Fragment>
          <BoxSeparator size="small" />
          <Box>
            <div className={classes.actionsContainer}>
              <Button onClick={handleOnRejectPayment}>
                {i18n.get('ManageSubscriptionsPaymentDetails.ButtonReject')}
              </Button>
              <Button onClick={handleOnApprovePayment}>
                {i18n.get('ManageSubscriptionsPaymentDetails.ButtonApprove')}
              </Button>
            </div>
          </Box>
        </React.Fragment>
      ) : null}
    </Page>
  );
};

SubscriptionPaymentDetailsScreen.id = 'com.Handy.SubscriptionPaymentDetails';

export default SubscriptionPaymentDetailsScreen;
